import React, { Fragment, useEffect } from "react";
import { Spin, Card, Result } from "antd";
import white from "../../../assets/images/white.jpg";
import { getCollection } from "../../../redux/actions/collectionActions";
import { reset } from "../../../redux/reducers/collectionReducer.js";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FeaturedArt from "./FeaturedArt/FeaturedArt.js";
// import FeaturedCollection from "./FeaturedCollection"

const Hero = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // geting values from redux collectionReducer
  const { collection, isLoading, isError } = useSelector(
    (state) => state.collection
  );

  // fillter collection of type 1='unique' collection
  const filterData = collection?.filter(
    (fil) => fil.type === 1 && fil.publishItem === true
  );
  const collectionName = filterData[0]?.name.replace(/ /g, "");

  // const filterAllCollection = collection?.filter(
  //   (fil) => fil.publishItem === true
  // );

  useEffect(() => {
    // calling collection detials from redux actionCollection
    dispatch(getCollection());
    // reset function excute every time before execute getCollection() function except first time 
    return () => dispatch(reset());
  }, [dispatch]);

  if (isError) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
      />
    );
  }

  return (
    <Fragment>
      <div className="hero_box">
        <div className="hero_Container_Home">
          <div className="left-box">
            {" "}
            <div style={{ color: "#000" }} className="heading">
              <h1 style={{ fontSize: "2.5rem" }}>
                <strong>REIMAGINE EXPERIENCES</strong>
              </h1>
              <h3 style={{ fontSize: "" }}>For next generation of</h3>
              <h1>Brands | Creators | Fans</h1>
            </div>
          </div>
          <div className="right-box center">
            <Spin spinning={isLoading}>
              <Card
                className="collection-box"
                onClick={() =>
                  history.push(
                    `/collection/${collectionName}/${filterData[0]?._id}`
                  )
                }
              >
                <img
                  className="collection-img"
                  src={filterData[0]?.image ? filterData[0]?.image : white}
                  alt=""
                />
                <div style={{ padding: "8px 15px" }}>
                  <h3 style={{ marginBottom: "0px" }}>{filterData[0]?.name}</h3>
                  <div>
                    <h4>{filterData[0]?.description}</h4>
                  </div>
                </div>
              </Card>
            </Spin>
          </div>
        </div>
      </div>
      {/* <FeaturedCollection
        filterAllCollection={filterAllCollection}
        isLoading={isLoading}
      /> */}
      <FeaturedArt />
    </Fragment>
  );
};

export default Hero;
