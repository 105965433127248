import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./UploadDetails.module.sass";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import Switch from "../../components/Switch";

import Modal from "../../components/Modal";

import FolowSteps from "./FolowSteps";

import axios from "axios";
import _ from "underscore";

import mp3image from "../../../src/assets/images/mp3icon.png";
import ReactPlayer from "react-player";
import VideoModal from "../VideoModal";
import moment from "moment";
import createWeb3ContractMethodInstance from "../../components/CreateWeb3Instance/Web3instance";
import getinstancesParamter from "../../components/getInstancesParameter/Constant";

const constant = require("../../components/Constant/Constant");

const pinataSDK = require("@pinata/sdk");
const pinata = pinataSDK(
  "32d944bab133a65ecf62",
  "a90d6a495308b636cb8a486b0f4354161640bed6ee530f45852e33b13c511a56"
);

var apiBaseUrl = constant.getAPiUrl();

var getWeb3InstanceParameter = constant.getWeb3InstanceParameter();

var util = require("web3-utils");

const Upload = () => {
  const [royalties, setroyalties] = useState("");

  const [locking, setLocking] = useState(false);
  const [previewImage, setpreviewImage] = useState("");
  const [blockloader, setblockloader] = useState(false);
  const [alertmessage, setalertmessage] = useState("");

  const [videomodaltoggle, setvideomodaltoggle] = useState(false);
  const [arrayroyalities, setarrayroyalities] = useState([]);
  const [royalitiessharedistribution, setroyalitiessharedistribution] =
    useState(10000);

  const [royalpercentage, setroyalpercentage] = useState(0);
  const [unlockdescription, setunlockdescription] = useState("");
  const [unlockurl, setunlockurl] = useState("");
  const [networkdId, setnetworkdId] = useState();
  const [supporttoggle, setsupporttoggle] = useState(false);
  //   const [collectionoftokenId, setcollectionoftokenId] = useState([]);
  // console.log(collectionoftokenId)
  const navLinks = [
    "Select Network Type",
    "PolygonMumbai",
    "Ropsten Test Network",
    "Mainet",
  ];
  const sortingOptions = [];
  navLinks.map((x) => sortingOptions.push(x));
  const [sorting, setSorting] = useState(sortingOptions[0]);
  const [selectnetworkmintingtoggle, setselectnetworkmintingtoggle] =
    useState(false);

  const [state, setstate] = useState({
    name: "",
    externalURL: "URL",
    description: "",
    attrName: "",
    externallink: "",
    attrValue: "",
    attributes: [],
    arrayroyalities: [],
    IPFSHash: "",
    address: "",
    fileType: "",
    size: "",
    property: "",
  });

  const [visibleModal, setVisibleModal] = useState(false);

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [showalertmessagetoggle, setshowalertmessagetoggle] = useState(false);
  const [alertmessageshow, setalertmessageshow] = useState("");
  const [checktype, setchecktype] = useState("");
  const [royaltiesreceiveraddress, setroyaltiesreceiveraddress] = useState([]);
  const [selectnetworkusingdropdouwn, setselectnetworkusingdropdouwn] =
    useState("");
  const [errornetworktoggle, seterrornetworktoggle] = useState(false);

  const [transactionhash, settransactionhash] = useState("");
  const [collectionoftokenId, setcollectionoftokenId] = useState([]);

  // console.log(collectionoftokenId)

  if (window.ethereum) {
    window.ethereum.on("networkChanged", function (value) {
      //setnetworkId

      setnetworkdId(value);
      if (value == 80001 || value == 4) {
        setnetworkdId(value);
        setsupporttoggle(false);
      } else {
        setsupporttoggle(true);
      }
    });
  } else {
    window.location = "/errorpage";
  }

  useEffect(async () => {});

  //call web3 run time instances
  const getWeb3Instance = async (
    registrationType,
    blockchaintype,
    networktype,
    erctype
  ) => {
    let contract_Methods = await createWeb3ContractMethodInstance(
      registrationType,
      blockchaintype,
      networktype,
      erctype
    );

    return contract_Methods.contract_Methods;
  };

  // call the function for generate the token id
  function create_UUID() {
    var dt = Date.now();
    var uuid = "xxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);

      return c == "x" ? r : (r & 0x3) | 0x8;
    });

    return uuid;
  }

  // const getAssetDetails = async () => {
  //   const modeData = [];
  //   try {
  //     const res = await axios({
  //       method: "get",
  //       url: `${apiBaseUrl}/api/user/allTokens/${80001}/${1}/10`,
  //       // url: `${apiBaseUrl}/api/user/allTokens/${inputvalue}/${pageNumberValue}/10`,
  //       headers: {
  //         Authorization: localStorage.token
  //           ? `Bearer ${localStorage.token}`
  //           : undefined,
  //         "Content-Type": "application/json;charset=UTF-8",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     });
  //     const dataRes = res.data.data;
  //     for (const { uri, ...other } of dataRes) {
  //       const response = await fetch(uri, { mode: "cors" });
  //       let data = await response.json();
  //       modeData.push({ ...data, ...other });
  //     }
  //     setcollectionoftokenId(modeData);
  //     // console.log(res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //create mint asset by calling createItemFunction

  const createItemFunction = async () => {
    state.fileType = checktype;

    //check validation of upload file,item name, description,royalties

    if (state.IPFSHash == "") {
      setshowalertmessagetoggle(true);
      setalertmessageshow("Please Upload File");
    } else if (state.name == "") {
      setshowalertmessagetoggle(true);
      setalertmessageshow("Please Mention Item Name");
    } else if (state.description == "") {
      setshowalertmessagetoggle(true);
      setalertmessageshow("Please Mention Description");
    } else if (royalties == "") {
      setshowalertmessagetoggle(true);
      setalertmessageshow("Please Mention Royalities");
    } else if (royalties > 10001) {
      setshowalertmessagetoggle(true);
      setalertmessageshow("Please Mention Royalities Below 10000 bps ");
    } else if (locking && unlockurl == "") {
      setshowalertmessagetoggle(true);
      setalertmessageshow(
        "Please Mention  Unlock Url after Sucessfull Transaction"
      );
    } else {
      const account = localStorage.getItem("CurrentAddress");
      let tokenId = create_UUID();
      var token = await createMintToken(
        {
          ...state,
        },
        tokenId
      );
      //if asset minted sucessfully call the api to maintain history of asset item
      if (token.status) {
        const eventResult = subscribeLogEvent(window.contract, "Minted");
        await window.ethereum.enable();
        const checknetworkId = window.ethereum.networkVersion;
        if (eventResult) {
          const headers = {
            Authorization: localStorage.token
              ? `Bearer ${localStorage.token}`
              : undefined,
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          };
          const data = {
            walletAddress: account.toLowerCase(),
            tokenId: tokenId,
            action: "Minted",
            from: "Null",
            to: account.toLowerCase(),
            date: moment().format("YYYY-MM-DD, h:mm:ss a"),
            transactionHash: token.hash,
            networkId: checknetworkId,
          };
          let _royaltiesreceiver = [account];
          arrayroyalities.forEach((value) => {
            _royaltiesreceiver.push(value.royaladdress);
          });
          let sharehodlderdata = {
            tokenId: tokenId,
            walletAddress: _royaltiesreceiver,
            networkId: checknetworkId,
          };
          axios
            .post(
              `${apiBaseUrl}/api/user/addShareHolderData`,
              sharehodlderdata,
              {
                headers: headers,
              }
            )
            .then((response) => {})
            .catch((error) => {
              console.log(error);
            });
          let unlockurldata = {
            tokenId: tokenId,
            unlockOncePurchased: true,
            description: unlockdescription,
            url: unlockurl,
            networkId: checknetworkId,
          };
          axios
            .post(`${apiBaseUrl}/api/user/createMintToken`, data, {
              headers: headers,
            })
            .then((response) => {
              if (response.data.status == 200) {
                if (locking) {
                  if (unlockurl != "") {
                    axios
                      .post(
                        `${apiBaseUrl}/api/user/unlockPurchaseData`,
                        unlockurldata,
                        {
                          headers: headers,
                        }
                      )
                      .then((response) => {
                        setalertmessage("TokenId Added Successfully");
                        window.location = "/profile";
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    setalertmessage("TokenId Added Successfully");
                    // window.location = "/profile";
                    window.location = "/dani";
                  }
                } else {
                  // getallminttokenasset(80001, 1)
                  setalertmessage("TokenId Added Successfully");
                  window.location.reload();
                  window.location = "/dani";
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert("Something went wrong");
        }
      }
    }
  };

  //calling create mint token api and get response of pinta url where we stored asset json file
  async function createMintToken(jsonData, token) {
    try {
      setalertmessage("Minting In Progress Please Wait ...");

      setblockloader(true);
      const account = localStorage.getItem("CurrentAddress");

      let newjsondata = {
        tokenId: token,
        collectionName: jsonData.name,

        jsonDetails: {
          description: jsonData.description,
          external_url: jsonData.externallink,
          image: jsonData.IPFSHash,
          name: jsonData.name,
          attributes: jsonData.attributes,
          assettype: jsonData.fileType,
        },
      };

      //calling the api for getting status of json file of asset save in pinata url
      const getResponseApi = await axios
        .post(`${apiBaseUrl}/api/user/request/uri`, newjsondata, {
          headers: {
            Authorization: localStorage.token
              ? `Bearer ${localStorage.token}`
              : undefined,
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .catch((error) => {
          setshowalertmessagetoggle(true);
          setalertmessageshow(
            "Item Name Already Exist Please Provide Different Item Name"
          );
        });

      //get collection data of royalties into arrat

      let uriValue = getResponseApi.data.Uri;

      let _royaltiesreceiver = [account];
      let shares_ = [`${royalitiessharedistribution}`];

      arrayroyalities.forEach((value) => {
        if (value.royaladdress && value.royalValue) {
          _royaltiesreceiver.push(value.royaladdress);
          shares_.push(value.royalValue);
        }
      });

      setroyaltiesreceiveraddress(_royaltiesreceiver);

      const getWeb3InstanceParameter = await getinstancesParamter();

      //calling run time instances for mint token using calling method mintTokenWithRoyaltiesShares into blockchain

      let contractmethod = await getWeb3Instance(
        getWeb3InstanceParameter.nftRegistrationtype,
        getWeb3InstanceParameter.Blockchaintype,
        getWeb3InstanceParameter.networkType,
        getWeb3InstanceParameter.erctype
      );

      const coolNumber = await contractmethod.methods
        .mintTokenWithRoyaltiesShares(
          account,
          token,
          uriValue,
          royalties,
          _royaltiesreceiver,
          shares_
        )
        .send({ from: account })
        .on("transactionHash", function (hash) {
          //set hash value into settransactionhash which getting response from mintTokenWithRoyaltiesShares blockchain method

          settransactionhash(hash);
        });

      settransactionhash(coolNumber.transactionHash);

      let hashobject = {
        status: true,
        hash: coolNumber.transactionHash,
      };

      return hashobject;
    } catch (error) {
      setblockloader(false);
      return false;
    }
  }

  const subscribeLogEvent = async (contract, eventName) => {
    const subscribedEvents = {};

    if (window.web3.utils._) {
      const eventJsonInterface = await window.web3.utils._.find(
        contract._jsonInterface,
        (o) => o.name === eventName && o.type === "event"
      );

      const subscription = window.web3.eth.subscribe(
        "logs",
        {
          address: contract.options.address,
          topics: [eventJsonInterface.signature],
        },
        (error, result) => {
          if (!error) {
            const eventObj = window.web3.eth.abi.decodeLog(
              eventJsonInterface.inputs,
              result.data,
              result.topics.slice(1)
            );
          } else {
            console.log(error);
          }
        }
      );
      subscribedEvents[eventName] = subscription;
      return subscribedEvents;
    }
  };

  //for editing and uploading the asset file
  const onFileChange = async ({ target }) => {
    setchecktype(target.files[0].type);
    const fileSize = target.files[0].size / 1024 / 1024;
    if (fileSize > 100) {
      alert("File size exceeds 100 MB");
    } else {
      if (
        target.files[0].type != "image/png" &&
        target.files[0].type != "image/jpeg" &&
        target.files[0].type != "image/gif" &&
        target.files[0].type != "video/mp4" &&
        target.files[0].type != "audio/mpeg"
      ) {
        setshowalertmessagetoggle(true);
        setalertmessageshow("We Support JPEG,GIF,PNG,Mp3,Video format Only");
      } else {
        if (target.files[0]) {
          setpreviewImage("");
          setblockloader(true);

          setalertmessage("File Uploading...");

          const url = "https://api.pinata.cloud/pinning/pinFileToIPFS";

          const data = new FormData();
          data.append("file", target.files[0]);
          const result = await axios
            .post(url, data, {
              maxContentLength: -1,
              headers: {
                "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                pinata_api_key: "32d944bab133a65ecf62",
                pinata_secret_api_key:
                  "a90d6a495308b636cb8a486b0f4354161640bed6ee530f45852e33b13c511a56",
                path: "somename",
              },
            })
            .then(function (response) {
              setblockloader(false);
              setstate({
                ...state,
                IPFSHash: `https://nftbrewery.mypinata.cloud/ipfs/${response.data.IpfsHash}`,
              });

              setpreviewImage(
                `https://nftbrewery.mypinata.cloud/ipfs/${response.data.IpfsHash}`
              );
            })
            .catch(function (error) {
              console.log(error);
              setblockloader(false);
            });
        }
      }
    }
  };
  //for adding attribute name , value to stored into array of attributes
  const addAttribute = () => {
    setstate({
      ...state,
      attributes: [
        ...state.attributes,
        { attrName: state.attrName, attrValue: state.attrValue },
      ],
      attrName: "",
      attrValue: "",
    });
  };

  //for removing the participant royalities addresss
  const checkremoveroyalitiesarray = () => {
    console.log(arrayroyalities);

    var arr = arrayroyalities;
    var newArr = arr.slice(0, -1);
    console.log(newArr);

    setarrayroyalities(newArr);
  };

  //for adding royalties of participant into array
  const addroyalitiesarray = () => {
    setarrayroyalities(
      arrayroyalities.concat({
        id: Math.round(Math.random() * 36 ** 12).toString(36),
        royalValue: "",
        royaladdress: "",
      })
    );
  };

  //for adding the text field
  const onTextFieldChange = ({ target }) => {
    setstate({ ...state, [target.name]: target.value });
  };

  const royalitiesfunction = (e) => {
    // setroyalties(e.target.value.replace(/\D/,''))
    setroyalties(e.target.value.replace(/[^0-9]/g, ""));
  };

  const videotoggle = (value) => {
    setvideomodaltoggle(false);
  };

  //set the collection of royalties stored into array
  const royaliteisvaluefunction = (value, id) => {
    setroyalpercentage(+value);

    var findindexvalue = arrayroyalities.findIndex((obj) => {
      return obj.id == id;
    });
    // console.log(findindexvalue);
    arrayroyalities[findindexvalue].royalValue = value;

    let calulatevalue = 0;
    arrayroyalities.forEach((value) => {
      console.log(value.royalValue);
      calulatevalue = calulatevalue + +value.royalValue;
    });

    console.log(calulatevalue);
    let partialvalue = 10000 - calulatevalue;
    setroyalitiessharedistribution(partialvalue);
  };

  //for changing the address of royalties
  const addressChangefunction = (value, id, object) => {
    var findindexvalue = arrayroyalities.findIndex((obj) => {
      return obj.id == id;
    });

    let makeobject = {
      id: id,
      royaladdress: value.toLowerCase(),
      royalValue: object.royalValue,
    };
    arrayroyalities.splice(findindexvalue, 1, makeobject);
  };

  //handle network of array
  const oncheckfunction = (value) => {
    seterrornetworktoggle(false);

    setselectnetworkusingdropdouwn(value);

    setSorting(value);
  };

  const checkmetamask = async (networkname, networkid) => {
    seterrornetworktoggle(false);
    await window.ethereum.enable();
    const checknetworkid = window.ethereum.networkVersion;

    if (checknetworkid == networkid) {
      createItemFunction();
      setselectnetworkmintingtoggle(false);
    } else {
      seterrornetworktoggle(true);
    }
  };

  return (
    <>
      <div className={cn("section", styles.section)}>
        <Modal visible={selectnetworkmintingtoggle}>
          <div>
            <div className={styles.category}>Select Network For Minting :</div>
            <br />
            <Dropdown
              className={styles.dropdown}
              value={sorting}
              oncheckfunction={oncheckfunction}
              options={sortingOptions}
            />

            <br />
            {errornetworktoggle && (
              <>
                Change metamask network on {selectnetworkusingdropdouwn}
                <br />
                <br />
              </>
            )}
            {selectnetworkusingdropdouwn == "RinkebyTest Network" && (
              <>
                <center>
                  <h3
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      checkmetamask("RinkebyTest Network", 4);
                    }}
                  >
                    Confirm
                  </h3>
                </center>
              </>
            )}

            {selectnetworkusingdropdouwn == "PolygonMumbai" && (
              <>
                <center>
                  <h3
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      checkmetamask("PolygonMumbai", 80001);
                    }}
                  >
                    Confirm
                  </h3>
                </center>
              </>
            )}

            {selectnetworkusingdropdouwn == "Ropsten Test Network" && (
              <>
                <center>
                  <h3
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      checkmetamask("Ropsten Test Network", 3);
                    }}
                  >
                    Confirm
                  </h3>
                </center>
              </>
            )}

            <div></div>
          </div>
        </Modal>
        <Modal visible={supporttoggle}>
          <div>
            <center>
              <h3>Wrong Network !</h3>
            </center>
            <br />
            <center>
              <h4>
                Look like you connected to unsupported network.Change network to
                Polygon Mumbai Or Rinkeby.
              </h4>
            </center>
            <br />

            <div></div>
          </div>
        </Modal>

        <Modal visible={videomodaltoggle}>
          <VideoModal videotoggle={videotoggle} videoimage={previewImage} />
        </Modal>
        <Modal visible={showalertmessagetoggle}>
          <div
            onClick={() => {
              setshowalertmessagetoggle(false);
            }}
          >
            <center>
              <h4>{alertmessageshow}</h4>
            </center>

            <br />
            <center>
              {" "}
              <h4>
                <span
                  style={{ marginLeft: "", cursor: "pointer" }}
                  onClick={() => {}}
                >
                  OK
                </span>
              </h4>
            </center>

            <div></div>
          </div>
        </Modal>
        <Modal visible={blockloader}>
          <div
            onClick={() => {
              setblockloader(false);
            }}
          >
            <center>
              <h4>{alertmessage}</h4>
            </center>

            <br />

            <div></div>
          </div>
        </Modal>

        <div className={cn("container", styles.container)}>
          <div className={styles.wrapper}>
            <div
              className={cn("h4", styles.title)}
              style={{ marginTop: "-15%" }}
            ></div>

            <br />
            <br />

            <div className={styles.head}>
              <div className={cn("h2", styles.title)}>Create your asset</div>
            </div>

            <form className={styles.form} action="">
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.category}>Upload file</div>

                  <div className={styles.note}>
                    Drag or choose your file to upload
                  </div>

                  <div
                    className={styles.file}
                    style={{ maxWidth: "300px", maxHeight: "520px" }}
                  >
                    {checktype == "video/mp4" && previewImage != "" && (
                      <div
                        className={styles.file}
                        style={{ maxWidth: "300px", maxHeight: "520px" }}
                      >
                        <div style={{ marginTop: "-8%" }}>
                          <ReactPlayer
                            playing={true}
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                            url={previewImage}
                            className="react-player"
                            controls
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </div>
                    )}

                    {previewImage != "" && checktype != "video/mp4" && (
                      <>
                        {checktype == "audio/mpeg" ? (
                          <>
                            <div style={{ marginTop: "-25%" }}>
                              <ReactPlayer
                                playing={true}
                                config={{
                                  file: {
                                    attributes: { controlsList: "nodownload" },
                                  },
                                }}
                                onContextMenu={(e) => e.preventDefault()}
                                url={previewImage}
                                className="react-player"
                                light={mp3image}
                                controls
                                width="100%"
                                height="100%"
                              />
                            </div>
                          </>
                        ) : (
                          <img
                            srcSet={previewImage}
                            src={previewImage}
                            alt="Card"
                            style={{ maxWidth: "250px", maxHeight: "250px" }}
                          />
                        )}
                      </>
                    )}

                    {checktype != "video/mp4" && checktype != "audio/mpeg" && (
                      <input
                        className={styles.load}
                        type="file"
                        onChange={onFileChange}
                      />
                    )}
                    {previewImage == "" && (
                      <>
                        <div className={styles.icon}>
                          <Icon name="upload-file" size="24" />
                        </div>
                        <div className={styles.format}>
                          PNG, GIF,MP4,MP3 Max 100MB.
                        </div>
                      </>
                    )}
                  </div>

                  <div className={styles.col}>
                    {previewImage != "" && (
                      <div
                        className={styles.file}
                        style={{ width: "100px", height: "50px" }}
                      >
                        <input
                          className={styles.load}
                          type="file"
                          onChange={onFileChange}
                        />

                        <>
                          <div className={styles.icon}></div>
                          <div
                            className={styles.format}
                            style={{ marginTop: "-6%" }}
                          >
                            Change
                          </div>
                        </>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.category}>Item Details</div>
                  <div className={styles.fieldset}>
                    <TextInput
                      className={styles.field}
                      label="Item name"
                      name="name"
                      type="text"
                      onChange={onTextFieldChange}
                      value={state.name}
                      placeholder="Item Name"
                      required
                    />
                    <div className={styles.category}>Description :</div>
                    <TextInput
                      className={styles.field}
                      label="The description will be included on the NFT’s detail page underneath its image"
                      name="description"
                      onChange={onTextFieldChange}
                      type="text"
                      placeholder="Provide a detailed description of the NFT"
                      required
                    />
                    <div className={styles.category}>External Link :</div>
                    <TextInput
                      className={styles.field}
                      label="The NFT Brewery will include a link to this URL with the NFT, so that users can learn more about it. You are welcome to link to your own webpage with more details."
                      name="externallink"
                      // name="externallink"
                      onChange={onTextFieldChange}
                      type="text"
                      placeholder="https://www.yournft.com/external_link"
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="Royalty"
                      name="royalties"
                      min={1}
                      onChange={(e) => {
                        royalitiesfunction(e);
                      }}
                      value={royalties}
                      //  type="number"
                      placeholder="e. g. “10000 bps”"
                      required
                    />

                    <div style={{ marginTop: "-10%", marginLeft: "90%" }}>
                      <>bps</>
                    </div>
                    <br />

                    <br />

                    <div className={styles.category}>
                      Royalties can be shared amongst multiple creators. You can
                      add other creators that you want to share the royalties
                      with
                      <br />
                      <button
                        className={cn("button", styles.button)}
                        style={{ marginTop: "2%", marginLeft: "50%" }}
                        onClick={() => {
                          addroyalitiesarray();
                        }}
                        type="button"
                      >
                        <span>Add Royalties Particpant(s)</span>
                        <Icon name="plus" size="20" />
                      </button>
                    </div>
                    <br />
                    {arrayroyalities.length == 0 && (
                      <div className="flex px-1">
                        <div className={styles.row}>
                          <div className={styles.col}>
                            <TextInput
                              className={styles.field}
                              label="Address"
                              style={{ width: "" }}
                              value={localStorage.CurrentAddress}
                              type="text"
                              placeholder="e.g. ErfkgoWER45ELfgo"
                              required
                            />
                          </div>

                          <div className={styles.col}>
                            <TextInput
                              className={styles.field}
                              label="Royalty Share (bps)"
                              value={10000}
                              type="number"
                              placeholder="e. g. “10000 bps”"
                              required
                            />
                          </div>
                        </div>

                        <br />

                        <br />
                      </div>
                    )}

                    {arrayroyalities.length > 0 && (
                      <div className="flex px-1">
                        <div className={styles.row}>
                          <div className={styles.col}>
                            <TextInput
                              className={styles.field}
                              label="Address"
                              style={{ width: "" }}
                              value={localStorage.CurrentAddress}
                              type="text"
                              placeholder="e.g. ErfkgoWER45ELfgo"
                              required
                            />
                          </div>

                          <div className={styles.col}>
                            <TextInput
                              className={styles.field}
                              label="Royalty Share (bps)"
                              value={royalitiessharedistribution}
                              type="number"
                              placeholder="e. g. “10000 bps”"
                              required
                            />
                          </div>
                        </div>

                        <br />

                        <hr />
                        <br />
                      </div>
                    )}

                    {arrayroyalities.map((value, index) => (
                      <div className="flex px-1" key={index}>
                        <div className={styles.row}>
                          <div className={styles.col}>
                            <TextInput
                              className={styles.field}
                              label="Address"
                              style={{ width: "" }}
                              onChange={(e) => {
                                addressChangefunction(
                                  e.target.value,
                                  value.id,
                                  value
                                );
                              }}
                              type="text"
                              placeholder="e.g. ErfkgoWER45ELfgo"
                              required
                            />
                          </div>

                          <div className={styles.col}>
                            <TextInput
                              className={styles.field}
                              label="Royality Share (bps)"
                              onChange={(e) => {
                                royaliteisvaluefunction(
                                  e.target.value,
                                  value.id,
                                  value
                                );
                              }}
                              type="number"
                              placeholder="e. g. “10000 bps”"
                              required
                            />
                          </div>
                        </div>

                        <br />

                        <hr />
                        <br />
                      </div>
                    ))}

                    {arrayroyalities.length > 0 && (
                      <center>
                        <button
                          className={cn("button", styles.button)}
                          style={{
                            marginTop: "",
                            marginLeft: "10%",
                            color: "",
                          }}
                          onClick={() => {
                            checkremoveroyalitiesarray();
                          }}
                        >
                          Remove
                          <Icon name="edit" size="20" />
                        </button>
                      </center>
                    )}

                    <br />
                    {/* <hr/> */}
                    <br />
                    <div className={styles.category}>Attributes :</div>
                    <div style={{ marginBottom: "2%" }}>
                      <span
                        className={styles.field}
                        style={{ color: "#B1B5C3", marginTop: "" }}
                      >
                        Add unique properties for the NFT you want to make the
                        attributes of the NFT stand out for your audience.
                      </span>
                    </div>

                    <div className={styles.row}>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Name"
                          name="attrName"
                          type="text"
                          value={state.attrName}
                          onChange={onTextFieldChange}
                          placeholder=""
                          required
                        />
                      </div>
                      <div className={styles.col}>
                        <TextInput
                          className={styles.field}
                          label="Value"
                          name="attrValue"
                          value={state.attrValue}
                          onChange={onTextFieldChange}
                          type="text"
                          placeholder=""
                          required
                        />
                      </div>
                      <div className={styles.col}>
                        <button
                          className={cn("button", styles.button)}
                          style={{ marginTop: "15%" }}
                          onClick={addAttribute}
                          type="button"
                        >
                          <span>Add Attributes</span>
                          <Icon name="plus" size="20" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="flex flex-col">
                    {state.attributes.map((attr, index) => (
                      <div className="flex px-1" key={attr.attrName}>
                        <div className="flex flex-1">
                          {index + 1} {attr.attrName}: {attr.attrValue}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className={styles.options}>
                <div className={styles.option}>
                  <div className={styles.box}>
                    <div className={styles.category}>Unlock once purchased</div>
                    <div className={styles.text}>
                      Content will be unlocked after successful transaction
                    </div>
                  </div>
                  <Switch value={locking} setValue={setLocking} />
                </div>
                {locking && (
                  <>
                    <TextInput
                      className={styles.field}
                      label="Please Mention Description for transaction"
                      value={unlockdescription}
                      onChange={(e) => {
                        setunlockdescription(e.target.value);
                      }}
                      type="text"
                      placeholder="Provide a detail of Description"
                      required
                    />
                    <br />
                    <TextInput
                      className={styles.field}
                      label="Please Mention Url after successfull transaction"
                      value={unlockurl}
                      onChange={(e) => {
                        setunlockurl(e.target.value);
                      }}
                      type="text"
                      placeholder="https://www.yournft.com/external_link"
                      required
                    />
                  </>
                )}
              </div>
              <div className={styles.foot}>
                <button
                  className={cn("button-stroke tablet-show", styles.button)}
                  onClick={() => setVisiblePreview(true)}
                  type="button"
                >
                  Preview
                </button>

                <button
                  className={cn("button", styles.button)}
                  onClick={() => setselectnetworkmintingtoggle(true)}
                  type="button"
                >
                  <span>Create item</span>
                  <Icon name="arrow-next" size="10" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <FolowSteps className={styles.steps} />
      </Modal>
    </>
  );
};

export default Upload;
