import React, { useState,useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import axios from "axios";
import Icon from "../Icon";
import Image from "../Image";
import Modal from "../../components/Modal";
import Notification from "./Notification";
import User from "./User";
import logoimage from "../../../src/assets/images/logo.png"
import Blockchainloader from "../Blockchain/blockloader"

const constant = require("../../components/Constant/Constant")

 var apiBaseUrl = constant.getAPiUrl();

const nav = [
  {
    url: "/search01",
    title: "Discover",
  },
 
];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [search, setSearch] = useState("");
  const [unabledisabletoggle,setunabledisabletoggle]=useState(false)
  const [account,setaccount] = useState("")
  const [isloginmodaltoggle,setisloginmodaltoggle]=useState(false)
  const [checkemail,setcheckemail]=useState(false)
  const [tokenexpiredtoggle,settokenexpiredtoggle]=useState(false)
  const [connectmodaltoggle,setconnectmodaltoggle]=useState(false)
  


async function getaccountcheck() {

  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];

        
        console.log(account)
        setaccount(account)
}

  useEffect(() => {   


//     let newarray = []
// let givenarray = [6,1,0,2,0,5,0,7]

// givenarray.forEach((value)=>{
//   if(value == 0){
//     newarray.push(value)
//   }

// })

// let filterarray = givenarray.filter((value)=>{
//   return value !== 0
// })

// console.log(newarray.concat(filterarray) )

    
    getaccountaddress()

   let isemail =  localStorage.getItem("isEmail")
   console.log(isemail)
   if(isemail){
     setcheckemail(true)
   }
   
    setInterval(async function () {
    setNetwork(getNetworkName(window.ethereum.networkVersion));  
    },2000);


  
  },[])
  const [network, setNetwork] = useState("");
  console.log(network)
  function getNetworkName(id) {
    var networkName;
    switch (id) {
      case "1":
        networkName = "Mainnet";
        break;
      case "2":
        networkName = "Morden";
        break;
      case "3":
        networkName = "Ropsten";
        break;
      case "4":
        networkName = "Rinkeby";
        break;
      case "42":
        networkName = "Kovan";
        break;
      default:
        networkName = "Unknown";
    }
    return networkName;
  }
  if(window.ethereum){
    window.ethereum.on("networkChanged", function (value) {
      setNetwork(getNetworkName(value));
      
    });

  }
  else{
    window.location = "/errorpage"
  }

  

  async function getaccountaddress() {
    await window.ethereum.enable();
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];

        
        console.log(account)
       
          localStorage.setItem("CurrentAddress",account)
           
        // window.location.reload()
        
         setfunctionformintasset(account)
         window.ethereum.on('accountsChanged', function (accounts) {
          
            
            console.log(accounts[0])
             setfunctionformintasset(account[0])
             window.location.reload()
        


           });
   
  }

 const setfunctionformintasset =(accountaddress) =>{

  // console.log(accountaddress)
  // console.log("account addressssssssssssssssss")
  setaccount(accountaddress)
  
  axios({
        method: 'get',
        url: `${apiBaseUrl}/api/user/getInvite/${accountaddress}`,
        headers: {
          "Authorization":localStorage.token ? `Bearer ${localStorage.token}` : undefined,
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
    
    }).then((res)=>{
      
      // console.log(res.data)
      if(res.data.status == 200 ){
        // console.log("insdie of response")
        setunabledisabletoggle(true)
      }

      else if(res.data.status == 406)
      {
        setunabledisabletoggle(false)
      }
      
      
    }).catch((error)=>{
      console.log(error.message)
      if(error.message == "Request failed with status code 400"){
        let currentaddress = localStorage.CurrentAddress
        localStorage.clear()
        localStorage.setItem("CurrentAddress",currentaddress)
       
      }
    
    })

  }


  const handleSubmit = (e) => {
    alert();
  };

  console.log(unabledisabletoggle)
  if(!unabledisabletoggle){
    
  }
  console.log(account)
  console.log(network)

  const mintassetfunction = ()=>{
    console.log("clcik")
  let isemail =   localStorage.getItem("isEmail")
  console.log(isemail)
  if(isemail){
    window.location = "/upload-variants" 
    // console.log("upload inside")
  }
  else{
    
    setisloginmodaltoggle(true)

  }
  
  
  }

  return (
    <header className={styles.header} style={{backgroundColor:""}}>
      {/* <Blockchainloader/> */}

      
   
   

      

      
      <div className={cn("container", styles.container)} style={{backgroundColor:""}}>
        <Link className={styles.logo} to="/">
        <Image
            className={styles.pic}
            // src="/src/assets/images/logo.pn"
            src={logoimage}
            srcDark={logoimage}
            alt="Fitness Pro"
          />
          {/* <Image
            
            src="/images/logo.jpg"
            srcDark="/images/logo.jpg"
            alt="Fitness Pro"
          /> */}
        </Link>
        <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
          
          {network != "Unknown" &&
          <>
           {/* {network} Test Network */}
           </>
          }
          {/* <nav className={styles.nav}>
            {nav.map((x, index) => (
              <Link
                className={styles.link}
                // activeClassName={styles.active}
                to={x.url}
                key={index}
              >
                {x.title}
              </Link>
            ))}
          </nav> */}
          {/* <form
            className={styles.search}
            action=""
            onSubmit={() => handleSubmit()}
          >
            <input
              className={styles.input}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              name="search"
              placeholder="Search"
              required
            />
            <button className={styles.result}>
              <Icon name="search" size="20" />
            </button>
          </form> */}
          {/* <Link
            className={cn("button-small", styles.button)}
            to="/upload-variants"
          >
            Mint Asset
          </Link> */}
        </div>
        {/* {localStorage.getItem('start') &&
           <Notification className={styles.notification} />
        } */}

        {/* {unabledisabletoggle == true &&
        <>
        

         {localStorage.getItem('start') && 
        <button
          className={cn("button-small", styles.button)}
          // to="/upload-variants"
          onClick={()=>{
            mintassetfunction()
          }}
        >
          Mint Asset
        </button>}
        </>
        } */}

<Link className={cn("button-small", styles.button)} onClick = {()=>{
              localStorage.clear()
              console.log("1")
              localStorage.setItem("darkMode",true)
              window.location = "/"
             }}>
          
            Home
            
             </Link>


        
      
            
            
            
            
     

           
         
      
       
      
     
     
       
        


        
        

{localStorage.token && localStorage.isconnected == undefined &&
             <Link className={cn("button-small", styles.button)} onClick = {()=>{
              localStorage.clear()
              console.log("1")
              localStorage.setItem("darkMode",true)
              window.location = "/"
             }}>
          
             Logout
            
             </Link>

             
            }

           




     
      
        
      
        
       
        
      </div>
     
          
     
    </header>
  );
};

export default Headers;
















