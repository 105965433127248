import React from "react";
import "./Loader.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Loader = () => {
  return (
    <div>
      {" "}
      <Spin indicator={antIcon} className={"absolute-center "} />
    </div>
  );
};

export default Loader;
