import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Icon from "../../../components/Icon";


const Dropdown = ({ className, value, setValue, options,sortingarray,oncheckfunction,filterarrayofvalue,ondefaultcheckfunction,networkIdParameter }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (value) => {
     setValue(value);
     setVisible(false); 
    let checkputonsale = sortingarray.filter((value)=>{
      return value.checkStatus == true && networkIdParameter == value.networkId 
    })

    let filterarray = checkputonsale
    if(value == "Price Low to High"){   
      
     
      filterarray.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
 
      oncheckfunction(filterarray)
    }
    else if(value == "Price High to Low"){
      filterarray.sort((a,b) => (a.price < b.price) ? 1 : ((b.price < a.price) ? -1 : 0));

      oncheckfunction(filterarray)
    }
       
   
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.dropdown, className, { [styles.active]: visible })}
      >
        <div className={styles.head} onClick={() => setVisible(!visible)}>
          <div className={styles.selection}>{value}</div>
          <div className={styles.arrow}>
            <Icon name="arrow-bottom" size="10" />
          </div>
        </div>
        <div className={styles.body}>
          {options.map((x, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x === value,
              })}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              {x}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
