import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import TextInput from "../../../components/TextInput";
import axios from "axios";
import Web3 from "web3";
import Modal from "../../../components/Modal";
import createWeb3ContractMethodInstance from "../../../components/CreateWeb3Instance/Web3instance";
import getinstancesParamter from "../../../components/getInstancesParameter/Constant";

const constant = require("../../../components/Constant/Constant");
var apiBaseUrl = constant.getAPiUrl();

const Hero = () => {
  const [arrayofmintvalue, setarrayofmintvalue] = useState([]);
  const [filterarrayofmintvalue, setfilterarrayofmintvalue] = useState([]);    
  const [withdrawmodal, setwithdrawmodal] = useState(false);
  const [dataloadingtoggle, setdataloadingtoggle] = useState(false);
  const [totalroyalities, settotalroyalities] = useState();  
  const [search, setSearch] = useState("");  
  const [royalitiesTableData, setRoyalitiesTableData] = useState();

  
  const getMyRoyalities = async () => {
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;
    let account = localStorage.CurrentAddress;
    let tableData = [];
    try {
      setdataloadingtoggle(true);    
      const res = await axios.get(
        `${apiBaseUrl}/api/admin/royalty/${checknetworkId}/${account}`
      );
      const resData = res.data.data;
      for (const { uri, ...other } of resData) {
        const response = await fetch(uri, { mode: "cors" });
        const data = await response.json();

        tableData.push({ ...data, ...other });
      }
      console.log(tableData);
      setRoyalitiesTableData(tableData);
    } catch (error) {
      console.log(error);
    }
    setdataloadingtoggle(false);
  };

  
  useEffect(() => {
    getMyRoyalities();    
    setdataloadingtoggle(false);
 
  }, []);

  const getWeb3Instance = async (
    registrationType,
    blockchaintype,
    networktype,
    erctype
  ) => {
    console.log("calll");
    let contract_Methods = await createWeb3ContractMethodInstance(
      registrationType,
      blockchaintype,
      networktype,
      erctype
    );

    return contract_Methods.contract_Methods;
  };

  

  const withdrawfunction = async (tokenid) => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];

      setwithdrawmodal(true);

      // const withdraw = await window.marketcontract.methods.WithdrawPartialRoyaltiesCredits(tokenid).send({from: account});
      const getWeb3InstanceParameter = await getinstancesParamter();

      let contractmethod = await getWeb3Instance(
        getWeb3InstanceParameter.nftRegistrationtype,
        getWeb3InstanceParameter.Blockchaintype,
        getWeb3InstanceParameter.networkType,
        getWeb3InstanceParameter.erctype
      );
      console.log(contractmethod.methods);

      const withdraw = await contractmethod.methods
        .withdrawRoyaltiesCredits(tokenid)
        .send({ from: account });
      // getfetchroyalitiesapi()
      if (withdraw) {
        window.location.reload();
      }

      setwithdrawmodal(false);
    } catch (error) {
      console.log("error", error);

      setwithdrawmodal(false);
    }
  };

  

  const clearfeildfunction = () => {
    setSearch("");
    setarrayofmintvalue(filterarrayofmintvalue);
  };

  const onseachfunction = (val) => {
    setSearch(val);
    let filtervalue = filterarrayofmintvalue.filter((value) => {
      return (
        value.name.toLowerCase().includes(val.toLowerCase()) ||
        value.tokenId.includes(val)
      );
    });
   
    setarrayofmintvalue(filtervalue);
  };

  return (
    <div
      className={cn("section", styles.section)}
      style={{ backgroundColor: "#141416" }}
    >
      <Modal visible={dataloadingtoggle}>
        <div>
          <center>
            <h4>Please Wait..</h4>
          </center>
          <br />
          <center>
            <h4>Loading Data...</h4>
          </center>
         

          <div></div>
        </div>
      </Modal>
      <Modal visible={withdrawmodal}>
        <div>
          <center>
            <h4>Please Wait..</h4>
          </center>
          <br />
          <center>
            <h4>Please do not press Back or Refresh</h4>
          </center>
        

          <div></div>
        </div>
      </Modal>
      <div
        className={cn("container", styles.container)}
        style={{ backgroundColor: "#141416" }}
      >
        <div className={styles.top}>
          <h1 className={cn("h3", styles.title)} style={{ color: "white" }}>
            Total Royalities {totalroyalities}
          </h1>
          <div className={styles.row}>
            <div className={styles.col}>
              <TextInput
                // className={styles.input}
                style={{ color: "white" }}
                type="text"
                value={search}
                // onChange={(e) => setSearch(e.target.value)}
                style={{ width: "350px" }}
                onChange={(e) => {
                  onseachfunction(e.target.value);
                }}
                name="search"
                placeholder="Search By Asset Name Or Token ID ..."
                required
              />
            </div>
            <div className={styles.col} style={{ marginTop: "2%" }}>
              {search != "" && (
                <span
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    clearfeildfunction();
                  }}
                >
                  Clear
                </span>
              )}

             
            </div>
          </div>      

         
        </div>
        <div style={{ marginTop: "-3%" }}>
          <table>
            <thead>
              <tr
                style={{
                  border: "3px solid",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <td style={{ paddingTop: "18px", paddingBottom: "18px" }}>
                  <strong>Asset Name</strong>
                </td>
                <td>
                  <strong>Token ID</strong>
                </td>
                <td>
                  <strong>Current Owner</strong>
                </td>
                <td>
                  <strong>Royalities (bps)</strong>
                </td>
                {/* <td>
                  <strong>Share</strong>
                </td> */}
                <td>
                  <strong>Royalities</strong>
                </td>
                <td width="200px">
                  <strong>Action</strong>
                </td>
              </tr>
            </thead>
            <br />
        

            <tbody>
              {!dataloadingtoggle && (
                <>
                  {royalitiesTableData?.map((item, i) => {
                    return (
                      <tr
                        key={item.tokenId}
                        value={item}
                        style={{
                          border: "3px solid",
                          padding: "8px",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        <td style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                          {item.name}
                        </td>
                        <td>{item.tokenId}</td>
                        <td>
                          {item.ownerAddress.slice(0, 20)}
                          {/* {item.owner.slice(0, 4)}...
                          {item.owner.slice(item.owner.length - 4)} */}
                        </td>
                        {/* { <td>{item.ownerAddress}</td> } */}
                        <td>{item.royaltyInShareBasisPoints}</td>
                        <td>{item.royalty}</td>
                        {item.royalties == "0 ETH" ? (
                          <td
                            style={{ color: "green", cursor: "" }}
                            onClick={() => {
                              // withdrawfunction(item.tokenId)
                            }}
                          >
                            -
                          </td>
                        ) : (
                          <td
                            style={{ color: "green", cursor: "pointer" }}
                            onClick={() => {
                              withdrawfunction(item.tokenId);
                            }}
                          >
                            Withdraw
                          </td>
                        )}

                        <br />
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
       
      </div>
    </div>
  );
};

export default Hero;