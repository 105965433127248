import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Search01.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../components/Icon";
// import Card from "../../components/Card";
import Modal from "../../components/Modal";
import Dropdown from "../../components/Dropdown";
import axios from "axios";
import { bids } from "../../mocks/bids";
import Web3 from "web3";
import Card from "../../components/HomeCard";
const constant = require("../../components/Constant/Constant");

const contractABI = require("../../components/ABI/NFTSalePurchase.json");
const contract = require("../../components/ContractAddress/Contract");
const marketcontractABI = require("../../components/ABI/NFTMarketPlace.json");
const marketcontract = require("../../components/ContractAddress/Contract");
var apiBaseUrl = constant.getAPiUrl();

// data

// const navLinks = ["All items", "Art", "Game", "Photography", "Music", "Video"];
const navLinks = ["All items", "Photography", "Music", "Video"];

const dateOptions = ["Newest", "Oldest"];
const likesOptions = ["Most liked", "Least liked"];
const colorOptions = ["All colors", "Black", "Green", "Pink", "Purple"];
const creatorOptions = ["Verified only", "All", "Most liked"];

const Search = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(dateOptions[0]);
  const [likes, setLikes] = useState(likesOptions[0]);
  const [color, setColor] = useState(colorOptions[0]);
  const [creator, setCreator] = useState(creatorOptions[0]);

  const [search, setSearch] = useState("");

  const [values, setValues] = useState([100]);
  const [arrayofsalevalue, setarrayofsalevalue] = useState([]);

  // const [values, setValues] = useState([5]);

  const [visible, setVisible] = useState(false);

  const [timeby, settimeby] = useState(1);
  const [nottokenmessage, setnottokenmessage] = useState("");
  const [tokenexpiredtoggle, settokenexpiredtoggle] = useState(false);
  const [arrayofmintvalue, setarrayofmintvalue] = useState([]);
  const [arraylength, setarraylength] = useState(0);

  const [arrayofpush, setarrayofpush] = useState([]);
  const [loadmoretoggle, setloadmoretoggle] = useState(true);
  const [filterarrayofvalue, setfilterarrayofvalue] = useState([]);
  const [dataloadingtoggle, setdataloadingtoggle] = useState(false);
  const [showloading, setshowloading] = useState(false);
  useEffect(() => {
    let accountaddress = localStorage.getItem("CurrentAddress");
    // console.log(accountaddress,"line no 31111111111111111")

    selectandgetregisterapi(0, 2, 0);
    // load();

    //  setInterval( function () {

    //  settimeby(timeby + 1 ) ;

    // }, 2000);

    let url = "";
  }, []);
  const getallminttokenasset = async () => {
    let url = "";
    await window.ethereum.enable();

    const checknetworkId = window.ethereum.networkVersion;
    axios({
      method: "get",
      url: `${apiBaseUrl}/api/user/getAllPutOnSellTokenId/${checknetworkId}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status == 200) {
          if (response.data.message.allMarkrtPlaceTokenId.length) {
            minttokengetarrayfunction(
              response.data.message.allMarkrtPlaceTokenId
            );
            setarraylength(response.data.message.allMarkrtPlaceTokenId);
          } else {
            setnottokenmessage("No NFT items are available");
          }

          // console.log(response.data.message.length)
        } else if (response.data.status == 404) {
          setnottokenmessage("No NFT items are available");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.message == "Request failed with status code 400") {
          settokenexpiredtoggle(true);
          // setmodaltoggle(true)
        } else {
          setnottokenmessage("No NFT items are Available");
        }
      });
  };

  const selectandgetregisterapi = async (
    blockchaintype,
    networktype,
    erctype
  ) => {
    // setpleasewaitmodal(true)
    axios({
      method: "get",
      url: `${apiBaseUrl}/api/admin/getSmartContractAddress/${blockchaintype}/${networktype}/${erctype}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        console.log(response);

        if (response.data.status == 200) {
          // setpleasewaitmodal(false)

          // setcollectionofcontract(response.data.data)

          const filtercontractmethod = response.data.data.find(
            (blockchain) => blockchain.registrationType == 1
          );

          const nftfiltercontract = response.data.data.find(
            (blockchain) => blockchain.registrationType == 0
          );
          console.log(
            filtercontractmethod.address,
            "8888888888888888888888888885555555555555555555555555558888888888888"
          );
          console.log(filtercontractmethod.abi);

          load(filtercontractmethod, nftfiltercontract);
          // getaccountaddress();
          console.log(response.data.data);

          //  setinvitedarray(response.data.message.invitedAddress)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getapicall = () => {};

  const makeAPICall = async (pinatapath, value, createdAt) => {
    try {
      const response = await fetch(pinatapath, { mode: "cors" });
      console.log(response);
      console.log("line no 108");

      const data = await response.json();
      console.log({ data });
      if (data) {
        let checkstatus = await window.marketcontract.methods
          .checkTokenStatus(value)
          .call();
        console.log(checkstatus);
        const price = await window.marketcontract.methods
          .TokenPrice(value)
          .call();
        console.log(price);
        let gotbalaenc = await window.web3.utils.fromWei(price);
        console.log(gotbalaenc);
        data.price = +gotbalaenc;
        data.tokenId = value;
        data.putonsalestatus = checkstatus;
        data.createdAt = createdAt;

        //  arrayofsalevalue.push(makeobject)

        //  setarrayofpush(arrayofpush.push(makeobject))

        arrayofsalevalue.push(data);

        filterarrayofvalue.push(data);

        setarrayofpush(arrayofpush.push(data));
      }
      console.log(data.name);
    } catch (e) {
      console.log(e);
    }
  };

  async function minttokengetarrayfunction(arrayvalue) {
    let emptyarray = [];
    let salearray = [];

    for (const value of arrayvalue) {
      // const tokenURI = await window.contract.methods.tokenURI(value).call();
      // let makeobject = await JSON.parse(decodeURIComponent(tokenURI))
      // makeAPICall(makeobject,value);

      // let checkstatus =  await window.marketcontract.methods.checkTokenStatus(value).call()
      // if(checkstatus){
      //   const tokenURI = await window.marketcontract.methods.tokenURI(value).call();
      //   let makeobject = await JSON.parse(decodeURIComponent(tokenURI))
      //   makeAPICall(makeobject,value);

      // }
      const tokenURI = await window.nftcontract.methods
        .tokenURI(value.tokenId)
        .call();
      let makeobject = await JSON.parse(decodeURIComponent(tokenURI));
      console.log(makeobject);
      makeAPICall(makeobject, value.tokenId, value.createdAt);
    }

    setdataloadingtoggle(true);

    setloadmoretoggle(false);

    console.log(emptyarray);
  }

  function delayFunction(x) {
    return new Promise((resolve) => setTimeout(() => resolve(x), 1000));
  }

  // async function loadContract() {
  //   return await new window.web3.eth.Contract(contractABI.abi,contract.getcontractaddress())
  // }
  // async function marketloadContract(){
  //   return await new window.web3.eth.Contract(marketcontractABI.abi,contract.getmarketcontractaddress())
  // }

  async function marketContract(filtercontractmethod) {
    return await new window.web3.eth.Contract(
      filtercontractmethod.abi.abi,
      filtercontractmethod.address
    );
  }

  async function nftContract(nftfiltercontract) {
    return await new window.web3.eth.Contract(
      nftfiltercontract.abi.abi,
      nftfiltercontract.address
    );
  }

  async function load(filtercontractmethod, nftfiltercontract) {
    await loadWeb3();

    window.marketcontract = await marketContract(filtercontractmethod);
    window.nftcontract = await nftContract(nftfiltercontract);

    getallminttokenasset();

    //  window.contract = await loadContract();
    //  window.marketcontract = await marketloadContract()

    //      console.log(tokenURI)
    // console.log(JSON.parse(decodeURIComponent(tokenURI)));
  }
  async function loadWeb3() {
    if (window.ethereum) {
      // console.log(window.ethereum)
      window.web3 = new Web3(window.ethereum);
      window.ethereum.enable();
    }
  }

  const handleSubmit = (e) => {
    alert();
  };

  // const STEP = 0.1;
  // const MIN = 0.01;
  // const MAX = 10;

  // const STEP = 0.001;
  // const MIN = 0.001;
  // const MAX = 10;
  const STEP = 0.1;
  const MIN = 0.1;
  const MAX = 100;

  const rangefunction = (e) => {
    console.log(e);
    setValues(e);

    // arrayofsalevalue
    console.log(filterarrayofvalue);
    let getrange = e[0];
    console.log(getrange);

    let filtervalue = filterarrayofvalue.filter((value) => {
      return value.price <= getrange;
    });
    console.log(filterarrayofvalue);
    console.log(filtervalue);
    setarrayofsalevalue(filtervalue);
    //  let filtervalue =  filterarrayofvalue.filter((value)=>{
    //     value.price =< getrange

    //  )
  };

  const onseachfunction = (val) => {
    console.log(val);
    console.log(typeof val);
    let filtervalue = filterarrayofvalue.filter((value) => {
      return (
        value.name.toLowerCase().includes(val.toLowerCase()) ||
        value.price.toString().includes(val)
      );
    });
    // let filtervalue = filterarrayofvalue.filter((value)=>{
    //   return value.price.includes(val)
    //  })
    setarrayofsalevalue(filtervalue);
  };

  const resetfilter = () => {
    setSearch("");
    setValues([100]);
    setActiveIndex(0);
    setarrayofsalevalue(filterarrayofvalue);
  };

  const settypefunction = (index) => {
    if (dataloadingtoggle) {
      setshowloading(false);

      if (index == 0) {
        setarrayofsalevalue(filterarrayofvalue);
        setActiveIndex(0);
      } else if (index == 1) {
        let filtervalue = filterarrayofvalue.filter((value) => {
          return value.assettype == "image/jpeg";
        });
        setarrayofsalevalue(filtervalue);
        setActiveIndex(1);
      } else if (index == 2) {
        let filtervalue = filterarrayofvalue.filter((value) => {
          return value.assettype == "audio/mpeg";
        });
        setarrayofsalevalue(filtervalue);
        setActiveIndex(2);
      } else if (index == 3) {
        let filtervalue = filterarrayofvalue.filter((value) => {
          return value.assettype == "video/mp4";
        });
        setarrayofsalevalue(filtervalue);
        setActiveIndex(3);
      }
    } else {
      setshowloading(true);
    }
    // console.log(index)
    // setActiveIndex(index)
    // if(index == 0){
    //   setarrayofsalevalue(filterarrayofvalue)

    // }
    // else if(index == 1){
    //   let filtervalue = filterarrayofvalue.filter((value)=>{
    //   return value.assettype == "image/jpeg"
    //  })
    //  setarrayofsalevalue(filtervalue)

    // }
    // else if(index == 2){
    //   let filtervalue = filterarrayofvalue.filter((value)=>{
    //     return value.assettype == "audio/mpeg"
    //    })
    //    setarrayofsalevalue(filtervalue)
    // }
    // else if(index == 3){
    //   let filtervalue = filterarrayofvalue.filter((value)=>{
    //     return value.assettype == "video/mp4"
    //    })
    //    setarrayofsalevalue(filtervalue)
    // }
  };

  console.log(arrayofsalevalue);

  return (
    <div className={cn("section-pt80", styles.section)}>
      <Modal
        visible={showloading}
        // visible={true}
      >
        <div>
          <center>
            <h4>Loading Data..</h4>
          </center>
          <br />
          {/* <center><h4>Loading Data...</h4></center> */}
          <center>
            <h4>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  //  window.location = "/connect-wallet"
                  setshowloading(false);
                }}
              >
                OK
              </span>{" "}
            </h4>
          </center>

          <div></div>
        </div>
      </Modal>

      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={styles.title}></div>
          <div
            className={styles.search}
            action=""
            onChange={(e) => {
              onseachfunction(e.target.value);
            }}
          >
            <input
              className={styles.input}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              name="search"
              placeholder="type your keywords"
              required
            />
            <button className={styles.result}>
              <Icon name="search" size="16" />
            </button>
          </div>
          {/* <form
            className={styles.search}
            action=""
            
            onChange = {(e)=>{
onseachfunction(e.target.value)
            }}
          >
            <input
              className={styles.input}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              name="search"
              placeholder="type your keywords"
              required
            />
            <button className={styles.result}>
              <Icon name="search" size="16" />
            </button>
          </form> */}
        </div>
        {dataloadingtoggle && (
          <>
            <div className={styles.sorting}>
              <div className={styles.dropdown}>
                <Dropdown
                  className={styles.dropdown}
                  value={date}
                  setValue={setDate}
                  options={dateOptions}
                />
              </div>
              <div className={styles.nav}>
                {navLinks.map((x, index) => (
                  <button
                    className={cn(styles.link, {
                      [styles.active]: index === activeIndex,
                    })}
                    onClick={
                      () => settypefunction(index)
                      // setActiveIndex(index)
                    }
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
        <div className={styles.row}>
          <div className={styles.filters}>
            {dataloadingtoggle && (
              <>
                <div className={styles.range}>
                  <div className={styles.label}>Price range</div>
                  <br />
                  <Range
                    values={values}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    // onChange={(values) => setValues(values)}
                    onChange={(e) => {
                      rangefunction(e);
                    }}
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: "36px",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: "8px",
                            width: "100%",
                            borderRadius: "4px",
                            background: getTrackBackground({
                              values,
                              colors: ["#3772FF", "#E6E8EC"],
                              min: MIN,
                              max: MAX,
                            }),
                            alignSelf: "center",
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "24px",
                          width: "24px",
                          borderRadius: "50%",
                          backgroundColor: "#3772FF",
                          border: "4px solid #FCFCFD",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "-33px",
                            color: "#fff",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontFamily: "Poppins",
                            padding: "4px 8px",
                            borderRadius: "8px",
                            backgroundColor: "#141416",
                          }}
                        >
                          {values[0].toFixed(1)}
                        </div>
                      </div>
                    )}
                  />
                  <div className={styles.scale}>
                    {/* <div className={styles.number}>0.001 ETH</div> */}
                    <div className={styles.number}>0.1 ETH</div>
                    <div className={styles.number}>100 ETH</div>
                  </div>
                </div>
                <div className={styles.group}>
                  <div className={styles.item}>
                    <div className={styles.label}>Likes</div>
                    <Dropdown
                      className={styles.dropdown}
                      value={likes}
                      setValue={setLikes}
                      options={likesOptions}
                    />
                  </div>
                  {/* <div className={styles.item}>
                <div className={styles.label}>Color</div>
                <Dropdown
                  className={styles.dropdown}
                  value={color}
                  setValue={setColor}
                  options={colorOptions}
                />
              </div>
              <div className={styles.item}>
                <div className={styles.label}>Creator</div>
                <Dropdown
                  className={styles.dropdown}
                  value={creator}
                  setValue={setCreator}
                  options={creatorOptions}
                />
              </div> */}
                </div>
                <div className={styles.reset} onClick={resetfilter}>
                  <Icon name="close-circle-fill" size="24" />
                  <span>Reset filter</span>
                </div>
              </>
            )}
          </div>

          <div className={styles.wrapper}>
            <div className={styles.list}>
              {arrayofsalevalue.map((x, index) => (
                <Card className={styles.card} item={x} key={index} />
              ))}

              {/* {bids.map((x, index) => (
                <Card className={styles.card} item={x} key={index} />
              ))} */}
            </div>
            {/* <div className={styles.btns}>
              <button className={cn("button-stroke", styles.button)}>
                <span>Load more</span>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
