import { createSlice } from "@reduxjs/toolkit";
import {
  getCollection,
  getPremintAssetByCollectionID,
  getPremintAssestById,
  buyPremintAssest,
  getAllPremintAsset,
  getPremintAssetCollectionNameAndBioByCollectionId,
  updateTransactionHash,
  GetUpdateTransactionHash,
  updateOwnerAddress
} from "../actions/collectionActions";

const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    isLoading: true,
    isPremintAssetLoading: true,
    collection: [],
    premintAsserts: [],
    CollectionAndBio: null,
    premintAssetDetials: [],
    allPremintAssest: [],
    status: null,
    message: null,
    isError: false,
    isSuccess: false,
    visible: false,
    data: null,
    isSoldStatus: {},
    address: ''
  },
  reducers: {
    setModalVisibleState(state, action) {
      state.visible = action.payload.visible;
      state.data = action.payload.data;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCollection.fulfilled, (state, action) => {
        state.isLoading = false;
        state.collection = action.payload.data;
        state.isSuccess = true;
      })
      .addCase(getCollection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllPremintAsset.pending, (state) => {
        state.isPremintAssetLoading = true;
      })
      .addCase(getAllPremintAsset.fulfilled, (state, action) => {
        state.isPremintAssetLoading = false;
        state.allPremintAssest = action.payload.data;
        state.isSuccess = true;
      })
      .addCase(getAllPremintAsset.rejected, (state, action) => {
        state.isPremintAssetLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPremintAssetCollectionNameAndBioByCollectionId.pending, (state) => {
        // state.isPremintAssetLoading = true;
      })
      .addCase(getPremintAssetCollectionNameAndBioByCollectionId.fulfilled, (state, action) => {
        // state.isPremintAssetLoading = false;
        state.CollectionAndBio = action.payload;
        state.isSuccess = true;
      })
      .addCase(getPremintAssetCollectionNameAndBioByCollectionId.rejected, (state, action) => {
        // state.isPremintAssetLoading = false;
        state.message = action.payload;
        state.isError = true;
      })
      .addCase(getPremintAssetByCollectionID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPremintAssetByCollectionID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.premintAsserts = action.payload.data;
        state.isSuccess = true;
      })
      .addCase(getPremintAssetByCollectionID.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.isError = true;
      })
      .addCase(getPremintAssestById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPremintAssestById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.premintAssetDetials = action.payload.data;
        state.address = action.payload.address
      })
      .addCase(getPremintAssestById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(buyPremintAssest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(buyPremintAssest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(buyPremintAssest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateTransactionHash.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTransactionHash.fulfilled, (state, action) => {
        // console.log(action);
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateTransactionHash.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(GetUpdateTransactionHash.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(GetUpdateTransactionHash.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.isSuccess = true;
        state.isSoldStatus = action.payload.transactionStatus
      })
      .addCase(GetUpdateTransactionHash.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateOwnerAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOwnerAddress.fulfilled, (state, action) => {
        // console.log(action);
        state.isLoading = false;
        state.isSuccess = true;
        // state.isSoldStatus = action.payload.transactionStatus
      })
      .addCase(updateOwnerAddress.rejected, (state, action) => {
        // console.log(action);
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setModalVisibleState, reset, setIsSold } = collectionSlice.actions;
export default collectionSlice.reducer;
