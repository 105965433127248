import React, { useEffect } from "react";
import { Spin, Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllPremintAsset } from "../../../../redux/actions/collectionActions";
import { reset } from "../../../../redux/reducers/collectionReducer";
import Card from "./Card";

const FeaturedArt = () => {
  // geting values from redux collectionReducer
  const { allPremintAssest, isPremintAssetLoading } = useSelector(
    (state) => state.collection
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // getting getAllPremintAsset
    dispatch(getAllPremintAsset());
    return () => reset();
  }, []);

  // filter allPremintAsset and show only which one is publish and showItem is set by admin
  const filterData = allPremintAssest?.filter(
    (del) => del.publishItem === true && del.showItem === true
  );

  return (
    <div>
      <div>
        <h1 className="featured-heading">FEATURED ARTWORKS</h1>
      </div>
      <Spin spinning={isPremintAssetLoading}>
        <div className="middleContainer">
          {!isPremintAssetLoading &&
            filterData?.length > 0 &&
            filterData?.map((del) => <Card key={del._id} {...del} />)}
          {!isPremintAssetLoading && filterData?.length === 0 && <Empty />}
        </div>
      </Spin>
    </div>
  );
};

export default FeaturedArt;
