import React, { Fragment } from "react";
import { Route } from "react-router-dom";
const DaniHome = React.lazy(() => import("./screens/DaniHome/DaniHome"));
const Dani = React.lazy(() => import("./screens/MarketItemDaniPage/index"));
const Marketplace = React.lazy(() => import("./screens/Home"));
const MarketItem = React.lazy(() => import("./screens/MarketItem"));
const AssestPage = React.lazy(() => import('./screens/AssestPage/AssestPage'))
const PremintAssestByCollectionId = React.lazy(() => import('./screens/PremintAssestbyCollectionId/PremintAssestByCollectionId'))
const ConnectWallet = React.lazy(() => import('./screens/ConnectWallet'))
const Test = React.lazy(() => import('./screens/Testing/DaniHome'))

const Routes = () => {
  return (
    <Fragment>
      <Route exact path="/" render={() => <DaniHome />} />
      <Route exact path="/testing" render={() => <Test/>} />
      <Route exact path="/dani" render={() => <Dani />} />
      <Route exact path="/marketplace" render={() => <Marketplace />} />
      <Route path="/asset" render={() => <MarketItem />} />
      <Route path="/assetPage/:id" render={() => <AssestPage />} />
      <Route path="/collection/:name/:id" render={() => <PremintAssestByCollectionId />} />
      {/* <Route path="/collection/premintAssets/:id" render={() => <PremintAssestByCollectionId />} /> */}
      <Route exact path="/connect-wallet" render={() => <ConnectWallet />} />
    </Fragment>
  );
};

export default Routes;
