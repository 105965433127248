import React from "react";
import cn from "classnames";
import styles from "./Checkout.module.sass";
import Web3 from "web3";
// import Icon from "../../../../components/Icon";
// import LoaderCircle from "../../../../components/LoaderCircle";
import { useState } from "react/cjs/react.development";
// import Modal from "../../../../components/Modal";
import axios from "axios";
import moment from "moment";
import createWeb3ContractMethodInstance from "../../../../components/CreateWeb3Instance/Web3instance";
import getinstancesParamter from "../../../../components/getInstancesParameter/Constant";
const contract = require("../../../../components/ContractAddress/Contract");
const constant = require("../../../../components/Constant/Constant");
var apiBaseUrl = constant.getAPiUrl();

const Checkout = ({ className, ...props }) => {
  const [updatetoggle, setupdatetoggle] = useState(false);
  const [blockloader, setblockloader] = useState(false);
  const [alertmessage, setalertmessage] = useState("");
  const items = [
    {
      title: "Your balance",
      value: `${localStorage.CurrentBalance} ETH`,
    },
    {
      title: "Token Price",
      value: `${props.tokenprice} ETH`,
    },
    // {
    //   title: "Service Fees",
    //   value: `${props.conFees} ETH`,
    // },
    {
      title: "You will pay",
      value: `${props.calculatevalue} ETH`,
    },

    {
      title: "Inclusive of convenience Fees",
      // value: `${props.adminfees} %`,
    },
    // {
    //   title: "Royalities",
    //   value: `${props.royalities} %`,
    // },
  ];

  const getWeb3Instance = async (
    registrationType,
    blockchaintype,
    networktype,
    erctype
  ) => {
    console.log("calll");

    let contract_Methods = await createWeb3ContractMethodInstance(
      registrationType,
      blockchaintype,
      networktype,
      erctype
    );

    return contract_Methods.contract_Methods;
  };

  const purchasenowfunction = async () => {
    let pricetokenid = localStorage.PricetokenId;
    let currentaddress = localStorage.CurrentAddress;

    let convertnumber = props.calculatevalue.toString();
    //  let convertnumber = "0.012"

    var tokenPrice = await Web3.utils.toWei(convertnumber, "ether");
    // console.log(tokenPrice);

    setupdatetoggle(true);

    // console.log(pricetokenid);
    // console.log(currentaddress);
    // console.log(tokenPrice);
    props.blockloader(true);
    props.handleContactSubmission(false);

    //let getres = await window.contract.methods.approve('0xF02a25062716dbCb906Bb5c61eD7390c9eA27C6D',pricetokenid).send({from:currentaddress})

    // console.log(getres)

    try {
      props.alertmessage("Please Wait ...");

      // console.log(
      //   pricetokenid,
      //   "buytokenoooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo"
      // );
      const getWeb3InstanceParameter = await getinstancesParamter();

      let contractmethod = await getWeb3Instance(
        getWeb3InstanceParameter.martketRegistrationtype,
        getWeb3InstanceParameter.Blockchaintype,
        getWeb3InstanceParameter.networkType,
        getWeb3InstanceParameter.erctype
      );

      let response = await contractmethod.methods
        .BuyToken(pricetokenid)
        .send({ from: currentaddress, value: tokenPrice })
        .on("transactionHash", function (hash) {
          console.log(hash);

          // settransactionhash(hash)
        });

      let uniqueid = localStorage.getItem("_id");

      const header = {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      };

      let dataofsold = {
        _id: uniqueid,
        isSold: true,
      };
      axios
        .post(`${apiBaseUrl}/api/user/isSoldStatus`, dataofsold, {
          headers: header,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      // let response = await window.marketcontract.methods.BuyToken(pricetokenid).send({from: currentaddress})
      await window.ethereum.enable();
      const checknetworkId = window.ethereum.networkVersion;

      const headers = {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      };
      const data = {
        walletAddress: currentaddress.toLowerCase(),
        tokenId: pricetokenid,
        sell_price: props.saleprice,
        royalty_paid: props.royalities,
        convience_fees_paid: props.adminfees,
        action: "Buy",
        from: contract.getmarketcontractaddress(),
        to: currentaddress.toLowerCase(),
        // "date":moment(Date.now()).format('DD-MM-YYYY'),
        date: moment().format("YYYY-MM-DD, h:mm:ss a"),
        transactionHash: response.transactionHash,
        networkId: checknetworkId,
      };

      let purchase = await axios
        .post(`${apiBaseUrl}/api/user/collectibleTokenId`, data, {
          headers: headers,
        })
        .then((response) => {
          props.alertmessage(
            `${props.tokenname} Token Purchase From NFTBrewery Successfully`
          );
        })
        .catch((error) => {
          alert(error);
        });

      // let approveforsale =  await  axios.post(`${apiBaseUrl}/api/user/mintToken`, data, {
      //   headers: headers
      // }).then((response)=>{
      //   props.alertmessage(`${props.tokenname} Token Purchase From Proteum Successfully`)

      // }) .catch((error)=>{
      //   alert(error)
      // })

      // let ownerupdatelist =  await  axios.post(`${apiBaseUrl}/api/user/buyTokenOwnerListUpdate`, data, {
      //   headers: headers
      // }).then((response)=>{

      // }) .catch((error)=>{
      //   alert(error)
      // })

      // props.alertmessage(`${props.tokenname} Token Purchase From Proteum Successfully`)
      window.location = "/";
      // window.location.reload();
      console.log(response);
    } catch (error) {
      console.log("error", error);
      props.blockloader(false);
    }

    // console.log(response)
    // if(response){
    //   setupdatetoggle(false)
    //   window.location= "/profile"
    // }
  };

  // let percentvalue = localStorage.saleprice * localStorage.adminfees /100
  // console.log(typeof percentvalue)
  // console.log(typeof +localStorage.saleprice)

  // let totalvalue = percentvalue + +localStorage.saleprice
  // console.log(totalvalue)

  console.log(props);
  // let tokenname = localStorage.getItem("ItemDetails")

  return (
    <>
      <div className={cn(className, styles.checkout)}>
        <div className={cn("h4", styles.title)}>Checkout</div>
        <div className={styles.info}>
          You are about to purchase <strong>{props.tokenname}</strong> from{" "}
          <strong>NFTBrewery</strong>
        </div>
        <div className={styles.table}>
          {items.map((x, index) => (
            <div className={styles.row} key={index}>
              <div className={styles.col}>{x.title}</div>
              <div className={styles.col}>{x.value}</div>
            </div>
          ))}
        </div>
        {/* <div className={styles.attention}>
        <div className={styles.preview}>
          <Icon name="info-circle" size="32" />
        </div>
        <div className={styles.details}>
          <div className={styles.subtitle}>This creator is not verified</div>
          <div className={styles.text}>Purchase this item at your own risk</div>
        </div>
      </div> */}
        {/* <div className={cn("h4", styles.title)}>Follow steps</div>
      <div className={styles.line}>
        <div className={styles.icon}>
          <LoaderCircle className={styles.loader} />
        </div>
        <div className={styles.details}>
          <div className={styles.subtitle}>Purchasing</div>
          <div className={styles.text}>
            Sending transaction with your wallet
          </div>
        </div>
      </div> */}
        {/* <div className={styles.attention}>
         <div className={styles.preview}>
          <Icon name="info-circle" size="32" />
        </div> 
         <div className={styles.details}>
          <div className={styles.subtitle}>This creator is not verified</div>
          <div className={styles.text}>Purchase this item at your own risk</div>
        </div>
         <div className={styles.avatar}>
          <img src="/images/content/avatar-3.jpg" alt="Avatar" />
        </div>
      </div> */}
        <div className={styles.btns}>
          <button
            className={cn("button", styles.button)}
            onClick={() => {
              purchasenowfunction();
            }}
          >
            I understand, continue
          </button>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={() => {
              props.handleContactSubmission(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default Checkout;
