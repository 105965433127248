import Web3 from "web3";

// check checknetworkId and setting paramter
async function getinstancesParamter() {
  //enable window.ethereum

  await loadWeb3();
  // default values
  const martketRegistrationtype = 1;
  const nftRegistrationtype = 0;

  // let Blockchaintype = 0
  let erctype = 0;
  await window.ethereum.enable();
  const checknetworkId = window.ethereum.networkVersion;

  console.log(checknetworkId);

  let networkType;
  let Blockchaintype;
  if (checknetworkId == 4) {
    networkType = 2;
    Blockchaintype = 0;
  } else if (checknetworkId == 80001) {
    networkType = 1;
    Blockchaintype = 1;
  } else if (checknetworkId == 3) {
    networkType = 1;
    Blockchaintype = 0;
  }else if(checknetworkId == 1){
    networkType = 0;
    Blockchaintype = 0;
  }

  let collecionofTypeobject = {
    martketRegistrationtype,
    nftRegistrationtype,
    Blockchaintype,
    networkType,
    erctype,
  };
  return collecionofTypeobject;
}

async function loadWeb3() {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    window.ethereum.enable();
  }
}

export default getinstancesParamter;
