//not working

var contractaddress = "0x406821B60363910205Cb7f1D96F51CBCAC3F74d9";

var marketplaceaddress = "0x434D82022Ba2281fBd91449CEDCccF8c70D00397";

export function getcontractaddress() {
  return contractaddress;
}

export function getmarketcontractaddress() {
  return marketplaceaddress;
}
