import React,{ useState,useEffect } from "react";
import cn from "classnames";
import styles from "./RemoveSale.module.sass";
import Web3 from "web3";
const constant = require("../../components/Constant/Constant")
const contractABI = require('../../components/ABI/NFTSalePurchase.json')
const marketcontractABI = require('../../components/ABI/NFTMarketPlace.json')
 const marketcontract = require("../../components/ContractAddress/Contract")
 const contract = require("../../components/ContractAddress/Contract")

const RemoveSale = ({ className,...props }) => {

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    load();
  },[])

  async function loadContract() {
    return await new window.web3.eth.Contract(contractABI.abi,contract.getcontractaddress())
  }
   

  async function load() {

    await loadWeb3();
     window.contract = await loadContract();
     window.marketcontract = await marketloadContract()
    
    //      console.log(tokenURI)
    // console.log(JSON.parse(decodeURIComponent(tokenURI)));    
    
 
  }
  async function marketloadContract(){
    return await new window.web3.eth.Contract(marketcontractABI.abi,contract.getmarketcontractaddress())
  }
  async function loadWeb3() {
    if (window.ethereum) {
      // console.log(window.ethereum)
       window.web3 = new Web3(window.ethereum);
       window.ethereum.enable();
            
    }
  }

  const removenowFunction =async ()=>{
    try{
    let accountaddress = localStorage.getItem("CurrentAddress")
    setVisible(true)

    
    
    let response = await  window.marketcontract.methods.RemoveTokenFromSale(localStorage.PricetokenId).send({from: accountaddress})
    console.log(response)
    
    if(response){
      
       window.location = "/profile"
    }

  }
  catch (error) {
    console.log("error", error);
    setVisible(false)
  
  }
  



  }

  console.log(props.pricetokenid)
  return (
    <div className={cn(className, styles.transfer)}>
      <div className={cn("h4", styles.title)}>Remove from sale</div>
      <div className={styles.text}>
        Do you really want to remove your item from sale? You can put it on sale
        anytime
      </div>

      {visible ? (
 <div className={styles.btns}>

        
 <button className={cn("button", styles.button)} onClick={()=>{
  
 }}>Processing</button>
 {/* <button className={cn("button-stroke", styles.button)}>Cancel</button> */}
</div>
      ):(
        <div className={styles.btns}>

        
        <button className={cn("button", styles.button)} onClick={()=>{
          removenowFunction()
        }}>Remove now</button>
        <button className={cn("button-stroke", styles.button)} onClick={()=>{
          props.removecancel(false)
        }}>Cancel</button>
      </div>
      )}
     
    </div>
  );
};

export default RemoveSale;
