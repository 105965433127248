import React, { useEffect } from "react";
import Hero from "./Hero";
// import Selection from "./Selection";
// import Popular from "./Popular";
// import HotBid from "../../components/HotBid";
import HotBid from "./HotBid";
// import Collections from "./Collections";
// import Discover from "./Discover";
// import Description from "./Description";
// import Web3 from "web3";
// import axios from "axios";

// const contractABI = require("../../components/ABI/NFTSalePurchase.json");
// const contract = require("../../components/ContractAddress/Contract");
// const constant = require("../../components/Constant/Constant");

// var apiBaseUrl = constant.getAPiUrl();
// console.log(contract.getcontractaddress())
import Page from "../../components/Page";

const Home = () => {
  useEffect(() => {});

  return (
    <Page>
      <Hero />
      {/* <Selection /> */}
      {/* <Popular /> */}
      {/* <Discover /> */}
      {/* <HotBid classSection="section" /> */}
      <HotBid />
      {/* <Collections /> */}
      {/* <Discover /> */}
      {/* <Description /> */}
    </Page>
  );
};

export default Home;
