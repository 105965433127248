import React, { useState, useEffect } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Actions.module.sass";
import Transfer from "../Transfer";
import style from "../Transfer/Transfer.module.sass";
import RemoveSale from "../RemoveSale";
import axios from "axios";
import Burn from "../Burn";
import Report from "../Report";
import Icon from "../Icon";
import Web3 from "web3";
import Modal from "../../components/Modal";
import moment from "moment";
import createWeb3ContractMethodInstance from "../../components/CreateWeb3Instance/Web3instance";
import getinstancesParamter from "../../components/getInstancesParameter/Constant";
const contract = require("../../components/ContractAddress/Contract");
const marketcontractABI = require("../../components/ABI/NFTMarketPlace.json");
const marketcontract = require("../../components/ContractAddress/Contract");
const constant = require("../../components/Constant/Constant");
var apiBaseUrl = constant.getAPiUrl();

const Actions = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const [visibleModalTransfer, setVisibleModalTransfer] = useState(false);
  const [visibleModalRemoveSale, setVisibleModalRemoveSale] = useState(false);
  const [visibleModalBurn, setVisibleModalBurn] = useState(false);
  const [visibleModalReport, setVisibleModalReport] = useState(false);
  const [receiveraddress, setreceiveraddress] = useState("");
  const [blockloader, setblockloader] = useState(false);
  const [alertmessage, setalertmessage] = useState("");
  const [continueorpleasewait, setcontinueorpleasewait] = useState("Continue");
  const [disableonchangemessage, setdisableonchangemessage] = useState(false);

  const items = [
    // {
    //   title: "Change price",
    //   icon: "coin",
    //   action: () => console.log("coin"),
    // },
    {
      title: "Transfer token",
      icon: "arrow-right-square",
      action: () => setVisibleModalTransfer(true),
    },
    // {
    //   title: "Remove from sale",
    //   icon: "close-circle",
    //   action: () => setVisibleModalRemoveSale(true),
    // },
    {
      title: "Burn token",
      icon: "close-circle",
      action: () => setVisibleModalBurn(true),
    },
    // {
    //   title: "Report",
    //   icon: "info-circle",
    //   action: () => setVisibleModalReport(true),
    // },
  ];

  useEffect(() => {
    // load();
  }, []);

  const onChangeBurnfunction = (value) => {
    setVisibleModalBurn(value);
  };

  const onBurnContunefunction = async (value) => {
    setVisibleModalBurn(value);
    let tokenId = localStorage.PricetokenId;
    let CurrentAddress = localStorage.CurrentAddress;
    console.log(CurrentAddress);
    const getWeb3InstanceParameter = await getinstancesParamter();
    let contractmethod = await getWeb3Instance(
      getWeb3InstanceParameter.nftRegistrationtype,
      getWeb3InstanceParameter.Blockchaintype,
      getWeb3InstanceParameter.networkType,
      getWeb3InstanceParameter.erctype
    );
    console.log(contractmethod);
    let burnTokenStatus = await contractmethod.methods
      .burnToken(tokenId)
      .send({ from: CurrentAddress });
    console.log(burnTokenStatus);

    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;

    const headers = {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    };
    const data = {
      walletAddress: CurrentAddress.toLowerCase(),
      networkId: checknetworkId,
    };

    let purchase = await axios
      .post(`${apiBaseUrl}/api/user/burnToken`, data, {
        headers: headers,
      })
      .then((response) => {
        window.location = "/profile";
      })
      .catch((error) => {
        alert(error);
      });
  };
  const getWeb3Instance = async (
    registrationType,
    blockchaintype,
    networktype,
    erctype
  ) => {
    console.log("calll");
    let contract_Methods = await createWeb3ContractMethodInstance(
      registrationType,
      blockchaintype,
      networktype,
      erctype
    );

    return contract_Methods.contract_Methods;
  };
  async function load() {
    await loadWeb3();
    // window.contract = await loadContract();
    window.marketcontract = await marketloadContract();

    // localStorage.setItem("darkMode",true)
  }
  async function loadWeb3() {
    if (window.ethereum) {
      // console.log(window.ethereum)
      window.web3 = new Web3(window.ethereum);
      window.ethereum.enable();
    }
  }
  async function marketloadContract() {
    return await new window.web3.eth.Contract(
      marketcontractABI.abi,
      contract.getmarketcontractaddress()
    );
  }

  // async function loadContract() {
  //   return await new window.web3.eth.Contract(contractABI.abi,contract.getcontractaddress())
  // }

  const continuefunction = async () => {
    let pricetokenid = localStorage.PricetokenId;
    let currentaddress = localStorage.CurrentAddress;
    console.log(pricetokenid);
    console.log(currentaddress);
    console.log(receiveraddress);

    if (
      receiveraddress.toLowerCase() != currentaddress &&
      receiveraddress != ""
    ) {
      console.log("if part");

      try {
        setcontinueorpleasewait("Please Wait");

        // let response = await window.marketcontract.methods.BuyToken(pricetokenid).send({from: currentaddress,value:"02255555"})

        // let gettokenroyalities = await window.marketcontract.methods.getTokenRoyalties(pricetokenid).call();
        // console.log(gettokenroyalities)
        setdisableonchangemessage(true);

        const getWeb3InstanceParameter = await getinstancesParamter();

        let contractmethod = await getWeb3Instance(
          getWeb3InstanceParameter.nftRegistrationtype,
          getWeb3InstanceParameter.Blockchaintype,
          getWeb3InstanceParameter.networkType,
          getWeb3InstanceParameter.erctype
        );

        let response = await contractmethod.methods
          .safeTransferFrom(currentaddress, receiveraddress, pricetokenid)
          .send({ from: currentaddress })
          .on("transactionHash", function (hash) {
            console.log(hash);

            // settransactionhash(hash)
          });

        await window.ethereum.enable();
        const checknetworkId = window.ethereum.networkVersion;

        const headers = {
          Authorization: localStorage.token
            ? `Bearer ${localStorage.token}`
            : undefined,
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        };
        const data = {
          walletAddress: receiveraddress.toLowerCase(),
          tokenId: pricetokenid,
          sell_price: "0",
          royalty_paid: "0",
          convience_fees_paid: "0",
          action: "Transfer",
          from: contract.getmarketcontractaddress(),
          to: receiveraddress.toLowerCase(),
          // "date":moment(Date.now()).format('DD-MM-YYYY'),
          date: moment().format("YYYY-MM-DD, h:mm:ss a"),
          transactionHash: response.transactionHash,
          networkId: checknetworkId,
        };

        let purchase = await axios
          .post(`${apiBaseUrl}/api/user/collectibleTokenId`, data, {
            headers: headers,
          })
          .then((response) => {
            setdisableonchangemessage(false);
            window.location = "/profile";
          })
          .catch((error) => {
            alert(error);
            setcontinueorpleasewait("Continue");
          });
      } catch (error) {
        console.log("error", error);
        setcontinueorpleasewait("Continue");
        // props.blockloader(false)
      }
    } else {
      // setblockloader(true)

      console.log("else part");
      if (receiveraddress == "") {
        setalertmessage("Please Paste Receiver Address");
      } else {
        setalertmessage("Invalid Receiver Address");
      }
    }
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div
          className={cn(styles.actions, className, {
            [styles.active]: visible,
          })}
        >
          <button
            className={cn("button-circle-stroke", styles.button)}
            onClick={() => setVisible(!visible)}
          >
            <Icon name="more" size="24" />
          </button>
          <div className={styles.body}>
            {items.map((x, index) => (
              <div className={styles.item} key={index} onClick={x.action}>
                <Icon name={x.icon} size="20" />
                <span>{x.title}</span>
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
      <Modal
        visible={visibleModalTransfer}
        // onClose={() => setVisibleModalTransfer(false)}
      >
        <div className={cn(className, style.transfer)}>
          <div className={cn("h4", style.title)}>Transfer token</div>
          <div className={style.text}>
            You can transfer tokens from your address to another
          </div>
          <div className={style.info}>Receiver Address</div>
          <div className={style.field}>
            {disableonchangemessage ? (
              <input
                className={style.input}
                type="text"
                name="address"
                placeholder="Paste address"
                value={receiveraddress}

                //  onChange={(e)=>{
                //    setreceiveraddress(e.target.value)

                //  }}
              />
            ) : (
              <input
                className={style.input}
                type="text"
                name="address"
                placeholder="Paste address"
                value={receiveraddress}
                onChange={(e) => {
                  setreceiveraddress(e.target.value);
                }}
              />
            )}

            <br />

            <span style={{ marginTop: "5%" }}>{alertmessage}</span>
          </div>
          <div className={style.btns}>
            {continueorpleasewait == "Continue" ? (
              <>
                <button
                  className={cn("button", styles.button)}
                  onClick={() => {
                    continuefunction();
                  }}
                >
                  Continue
                </button>{" "}
                <button
                  className={cn("button-stroke", styles.button)}
                  onClick={() => {
                    setVisibleModalTransfer(false);
                    setreceiveraddress("");
                    setalertmessage("");
                  }}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button className={cn("button", styles.button)}>
                Please Wait
              </button>
            )}
          </div>
        </div>

        {/* <Transfer/> */}
      </Modal>
      <Modal
        visible={visibleModalRemoveSale}
        onClose={() => setVisibleModalRemoveSale(false)}
      >
        <RemoveSale />
      </Modal>
      <Modal
        visible={visibleModalBurn}
        onClose={() => setVisibleModalBurn(false)}
      >
        <Burn
          onChangeBurnfunction={onChangeBurnfunction}
          onBurnContunefunction={onBurnContunefunction}
        />
      </Modal>
      <Modal
        visible={visibleModalReport}
        onClose={() => setVisibleModalReport(false)}
      >
        <Report />
      </Modal>
    </>
  );
};

export default Actions;
