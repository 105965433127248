import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Profile.module.sass";
import Icon from "../../components/Icon";
import User from "./User";
import Items from "./Items";
import CollectedItems from "./CollectedItems";
import SaleItems from "./SaleItems";
import { bids } from "../../mocks/bids";
import Modal from "../../components/Modal";
import axios from "axios";
const constant = require("../../components/Constant/Constant");
var apiBaseUrl = constant.getAPiUrl();

const Profile = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [profilePhoto, setprofilePhoto] = useState("");

  const [checktype, setchecktype] = useState("");
  const [previewImage, setpreviewImage] = useState(
    "https://gateway.pinata.cloud/ipfs/QmWzVBtcM6bAjUTm8bZ9KAy6rbCLW1viKeKt2N43as8XPj"
  );
  const [blockloader, setblockloader] = useState(false);
  const [alertmessage, setalertmessage] = useState("");

  const [profilekey, setprofilekey] = useState("");

  const [invalidfiletypetoggle, setinvalidfiletypetoggle] = useState(false);
  const [showsavebuttontoggle, setshowsavebuttontoggle] = useState(false);

  const navLinks = ["Created", "Collectibles", "On Sale"];

  useEffect(() => {
    getaccountaddress();
  }, []);

  //fetch account address and get coverphoto image from getCoverPhoto api
  async function getaccountaddress() {
    let account = localStorage.CurrentAddress;
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;

    const headers = {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(
        `${apiBaseUrl}/api/user/getCoverPhoto/${account.toLowerCase()}/${checknetworkId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // if response get data
        if (response.data.message.coverPhoto.length == 0) {
          //setprofile photo in profile
          setprofilePhoto("/images/content/bg-profile.jpg");
        } else {
          setprofilePhoto(response.data.message.coverPhoto);
        }
      })
      .catch((error) => {
        console.log(error);
        setprofilePhoto("/images/content/bg-profile.jpg");
      });
  }

  // onChange the cover photo
  const onFileChange = async ({ target }) => {
    setVisible(false);
    setchecktype(target.files[0].type);

    const fileSize = target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      alert("File size exceeds 2 MB");
    } else {
      if (
        target.files[0].type == "video/mp4" ||
        target.files[0].type == "audio/mpeg"
      ) {
        setinvalidfiletypetoggle(true);
        setalertmessage("We Support JPEG, PNG, GIF Only");
      } else {
        if (target.files[0]) {
          setpreviewImage("");
          setprofilePhoto("");
          setblockloader(true);

          setalertmessage("File Uploading...");

          const account = localStorage.getItem("CurrentAddress");
          await window.ethereum.enable();
          const checknetworkId = window.ethereum.networkVersion;
          const data = new FormData();
          data.append("coverPhoto", target.files[0]);
          data.append("walletAddress", account.toLowerCase());
          data.append("networkId", checknetworkId);
          const headers = {
            "content-type": "multipart/form-data",
          };

          //call api for upload cover photo
          axios
            .post(`${apiBaseUrl}/api/user/uploadCoverPhoto`, data, {
              headers: headers,
            })
            .then((response) => {
              console.log(response);

              if (response.data.status == 200) {
                setprofilePhoto(response.data.message.coverPhoto);
                setprofilekey(response.data.message.coverPhotoKey);
                setblockloader(false);
                setshowsavebuttontoggle(true);

                savephotofuction(
                  response.data.message.coverPhoto,
                  response.data.message.coverPhotoKey
                );
              }
            })
            .catch((error) => {
              console.log(error);
              setblockloader(false);
            });
        }
      }
    }
  };

  const savephotofuction = async (coverphoto, coverkey) => {
    const account = localStorage.getItem("CurrentAddress");
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;
    const data = new FormData();
    data.append("coverPhoto", coverphoto);
    data.append("walletAddress", account.toLowerCase());
    data.append("coverPhotoKey", coverkey);
    data.append("networkId", checknetworkId);
    const headers = {
      "content-type": "multipart/form-data",
    };

    axios
      .post(`${apiBaseUrl}/api/user/setCoverPhoto`, data, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
        setblockloader(false);
        setVisible(false);
      })
      .catch((error) => {});
  };

  return (
    <div className={styles.profile} style={{ backgroundColor: "" }}>
      <Modal visible={invalidfiletypetoggle}>
        <div
          onClick={() => {
            setblockloader(false);
          }}
        >
          <center>
            <h4>{alertmessage}</h4>
          </center>

          <br />
          <center>
            {" "}
            <h4>
              <span
                style={{ marginLeft: "", cursor: "pointer" }}
                onClick={() => {
                  setinvalidfiletypetoggle(false);
                }}
              >
                OK
              </span>
            </h4>
          </center>

          <div></div>
        </div>
      </Modal>
      <Modal visible={blockloader}>
        <div
          onClick={() => {
            setblockloader(false);
          }}
        >
          <center>
            <h4>{alertmessage}</h4>
          </center>

          <br />

          <div></div>
        </div>
      </Modal>
      <div
        className={cn(styles.head, { [styles.active]: visible })}
        style={{
          backgroundImage: `url(${profilePhoto})`,
        }}
      >
        <div className={cn("container", styles.container)}>
          <div className={styles.btns}>
            <button
              className={cn("button-small", styles.button)}
              onClick={() => setVisible(true)}
            >
              <span>Edit cover photo</span>
              <Icon name="edit" size="16" />
            </button>
            <Link
              className={cn("button-small", styles.button)}
              to="profile-edit"
            >
              <span>Edit profile</span>
              <Icon name="image" size="16" />
            </Link>
          </div>
          <div className={styles.file}>
            <input type="file" onChange={onFileChange} />
            <div className={styles.wrap}>
              <Icon name="upload-file" size="48" />
              <div className={styles.info}>Drag and drop your photo here</div>
              <div className={styles.text}>or click to browse</div>
            </div>
            {showsavebuttontoggle && (
              <button
                className={cn("button-small", styles.button)}
                onClick={() => {
                  savephotofuction();
                }}
              >
                Save photo
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <User className={styles.user} />
          <div className={styles.wrapper}>
            <div className={styles.nav}>
              {navLinks.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  key={index}
                  onClick={() => setActiveIndex(index)}
                >
                  {x}
                </button>
              ))}
            </div>
            <div className={styles.group}>
            
              <div className={styles.item}>
                {activeIndex === 0 && (
                  <Items class={styles.items} items={bids.slice(0, 2)} />
                )}
                {activeIndex === 1 && (
                  <CollectedItems
                    class={styles.items}
                    items={bids.slice(0, 6)}
                  />
                )}
                {activeIndex === 2 && (
                  <SaleItems class={styles.items} items={bids.slice(0, 3)} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
