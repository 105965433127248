import React,{useState,useEffect} from "react";
import cn from "classnames";
import styles from "./Items.module.sass";
import Card from "../../../components/ProfileSaleCard";
import Modal from "../../../components/Modal";
import Loader from "../../../components/Loader";
import axios from "axios";
import Web3 from "web3";
const constant = require("../../../components/Constant/Constant")


 const contract = require("../../../components/ContractAddress/Contract")
 var apiBaseUrl = constant.getAPiUrl();


const Items = ({ className, items }) => {
  const [arrayofmintvalue,setarrayofmintvalue] = useState([]);  
  const [arraylength,setarraylength]= useState(0);
  const [nottokenmessage,setnottokenmessage]= useState("")
  const [tokenexpiredtoggle,settokenexpiredtoggle]=useState(false)
  const [arrayofpush,setarrayofpush] = useState([])
  const [collectionoftokenId,setcollectionoftokenId]= useState([])

  

 const data = arrayofmintvalue

  
  useEffect(() => {
    
   
    
    //calling function for get asset regarding to put on sale
    getPutOnSellTokenId()

  


  
    


  },[])


  //getting respose using api we get all sell token id asset 
  const getPutOnSellTokenId = async ()=>{
    let url = ""
    let accountaddress = localStorage.getItem("CurrentAddress")
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion
    if(accountaddress){
      url = accountaddress.toLowerCase()
     }

   
 
    axios({
      method: 'get',
    
      url: `${apiBaseUrl}/api/user/getPutOnSellTokenId/${url}/${checknetworkId}`,
      headers: {
        "Authorization":localStorage.token ? `Bearer ${localStorage.token}` : undefined,
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
  
  }).then((response)=>{
   
    if(response.data.status == 200){

     
       if(response.data.message.marketPlaceTokenId.length){

        
        setcollectionoftokenId(response.data.message.marketPlaceTokenId)
    
        setarraylength(response.data.message.marketPlaceTokenId.length)
      }
      else{
        setnottokenmessage("No NFTs are available")
      }   
  
      
      
    }
    else if(response.data.status == 404) {
      setnottokenmessage("No NFTs are Available")
    }

  }).catch((error)=>{
    console.log(error)
 
    if(error.message == "Request failed with status code 400"){
      settokenexpiredtoggle(true)
  
    }
    else {
      setnottokenmessage("No NFTs are Available")
    }
  })

  }

  

  


  return (
    <div className={cn(styles.items, className)}>
      <Modal
    
    visible={tokenexpiredtoggle}
   

 >
   
   <div >
  
   <center><h4>Your Session Has Expired</h4></center>
   <br/>
   <center><h4><span style={{cursor:"pointer"}} onClick={()=>{
     window.location = "/login"
   }}>Login</span> {' '}{' '}<span style={{marginLeft:"10%",cursor:"pointer"}}  onClick={()=>{
    settokenexpiredtoggle(false)
  }}>Cancel</span></h4></center>


   <div >
   
                </div>
   </div>
   </Modal>
      <div className={styles.list}>
 
  
   <>
   
   <center><h3 style={{marginTop:"50%"}}>{nottokenmessage}</h3></center> 
   {collectionoftokenId.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
  
  </>
  
   
        

    
       
      </div>
   
    </div>
  );
};

export default Items;
