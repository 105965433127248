import axios from "axios";
import { BACKEND_API_URL } from "../utils/enviroment";
const URI = "api";

// const collectionApi({
const getCollectionAPI = () =>
  axios
    .get(`${BACKEND_API_URL}/${URI}/admin/allCollection`)
    .then((res) => res.data);

const getPremintAssetByCollectionIdAPI = (id) =>
  axios
    .get(`${BACKEND_API_URL}/${URI}/admin/allPremintAsset/${id}`)
    .then((res) => res.data);

const getPremintAssetByIdAPI = (id) =>
  axios
    .get(`${BACKEND_API_URL}/${URI}/admin/preMintAsset/${id}`)
    .then((res) => res.data);

const getAllPremintAssetAPI = () =>
  axios
    .get(`${BACKEND_API_URL}/${URI}/admin/premintedAsset`)
    .then((res) => res.data);

const getPremintAssetCollectionNameAndBioByCollectionIdAPI = (id) =>
  axios
    .get(`${BACKEND_API_URL}/${URI}/admin/getBio/${id}`)
    .then((res) => res.data);

const updateTransactionHashAPI = (data) =>
  // console.log(data)
  axios
    .post(`${BACKEND_API_URL}/${URI}/admin/updateTransactionHash`, data)
    .then((res) => res.data);

const getUpdateTransactionHashAPI = (id) =>
  // console.log(data)
  axios
    .get(`${BACKEND_API_URL}/${URI}/admin/getTransactionStatus/${id}`)
    .then((res) => res.data);

const getSmartContractAddressAPI = (data) =>
  axios
    .get(`${BACKEND_API_URL}/${URI}/admin/getSmartContractAddress/${data?.registrationType}/${data?.blockchainType}/${data?.networkType}/${data?.smartContractType}`)
    .then((res) => res.data);

const updateOwnerAddressAPI = (data) =>
  axios
    .post(`${BACKEND_API_URL}/${URI}/admin/updateAssetOwner`, data)
    .then((res) => res.data);

// https://backend.daniclosandorra.thenftbrewery.com/api/admin/getSmartContractAddress/:registrationType/:blockchainType/:networkType/:smartContractType

export {
  getCollectionAPI,
  getPremintAssetCollectionNameAndBioByCollectionIdAPI,
  getPremintAssetByCollectionIdAPI,
  getPremintAssetByIdAPI,
  getAllPremintAssetAPI,
  updateTransactionHashAPI,
  getUpdateTransactionHashAPI,
  getSmartContractAddressAPI,
  updateOwnerAddressAPI
};
