import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./ProfileEdit.module.sass";
import Control from "../../components/Control";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Icon from "../../components/Icon";
import axios from "axios"
const constant = require("../../components/Constant/Constant")
var apiBaseUrl = constant.getAPiUrl();

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Login",
  },
];

const Signup = () => {
const [submitbutton,setsubmitbutton] = useState(false)
const [firstName,setfirstName] = useState("")
const [lastName,setlastName] = useState("")
const [mobile,setmobile]=useState("")
const [email,setemail]=useState("")
const [otpnumber,setotpnumber]= useState("")
const [responsemessage,setresponsemessage]= useState("")

const onTextFieldChange = ({ target }) => {
  // setstate({ ...state, [target.name]: target.value });

};

const singnupsubmitbutton = ()=>{
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
  const data = {
    firstName:firstName,
    lastName:lastName,
    phone:mobile,
    email:email
  }
  axios.post(`${apiBaseUrl}/api/user/signUp`, data, {
    headers: headers
  })
  .then((response) => {
    console.log(response)
    
   
    if(response.data.status == 200){
      setsubmitbutton(true)
      setresponsemessage(response.data.message)

     
      // window.location ="/"
    }

    else if(response.data.status == 406){
      // console.log("inside of error")
      setresponsemessage(response.data.error)
     

    }
    else if(response.data.status == 403){
      // console.log("inside of error")
      setresponsemessage(response.data.error)
     

    }
    // window.location.reload()

  })
  .catch((error) => {
    console.log(error)
    // seterrormodaltoggle(true)
    setresponsemessage("Please Provide Valid Email")
   
  })

}


const submitotpfunction = ()=>{
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
  const data = {
    otp:otpnumber,
    email:email
  }
  axios.post(`${apiBaseUrl}/api/user/verifyOTP`, data, {
    headers: headers
  })
  .then((response) => {
    console.log(response)
   
    if(response.data.status == 200){
      // localStorage.setItem("isEmail",true)
    //  window.location = "/"
    callloginfunction()
     
      
    }
    

  })
  .catch((error) => {
    console.log(error)
    setresponsemessage("Invalid OTP, Check Your Email Again")
    // seterrormodaltoggle(true)
   
  })

}

const callloginfunction = () =>{
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
  const data = {
    otp:otpnumber,
    email:email
  }
 
  axios.post(`${apiBaseUrl}/api/user/Login`, data, {
    headers: headers
  })
  .then((response) => {
    console.log(response)
   
   
    if(response.data.status == 200){
      localStorage.setItem("isEmail",true)
      localStorage.setItem("token",response.data.token)
    
       window.location = "/"
     
      // window.location ="/"
    }
    // else if(response.data.status == 404){
    //   console.log("inside of error")
     

    // }
    // window.location.reload()

  })

  .catch((error) => {
    console.log(error)
    setresponsemessage("Invalid OTP, Check Your Email Again")

    // setresponsemessage(error)
    // seterrormodaltoggle(true)
   
  })
}

const resendotpfunction = ()=>{
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
  const data = {
   
    email:email
  }
 
  axios.post(`${apiBaseUrl}/api/user/resendOTP`, data, {
    headers: headers
  })
  .then((response) => {
    console.log(response)
   
   
    if(response.data.status == 200){
      console.log(response.data)
       setresponsemessage(`New ${response.data.message}`)
    
      // window.location = "/"
     
      // window.location ="/"
    }
    // else if(response.data.status == 404){
    //   console.log("inside of error")
     

    // }
    // window.location.reload()

  })

  .catch((error) => {
    console.log(error)
    //  seterrormodaltoggle(true)
    //  setresponsemessage(error)
   
  })

}

  return (
    <div className={styles.page}>
      {/* <Control className={styles.control} item={breadcrumbs} /> */}
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.top}>
            <h1 className={cn("h2", styles.title)}>Signup</h1>
            {/* <div className={styles.info}>
              You can set preferred display name, create{" "}
              <strong>your profile URL</strong> and manage other personal
              settings.
            </div> */}
          </div>
          <div className={styles.row}>
            {/* <div className={styles.col}>
              <div className={styles.user}>
                <div className={styles.avatar}>
                  <img src="/images/content/avatar-1.jpg" alt="Avatar" />
                </div>
                <div className={styles.details}>
                  <div className={styles.stage}>Profile photo</div>
                  <div className={styles.text}>
                    We recommend an image of at least 400x400. Gifs work too{" "}
                    <span role="img" aria-label="hooray">
                      🙌
                    </span>
                  </div>
                  <div className={styles.file}>
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                    >
                      Upload
                    </button>
                    <input className={styles.load} type="file" />
                  </div>
                </div>
              </div>
            </div> */}
            <div className={styles.col}>
              <div className={styles.list}>
                <div className={styles.item}>
                  {/* <div className={styles.category}>Account info</div> */}
                  <div className={styles.fieldset}>
                    {submitbutton ? (
                      <>
                      <TextInput
                      className={styles.field}
                      label="Received OTP Number From Register Email"
                      name="Name"
                      type="text"
                      placeholder="Enter Six Digit Otp Number"
                      value={otpnumber}
                      onChange={(e)=>{
                        setotpnumber(e.target.value)
                      }}
                      required
                    />
                      </>
                    ):(
                      <>
                      <TextInput
                      className={styles.field}
                      label="First Name"
                      name="firstName"
                      type="text"
                      placeholder="Enter your First Name"
                      onChange={(e)=>{
                        setfirstName(e.target.value)
                      }}
                      required
                    />
                     <TextInput
                      className={styles.field}
                      label="Last Name"
                      name="lastName"
                      type="text"
                      placeholder="Enter your Last Name"
                      onChange={(e)=>{
                        setlastName(e.target.value)
                      }}
                      required
                    />
                     <TextInput
                      className={styles.field}
                      label="Mobile"
                      name="mobile"
                      type=""
                      placeholder="Enter your Mobile Number"
                      onChange={(e)=>{
                        setmobile(e.target.value)
                      }}
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="Email"
                      name="email"
                      type="text"
                      placeholder="Enter your Email name"
                      onChange={(e)=>{
                        setemail(e.target.value)
                      }}
                      // onChange={onTextFieldChange}
                      required
                    />
                      </>
                    )}

{submitbutton ? (
<center><button
className={cn("button-stroke button-small", styles.button)}
onClick={()=>{
submitotpfunction()
// setsubmitbutton(true)
// window.location = "/"
}}
>
<Icon name="plus-circle" size="16" />
<span>Confirm</span>
</button>{' '}<button
className={cn("button-stroke button-small", styles.button)}
onClick={()=>{
// setsubmitbutton(false)
resendotpfunction()

}}
>
<Icon name="plus-circle" size="16" />
<span>Resend OTP</span>
</button></center>
):(
  <center><button
  className={cn("button-stroke button-small", styles.button)}
  
  onClick={()=>{
    // setsubmitbutton(true)
    singnupsubmitbutton()
  }}
>
  <Icon name="plus-circle" size="16" />
  <span>Submit</span>
</button>{' '}</center>
)}
<br/>
{/* <button
  className={cn("button-stroke button-small", styles.button)}
  style={{marginLeft:"2%"}}
  onClick={()=>{
    window.location = "/login"
    // setsubmitbutton(true)
    // singnupsubmitbutton()
  }}
>
  <Icon name="plus-circle" size="16" />
  <span>Login</span>
</button> */}

<div className={styles.note}>
              {responsemessage}
                
              </div>
              <div className={styles.btns} style={{backgroundColor:"",marginLeft:""}}>
             
             <button
                className={cn("button-stroke button-small")}
                onClick={()=>{
                  window.location="/login"
                }}
                style={{marginLeft:"15%"}}
                
               
              >
                <Icon name="plus-circle" size="16" />
                <span>Login</span>
              </button>
            
           <button   className={cn("button-stroke button-small", styles.button)} style={{marginLeft:"5%"}}  onClick={()=>{
              window.location= '/'
            }}>
              <Icon name="circle-close" size="16" />
              <span>Home</span>
            </button>
            
              
              
             
             
          </div>

{/* <center><button
  className={cn("button-stroke button-small", styles.button)}
  
  onClick={()=>{
    
    singnupsubmitbutton()
  }}
>
  <Icon name="plus-circle" size="16" />
  <span>Submit</span>
</button></center> */}

                  
                    {/* <TextInput
                      className={styles.field}
                      label="Password"
                      name="Url"
                      type="password"
                      placeholder="Enter your Password"
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="Confirm Password"
                      name="Url"
                      type="password"
                      placeholder="Enter your Confirm Password"
                      required
                    /> */}
                    {/* <TextArea
                      className={styles.field}
                      label="Bio"
                      name="Bio"
                      placeholder="About yourselt in a few words"
                      required="required"
                    /> */}
                  </div>
                 
                </div>
                {/* <div className={styles.note}>
              {responsemessage}
                
              </div> */}
            
            
              
            
              </div>
              <div >

{/* {submitbutton ? (
<center><button
className={cn("button-stroke button-small", styles.button)}
onClick={()=>{
submitotpfunction()

}}
>
<Icon name="plus-circle" size="16" />
<span>Confirm</span>
</button>{' '}<button
className={cn("button-stroke button-small", styles.button)}
onClick={()=>{

resendotpfunction()

}}
>
<Icon name="plus-circle" size="16" />
<span>Resend OTP</span>
</button></center>
):(
  <button
  className={cn("button-stroke button-small", styles.button)}
  
  onClick={()=>{
    
    singnupsubmitbutton()
  }}
>
  <Icon name="plus-circle" size="16" />
  <span>Submit</span>
</button>
)} */}


</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
