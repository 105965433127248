import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
// import Icon from "../Icon";
import axios from "axios";
import Web3 from "web3";
import createWeb3ContractMethodInstance from "../../components/CreateWeb3Instance/Web3instance";
import getinstancesParamter from "../../components/getInstancesParameter/Constant";

import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
// import PutSale from "../../screens/Item/Control/PutSale";
// import BlockChainLoader from "../Blockchain/blockloader";
import VideoThumbnail from "react-video-thumbnail";
import mp3image from "../../assets/images/mp3icon.png";
import moment from "moment";
import Loader from "../../components/Loader/index";
import { Fragment } from "react/cjs/react.development";

const constant = require("../../components/Constant/Constant");
// const contractABI = require("../../components/ABI/NFTSalePurchase.json");
// const marketcontractABI = require("../../components/ABI/NFTMarketPlace.json");
// const marketcontract = require("../../components/ContractAddress/Contract");
const contract = require("../../components/ContractAddress/Contract");
//  const createWeb3ContractMethodInstance = require('../../components/CreateWeb3Instance/Web3instance');
//  console.log(createWeb3ContractMethodInstance)

var apiBaseUrl = constant.getAPiUrl();

const Card = ({ className, item }) => {
  // const [visible, setVisible] = useState(false);
  // const [arrayofmintvalue, setarrayofmintvalue] = useState([]);
  const [modaltoggle, setmodaltoggle] = useState(false);
  const [accountaddress, setaccountaddress] = useState("");
  const [priceforsale, setpriceforsale] = useState("");
  const [blockloader, setblockloader] = useState(false);
  const [alertmessage, setalertmessage] = useState("");
  const [tranfertoken, settranfertoken] = useState("Sold");
  const [objectValue, setobjectValue] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // console.log(item);

  useEffect(() => {
    getcollectionData();
    let accountaddress = localStorage.getItem("CurrentAddress");
    if (accountaddress) {
      setaccountaddress(accountaddress);
    }

    // if(objectValue.tokensold){

    // }

    // load();
    // selectandgetregisterapi(0,2,0)
  }, []);

  const checkstatussoldapi = async () => {
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;
    axios({
      method: "get",

      url: `${apiBaseUrl}/api/user/getTokenIdDetails/${item}/${checknetworkId}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.message.tokenEventHistory.eventHistory.length) {
          let arrayofhistory =
            response.data.message.tokenEventHistory.eventHistory;
          console.log(response.data.message.tokenEventHistory.eventHistory);
          //  let filterarray =       arrayofhistory.filter((value)=>{
          //    return value.action
          //  })

          let checktransfer = arrayofhistory[arrayofhistory.length - 1];
          console.log(checktransfer);

          // checktransferfunction(checktransfer)
          if (checktransfer.action == "Transfer") {
            // data.transferstatus = true
            console.log("Transfer");
            settranfertoken("transfer");
          } else {
            // data.transferstatus = false
            console.log("not tranfer");
          }

          // letchecktransfer()
          // console.log(createobject)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getcollectionData = async () => {
    // console.log(item)
    const getWeb3InstanceParameter = await getinstancesParamter();
    // console.log(getWeb3InstanceParameter)

    let contractmethod = await getWeb3Instance(
      getWeb3InstanceParameter.nftRegistrationtype,
      getWeb3InstanceParameter.Blockchaintype,
      getWeb3InstanceParameter.networkType,
      getWeb3InstanceParameter.erctype
    );
    // console.log(contractmethod.methods, "line no 64444444444444444");
    // console.log(item);

    let marketcontractmethod = await getWeb3Instance(
      getWeb3InstanceParameter.martketRegistrationtype,
      getWeb3InstanceParameter.Blockchaintype,
      getWeb3InstanceParameter.networkType,
      getWeb3InstanceParameter.erctype
    );

    let checkstatus = await marketcontractmethod.methods
      .checkTokenStatus(item)
      .call();
    // console.log(checkstatus);

    let accountaddress = localStorage.getItem("CurrentAddress");
    // console.log(accountaddress);
    const tokenURI = await contractmethod.methods.tokenURI(item).call();
    // console.log(tokenURI);
    // let makeobject = await JSON.parse(decodeURIComponent(tokenURI))
    let makeobject = tokenURI;

    makeAPICall(makeobject, item, checkstatus, accountaddress);
  };

  const makeAPICall = async (
    pinatapath,
    value,
    checkstatus,
    accountaddress
  ) => {
    try {
      // setIsLoading(true)
      const response = await fetch(pinatapath, { mode: "cors" });
      // console.log(response);
      // console.log("line no 108");

      const data = await response.json();
      // console.log({ data });
      if (data) {
        console.log(data);
        const getWeb3InstanceParameter = await getinstancesParamter();
        let contractmethod = await getWeb3Instance(
          getWeb3InstanceParameter.nftRegistrationtype,
          getWeb3InstanceParameter.Blockchaintype,
          getWeb3InstanceParameter.networkType,
          getWeb3InstanceParameter.erctype
        );
        // console.log(contractmethod);
        let owneraddress = await contractmethod.methods.ownerOf(value).call();
        // console.log(
        //   owneraddress,
        //   "0000000000000000000000555555555555555555555555555"
        // );
        // console.log(accountaddress);
        if (owneraddress.toLowerCase() == accountaddress) {
          data.tokenId = value;
          data.status = checkstatus;
          data.tokensold = false;
          console.log("not sollllllllllllllllllllllllllld");
          console.log(checkstatus);
        } else {
          console.log("solllllllllllllllllllldddddddddddddddddd");
          console.log(checkstatus);

          data.tokenId = value;
          data.status = checkstatus;
          data.tokensold = true;
          checkstatussoldapi();
        }

        setobjectValue(data);
        // data.tokenId = value
        // data.status = checkstatus
      }
      console.log(data.name);
      setIsLoading(false)
    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  };

  const getaddressofMarketFunction = async (
    registrationType,
    blockchaintype,
    networktype,
    erctype
  ) => {
    let getresponse = await axios({
      method: "get",
      url: `${apiBaseUrl}/api/admin/getSmartContractAddress/${registrationType}/${blockchaintype}/${networktype}/${erctype}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    }).catch((error) => {
      console.log(error);
    });
    console.log(getresponse.data.data[0]);
    return getresponse.data.data[0];
  };
  const getWeb3Instance = async (
    registrationType,
    blockchaintype,
    networktype,
    erctype
  ) => {
    console.log("calll");
    let contract_Methods = await createWeb3ContractMethodInstance(
      registrationType,
      blockchaintype,
      networktype,
      erctype
    );

    return contract_Methods.contract_Methods;
  };

  const selectandgetregisterapi = async (
    blockchaintype,
    networktype,
    erctype
  ) => {
    // setpleasewaitmodal(true)
    axios({
      method: "get",
      url: `${apiBaseUrl}/api/admin/getSmartContractAddress/${blockchaintype}/${networktype}/${erctype}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        console.log(response);

        if (response.data.status == 200) {
          // setpleasewaitmodal(false)

          // setcollectionofcontract(response.data.data)

          const filtercontractmethod = response.data.data.find(
            (blockchain) => blockchain.registrationType == 1
          );

          const nftfiltercontract = response.data.data.find(
            (blockchain) => blockchain.registrationType == 0
          );
          // console.log(
          //   filtercontractmethod.address,
          //   "000000000000000000000000000000000000000000000000000000000000000000000000000000000"
          // );
          console.log(filtercontractmethod.abi);

          //fetchdatafromcommonfile
          // console.log(filtercontractmethod);
          getcontract_method(filtercontractmethod, nftfiltercontract);
          // console.log(filtercontractmethod, nftfiltercontract);

          load(filtercontractmethod, nftfiltercontract);
          // getaccountaddress();
          // console.log(response.data.data);

          //  setinvitedarray(response.data.message.invitedAddress)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getcontract_method = async (
    filtercontractmethod,
    nftfiltercontract
  ) => {
    // console.log(filtercontractmethod, nftfiltercontract);
    const contract_Methods = await createWeb3ContractMethodInstance(
      filtercontractmethod,
      nftfiltercontract
    );
    // console.log(
    //   contract_Methods,
    //   "888888888888888888888888888888888888888888888888888888888888888888"
    // );

    // console.log(contract_Methods.marketcontract.methods);
  };

  async function marketContract(filtercontractmethod) {
    return await new window.web3.eth.Contract(
      filtercontractmethod.abi.abi,
      filtercontractmethod.address
    );
  }

  async function nftContract(nftfiltercontract) {
    return await new window.web3.eth.Contract(
      nftfiltercontract.abi.abi,
      nftfiltercontract.address
    );
  }

  async function load(filtercontractmethod, nftfiltercontract) {
    await loadWeb3();
    window.marketcontract = await marketContract(filtercontractmethod);
    window.nftcontract = await nftContract(nftfiltercontract);
  }
  async function loadWeb3() {
    if (window.ethereum) {
      // console.log(window.ethereum)
      window.web3 = new Web3(window.ethereum);
      window.ethereum.enable();
    }
  }

  const submitfunction = async () => {
    try {
      let numberofpricevalue = +priceforsale;
      if (numberofpricevalue > 0) {
        // alert("number greate")

        if (priceforsale != "") {
          console.log("inside price");
          setmodaltoggle(!modaltoggle);
          setalertmessage("Please Wait ...");
          setblockloader(true);

          // console.log(typeof givenprice);
          const getWeb3InstanceParameter = await getinstancesParamter();

          let contractmethod = await getWeb3Instance(
            getWeb3InstanceParameter.martketRegistrationtype,
            getWeb3InstanceParameter.Blockchaintype,
            getWeb3InstanceParameter.networkType,
            getWeb3InstanceParameter.erctype
          );
          // console.log(contractmethod.methods, "line no 64444444444444444");
          //  alert("getre")
          var tokenPrice = Web3.utils.toWei(priceforsale, "ether");

          let response = await contractmethod.methods
            .PutNFTonSale(objectValue.tokenId, tokenPrice)
            .send({ from: accountaddress })
            .on("transactionHash", function (hash) {
              console.log(hash);
            });

          let nftContractmethod = await getWeb3Instance(
            getWeb3InstanceParameter.nftRegistrationtype,
            getWeb3InstanceParameter.Blockchaintype,
            getWeb3InstanceParameter.networkType,
            getWeb3InstanceParameter.erctype
          );

          let getaddressofMarket = await getaddressofMarketFunction(
            getWeb3InstanceParameter.martketRegistrationtype,
            getWeb3InstanceParameter.Blockchaintype,
            getWeb3InstanceParameter.networkType,
            getWeb3InstanceParameter.erctype
          );

          let getapprove = await nftContractmethod.methods
            .setApprovalForAll(getaddressofMarket.address, true)
            .send({ from: accountaddress });
          // console.log(getapprove);

          // console.log(response)

          if (response) {
            await window.ethereum.enable();
            const checknetworkId = window.ethereum.networkVersion;
            const headers = {
              Authorization: localStorage.token
                ? `Bearer ${localStorage.token}`
                : undefined,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            };
            const data = {
              walletAddress: accountaddress,
              tokenId: objectValue.tokenId,
              action: "On Sale",
              from: accountaddress,
              to: contract.getmarketcontractaddress(),

              date: moment().format("YYYY-MM-DD, h:mm:ss a"),
              transactionHash: response.transactionHash,
              networkId: checknetworkId,
            };

            const selldata = {
              tokenId: objectValue.tokenId,
            };

            let approveforsale = await axios
              .post(`${apiBaseUrl}/api/user/putOnSellTokenId`, data, {
                headers: headers,
              })
              .then((response) => {
                // console.log(response);

                setalertmessage("Token Approved For Sale");
                window.location = "/";
              })
              .catch((error) => {
                alert(error);
              });
          }
        }
        //   else if(priceforsale == "0.0" || priceforsale == "0.00" || priceforsale == "0.000" || priceforsale == "0.0000" || priceforsale == "0.00000" || priceforsale == "0.000000" || priceforsale == "0.0000000" || priceforsale == "0.00000000"){
        //     alert(`Enter Price Should Be Greater Than ${priceforsale}`)

        // }

        //     else if(priceforsale == ""){
        //       alert("Please Enter Price")

        // }
      } else {
        if (priceforsale == "") {
          alert("Please Enter Price");
        } else {
          alert(`Enter Price Should Be Greater Than ${priceforsale}`);
        }
      }
    } catch (error) {
      console.log("error", error);
      // setmodaltoggle(!modaltoggle)
      //  window.location.reload()
      alert(error);
      // setblockloader(false)
    }

    // console.log("msgshow");
  };

  const subscribeLogEvent = async (contract, eventName, price) => {
    const subscribedEvents = {};
    console.log("subscribe event called");

    console.log(window.web3.utils._);

    const eventJsonInterface = await window.web3.utils._.find(
      contract._jsonInterface,
      (o) => o.name === eventName && o.type === "event"
    );
    // console.log(eventJsonInterface);

    const subscription = window.web3.eth.subscribe(
      "logs",
      {
        address: contract.options.address,
        topics: [eventJsonInterface.signature],
      },
      (error, result) => {
        // console.log(error);
        // console.log(result);
        if (!error) {
          const eventObj = window.web3.eth.abi.decodeLog(
            eventJsonInterface.inputs,
            result.data,
            result.topics.slice(1)
          );
          // console.log(`news ${eventName}!`, eventObj);
        } else {
          console.log(error);
        }
      }
    );
    subscribedEvents[eventName] = subscription;
    return subscribedEvents;
  };

  // console.log(arrayofmintvalue)

  // console.log("kdggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg")
  //  console.log(item.tokenId)
  //  console.log("")

  // console.log(item.transferstatus)

  return (
    <>
      <div className={cn(styles.card, className)}>
      {/* {!objectValue && <Loader/>} */}
        {isLoading ? <Loader/> : (
          <Fragment>
            <Link
              className={styles.link}
              to={"item"}
              onClick={() => {
                //  localStorage.setItem("Itemvalue",item.IPFSHash)
                localStorage.setItem("Itemvalue", objectValue.image);
                localStorage.setItem("ItemDetails", objectValue.name);
                localStorage.setItem("ItemDesc", objectValue.description);
                localStorage.setItem("PricetokenId", objectValue.tokenId);
                //  localStorage.setItem("ItemtokenId",item.tokenId)
                //  localStorage.setItem("ItemfileType",item.fileType)
                localStorage.setItem("ItemfileType", objectValue.assettype);
              }}
            >
              <div
                className={styles.preview}
                style={{ backgroundColor: "black" }}
              >
                {objectValue.assettype != "video/mp4" &&
                  objectValue.assettype != "audio/mpeg" && (
                    <>
                      <center>
                        {!objectValue.image ? (
                          <Loader />
                        ) : (
                          <img
                            srcSet={`${objectValue.image} 2x`}
                            src={objectValue.image}
                            alt="Card"
                            style={{
                              maxWidth: "180px",
                              maxHeight: "180px",
                              width: "170px",
                              height: "170px",
                            }}
                          />
                        )}
                      </center>
                    </>
                  )}
                {objectValue.assettype == "audio/mpeg" && (
                  <>
                    <center>
                      <img
                        srcSet={`${mp3image} 2x`}
                        src={mp3image}
                        alt="Card"
                        style={{
                          maxWidth: "180px",
                          maxHeight: "180px",
                          width: "170px",
                          height: "170px",
                        }}
                      />
                    </center>
                  </>
                )}

                <div className={styles.control}>
                  <div
                    className={cn(
                      { "status-green": "green" === "green" },
                      "green"
                    )}
                    style={{ marginTop: "5%" }}
                  >
                    {/* {item.categoryText} */}
                    Details
                  </div>
                </div>
                {objectValue.assettype == "video/mp4" && (
                  <div
                    style={{
                      maxWidth: "180px",
                      maxHeight: "180px",
                      width: "170px",
                      height: "170px",
                    }}
                  >
                    <center>
                      <VideoThumbnail
                        // videoUrl={"https://gateway.pinata.cloud/ipfs/Qmem4exur6JCXTkJuc4XSYttEzFJg1U6RejgKrot7DNuCB"}
                        // videoUrl={item.IPFSHash}
                        videoUrl={objectValue.image}
                        thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                      />
                    </center>
                  </div>
                )}
              </div>
            </Link>

            <div className={styles.body}>
              <div className={styles.line} style={{ cursor: "pointer" }}>
                {objectValue.name && (
                  <div className={styles.title} title={objectValue.name}>
                    {objectValue.name.slice(0, 15)}
                  </div>
                )}

                {objectValue.tokensold ? (
                  <>
                    <div className={styles.price}>{tranfertoken}</div>
                  </>
                ) : (
                  <>
                    {objectValue.status ? (
                      <div className={styles.price}>already on sale</div>
                    ) : (
                      <div
                        className={styles.price}
                        onClick={() => {
                          setmodaltoggle(!modaltoggle);
                        }}
                      >
                        put on Sale
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={styles.line}>
                <div className={styles.users}></div>
                <div className={styles.counter}>{objectValue.counter}</div>
              </div>
            </div>

            <div className={styles.foot}>
              <div
                className={styles.bid}
                dangerouslySetInnerHTML={{ __html: item.bid }}
              />
            </div>
          </Fragment>
        )}
      </div>

      {blockloader ? (
        <>
          <Modal visible={blockloader}>
            <div>
              <center>
                <h4>{alertmessage}</h4>
              </center>
              <br />
              {/* {profileline &&
   <center><h4><span style={{marginLeft:"10%",cursor:"pointer"}}  onClick={()=>{
   window.location ="/profile"
  }}>Submit</span></h4></center>
} */}

              <div></div>
            </div>
          </Modal>
        </>
      ) : (
        <Modal
          visible={modaltoggle}
          onClick={() => {
            setmodaltoggle(!modaltoggle);
          }}
        >
          {/* <PutSale /> */}
          <h3 style={{ fontSize: "25px" }}>Put on Sale</h3>

          <div style={{ marginTop: "5%" }}>
            {objectValue && (
              <TextInput
                className={styles.field}
                label="Item name"
                name="name"
                type="text"
                value={objectValue.name}
                placeholder=""
                required
              />
            )}

            <br />
            <TextInput
              className={styles.field}
              label="Price (ETH)"
              name="price"
              type="number"
              // pattern="[0-9]*"
              // inputmode="numeric"

              onChange={(e) => {
                setpriceforsale(e.target.value.replace("-", ""));
              }}
              value={priceforsale}
              placeholder=""
              required
            />
          </div>
          <br />
          <div style={{ marginTop: "10%" }}>
            <button
              className={cn("button", styles.button)}
              style={{ marginLeft: "-10%" }}
              onClick={() => {
                submitfunction();
              }}
            >
              Submit
            </button>
            <button
              className={cn("button-stroke", styles.button)}
              style={{ marginLeft: "15%" }}
              onClick={() => {
                setmodaltoggle(!modaltoggle);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Card;
