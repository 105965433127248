import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import SignuploginPage from "./components/SignuploginPage";
import Home from "./screens/Home";
import UploadVariants from "./screens/UploadVariants";
import UploadDetails from "./screens/UploadDetails";
import ConnectWallet from "./screens/ConnectWallet";
import Faq from "./screens/Faq";
import Errorpage from "./screens/ErrorPage";
import MyRoyalities from "./screens/MyRoyalities";
import Samplepage from "./screens/Samplepage/Samplepage";
import Activity from "./screens/Activity";
import Search01 from "./screens/Search01";
import Search02 from "./screens/Search02";
import Profile from "./screens/Profile";
import ProfileEdit from "./screens/ProfileEdit";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import Item from "./screens/Item";
import Item1 from "./screens/Item1";
import MarketItem from "./screens/MarketItem";
import MarketItemDani from "./screens/MarketItemDaniPage/index";

import PageList from "./screens/PageList";
import Forgotpassword from "./screens/Forgotpassword";
import DaniHome from "./screens/DaniHome/DaniHome";
import useDarkMode from "use-dark-mode";
import Routes from "./Routes";

const contract = require("./components/ContractAddress/Contract");

function App() {
  const darkMode = useDarkMode(false);

  useEffect(() => {
    if (darkMode) darkMode.disable();
    // console.log("use method")
  });

  return (
    <Router>
      <Switch>
        {/* <Route exact path="/">
          <Redirect to="/marketplace" />
        </Route> */}
        {/* <Route
          exact
          path="/dani"
          render={() => (
            <Page>
              <MarketItemDani />
            </Page>
          )}
        /> */}
        {/* <Route
          exact
          path="/marketplace"
          render={() => (
            // <Page>
              <Home />
            // </Page> 
          )}
        /> */}
        {/* <Route
          exact
          path="/"
          render={() => (
            // <Page>
              <DaniHome />
            // </Page>
          )}
        /> */}
        <Routes />
        <Route
          exact
          path="/upload-variants"
          render={() => (
            <Page>
              <UploadVariants />
            </Page>
          )}
        />
        <Route
          exact
          path="/upload-details"
          render={() => (
            <Page>
              <UploadDetails />
            </Page>
          )}
        />
        {/* <Route
          exact
          path="/connect-wallet"
          render={() => (
            <Page>
              <ConnectWallet />
            </Page>
          )}
        /> */}
        <Route
          exact
          path="/sample"
          render={() => (
            // <Page>
            <Faq />
            // </Page>
          )}
        />
        <Route
          exact
          path="/royalities"
          render={() => (
            <Page>
              <MyRoyalities />
            </Page>
          )}
        />
        <Route exact path="/errorpage" render={() => <Errorpage />} />
        <Route
          exact
          path="/samplepage"
          render={() => (
            // <Errorpage />
            <Page>
              <Samplepage />
            </Page>
          )}
        />
        <Route
          exact
          path="/activity"
          render={() => (
            <Page>
              <Activity />
            </Page>
          )}
        />
        <Route
          exact
          path="/search01"
          render={() => (
            <Page>
              <Search01 />
            </Page>
          )}
        />
        <Route
          exact
          path="/search02"
          render={() => (
            <Page>
              <Search02 />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile"
          render={() => (
            <Page>
              <Profile />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile-edit"
          render={() => (
            <Page>
              <ProfileEdit />
            </Page>
          )}
        />
        <Route
          exact
          path="/login"
          render={() => (
            <SignuploginPage>
              <Login />
            </SignuploginPage>
          )}
        />
        <Route
          exact
          path="/signup"
          render={() => (
            <SignuploginPage>
              <Signup />
            </SignuploginPage>
          )}
        />
        <Route
          exact
          path="/forgotpassword"
          render={() => (
            <Page>
              <Forgotpassword />
            </Page>
          )}
        />
        <Route
          exact
          path="/item"
          render={() => (
            <Page>
              <Item />
            </Page>
          )}
        />
        <Route
          exact
          path="/saleitem"
          render={() => (
            <Page>
              <Item1 />
            </Page>
          )}
        />
        {/* <Route
          // path={`/${contract.getmarketcontractaddress()}`}
          path="/assest"
          render={() => (
            <Page>
              <MarketItem />
            </Page>
          )}
        /> */}
        <Route
          exact
          path="/pagelist"
          render={() => (
            <Page>
              <PageList />
            </Page>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
