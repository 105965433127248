import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";

import VideoThumbnail from "react-video-thumbnail";
// import Web3 from "web3";
import mp3image from "../../assets/images/mp3icon.png";
import Loader from "../Loader";
import { Fragment } from "react/cjs/react.development";
const contract = require("../../components/ContractAddress/Contract");

// const constant = require("../../components/Constant/Constant");
// var apiBaseUrl = constant.getAPiUrl();
const Card = ({ className, item, networkIdParameter, pricevalue }) => {
  return (
    <>
      {/* {item && ( */}
      <div className={cn(styles.card, className)}>
        {!item ? (
          <Loader />
        ) : (
          <Fragment>
            <Link
              className={styles.link}
              to='/assest'
              // to={`${contract.getmarketcontractaddress()}/${item.tokenId}`}
              onClick={() => {
                sessionStorage.setItem(
                  "networkIdParameter",
                  networkIdParameter
                );
                sessionStorage.setItem("checkStatus", item.checkStatus);
                sessionStorage.setItem("checkStatusPrice", item.price);
                localStorage.setItem("Itemvalue", item.image);
                localStorage.setItem("ItemDetails", item.name);
                localStorage.setItem("ItemDesc", item.description);
                localStorage.setItem("PricetokenId", item.tokenId);
                localStorage.setItem("_id", item._id);
                localStorage.setItem("CurrentOwner", item.currentOwner);
                localStorage.setItem("ItemfileType", item.assettype);
              }}
            >
              <div
                className={styles.preview}
                style={{ backgroundColor: "black" }}
              >
                {item && (
                  <center
                    style={{
                      width: "250px",
                      height: "180px",
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                      alignItems: "center",
                    }}
                  >
                    {!item.image ? (
                      <Loader />
                    ) : (
                      <img
                        srcSet={`${item.image} 2x`}
                        src={item.image}
                        alt="Card"
                        style={{
                          maxWidth: "250px",
                          maxHeight: "200px",
                          width: "200px",
                          height: "180px",
                        }}
                      />
                    )}
                  </center>
                )}

                {item.assettype == "audio/mpeg" && (
                  <center>
                    <img
                      srcSet={`${mp3image} 2x`}
                      src={mp3image}
                      alt="Card"
                      style={{
                        maxWidth: "180px",
                        maxHeight: "180px",
                        width: "170px",
                        height: "170px",
                      }}
                    />
                  </center>
                )}
                <div className={styles.control}>
                  <div
                    className={cn(
                      { "status-green": "green" === "green" },
                      styles.category
                    )}
                  >
                    {item.viewCount} views
                  </div>
                </div>

                {item.assettype == "video/mp4" && (
                  <div
                    style={{
                      maxWidth: "190px",
                      maxHeight: "190px",
                      width: "190px",
                      height: "190px",
                    }}
                  >
                    <center>
                      <VideoThumbnail videoUrl={item.image} />
                    </center>
                  </div>
                )}
              </div>
            </Link>

            <div className={styles.body}>
              <div className={styles.line}>
                {item.name && (
                  <div
                    className={styles.title}
                    style={{ cursor: "pointer" }}
                    title={item.name}
                  >
                    {item.name.slice(0, 18)}
                  </div>
                )}

                {item.putonsalestatus && (
                  <div className={styles.price}>{item.price}</div>
                )}
              </div>
              <div className={styles.line}>
                <div className={styles.users}></div>
                <div className={styles.counter}>{item.counter}</div>
              </div>
            </div>
            <div className={styles.foot}>
              <div
                className={styles.bid}
                dangerouslySetInnerHTML={{ __html: item.bid }}
              />
            </div>
          </Fragment>
        )}
      </div>
    </>
  );
};

export default Card;
