import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Icon from "../../../components/Icon";
import moment from "moment"


const Dropdown = ({ className, value, setValue, options,sortingarray,oncheckfunction,filterarrayofvalue,ondefaultcheckfunction,networkIdParameter }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (value) => {
     setValue(value);
     setVisible(false); 


     //check status wheather is putonsale or not and network id
    let checkputonsale = sortingarray.filter((value)=>{
      return value.checkStatus == true && networkIdParameter == value.networkId 
    })

    

    let mostview = sortingarray
    let filterarray = checkputonsale
    if(value == "Price Low to High"){

//filter asset from price low to high

      filterarray.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
 
      oncheckfunction(filterarray)
    }
    else if(value == "Price High to Low"){
      //filter asset from price high to low
      filterarray.sort((a,b) => (a.price < b.price) ? 1 : ((b.price < a.price) ? -1 : 0));

      oncheckfunction(filterarray)
    }
    else if(value == "Oldest First"){
//sorting asset from last to current
    let oldfirstdata =  sortingarray.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        if(a.data){
          return new Date(a.createdAt) - new Date(b.createdAt)
        }
     
      });
    

      oncheckfunction(oldfirstdata)
    }
    else if(value == "Recently Listed"){     

      let prevdate = moment().subtract(2, "days").format("YYYY-MM-DD");
      let getrecentvalue = sortingarray.filter((value)=>{
      
        if(value.data){
          return prevdate <= moment(value.createdAt).format("YYYY-MM-DD");
        }
     
      })
  
      oncheckfunction(getrecentvalue)     

    }
    else if(value == "Recently Sold"){
//filter recently sold asset
      let prevdate = moment().subtract(2, "days").format("YYYY-MM-DD");
      let getrecentvalue = sortingarray.filter((value)=>{
        if(value.data){
          return prevdate <= moment(value.updatedAt).format("YYYY-MM-DD") && !value.isPutOnSell && value.isSold;
        }
        
      })
      
      oncheckfunction(getrecentvalue)
      

    }

    else if(value == "Most Viewed"){
     //filter most viewed asset
      if(mostview.data){
        mostview.data.sort((a,b) => (a.viewCount < b.viewCount) ? 1 : ((b.viewCount < a.viewCount) ? -1 : 0));
      }
     

      oncheckfunction(mostview)

    }

    
    else if(value == "Default")
    {
    let defaultgetvalue =  sortingarray.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.data.createdAt) - new Date(a.data.createdAt)
    });
    ondefaultcheckfunction(defaultgetvalue)
   
   
  }
        
   
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.dropdown, className, { [styles.active]: visible })}
      >
        <div className={styles.head} onClick={() => setVisible(!visible)}>
          <div className={styles.selection}>{value}</div>
          <div className={styles.arrow}>
            <Icon name="arrow-bottom" size="10" />
          </div>
        </div>
        <div className={styles.body}>
          {options.map((x, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: x === value,
              })}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              {x}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
