import Web3 from "web3";



//For Production
// var apiBaseUrl = "http://18.119.70.208:5000"
var apiBaseUrl = "https://backend.daniclosandorra.thenftbrewery.com"
// var apiBaseUrl = "https://backend.thenftbrewery.com";

//For Development
//var apiBaseUrl = "http://3.83.146.11:6100";


//fornft register smart contract

//var apiBaseUrl = "http://52.14.165.133:5000"




export function getAPiUrl() {
  return apiBaseUrl;
}

export  function getWeb3InstanceParameter(){   
   
  


    const martketRegistrationtype = 1
    const nftRegistrationtype = 0

    let Blockchaintype = 0
    let erctype = 0

    let collecionofTypeobject = {
      martketRegistrationtype,
      nftRegistrationtype,
      Blockchaintype, 
      // networkType,     
      erctype
    }
    return collecionofTypeobject
  
  

}

