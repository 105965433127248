import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./ProfileEdit.module.sass";
import Control from "../../components/Control";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import axios from "axios"
const constant = require("../../components/Constant/Constant")
var apiBaseUrl = constant.getAPiUrl();

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Login",
  },
];

const Login = () => {
  const [submitbutton,setsubmitbutton] = useState(false)
  const [email,setemail] = useState("")
  const [errormodaltoggle,seterrormodaltoggle] = useState(false)
  const [otpnumber,setotpnumber]= useState("")
  const [set,setset]= useState("")
  const [responsemessage,setresponsemessage]= useState("")

  useEffect(()=>{
//  localStorage.setItem("darkMode",true)
  },[])

  const loginsubmitfunction = ()=>{   
    //setsubmitbutton(true) 
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
    }
    const data = {
      email:email
    }
    axios.post(`${apiBaseUrl}/api/user/loginOTP`, data, {
      headers: headers
    })
    .then((response) => {
      console.log(response)
      setsubmitbutton(true) 
     
      if(response.data.status == 200){
        
        setresponsemessage(response.data.message)
        // window.location ="/"
      }
      else if(response.data.status == 406){
        // console.log("inside of error")
        setresponsemessage(response.data.error)
       
  
      }
      // else if(response.data.status == 404){
      //   console.log("inside of error")
       

      // }
      // window.location.reload()

    })
    .catch((error) => {
      console.log(error)
      //  seterrormodaltoggle(true)
      setresponsemessage("Email Not found, Please Signup Page Before Login")
     
    })

  }

  const resendotpfunction = ()=>{
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
    }
    const data = {
     
      email:email
    }
   
    axios.post(`${apiBaseUrl}/api/user/loginOTP`, data, {
      headers: headers
    })
    .then((response) => {
      console.log(response)
     
     
      if(response.data.status == 200){
        console.log(response.data)
         setresponsemessage(`New ${response.data.message}`)
      
        // window.location = "/"
       
        // window.location ="/"
      }
      else if(response.data.status == 406){
        // console.log("inside of error")
        setresponsemessage(response.data.error)
       
  
      }
      // else if(response.data.status == 404){
      //   console.log("inside of error")
       
  
      // }
      // window.location.reload()
  
    })

    .catch((error) => {
      console.log(error)
      //  seterrormodaltoggle(true)
      //  setresponsemessage(error)
     
    })

  }

  const submitotpfunction = ()=>{
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
    }
    const data = {
      otp:otpnumber,
      email:email
    }
   
    axios.post(`${apiBaseUrl}/api/user/Login`, data, {
      headers: headers
    })
    .then((response) => {
      console.log(response)
     
     
      if(response.data.status == 200){
        localStorage.setItem("isEmail",true)
        localStorage.setItem("token",response.data.token)
      
         window.location = "/"
       
        // window.location ="/"
      }
      // else if(response.data.status == 404){
      //   console.log("inside of error")
       
  
      // }
      // window.location.reload()
  
    })

    .catch((error) => {
      console.log(error)
      setresponsemessage("Invalid OTP, Check Your Email Again")

      // setresponsemessage(error)
      // seterrormodaltoggle(true)
     
    })
  
  }
  return (
    <div className={styles.page}>
         <Modal
    
    visible={errormodaltoggle}
    // onClick={()=>{
    //   setmodaltoggle(!modaltoggle)
    // }}

 >
   {/* <PutSale /> */}
   <div  onClick={()=>{
     
   }}>
   {/* <h3 style={{fontSize:"15px"}}>Email Address Not Found ! </h3> */}
   <center><h4>Email Not Found. Please SignUp Before Login</h4></center>
   <br/>
   <center><span style={{cursor:"pointer"}} onClick={()=>{
     window.location = "/signup"
   }}>SignUp</span>{' '}<span style={{marginLeft:"5%",cursor:"pointer"}} onClick={()=>{
     window.location = "/"
   }}>Home</span></center>
   </div>
   </Modal>
      {/* <Control className={styles.control} item={breadcrumbs} /> */}
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.top}>
            <h1 className={cn("h2", styles.title)}>Login</h1>
            {/* <div className={styles.info}>
              You can set preferred display name, create{" "}
              <strong>your profile URL</strong> and manage other personal
              settings.
            </div> */}
          </div>
          <div className={styles.row}>
            {/* <div className={styles.col}>
              <div className={styles.user}>
                <div className={styles.avatar}>
                  <img src="/images/content/avatar-1.jpg" alt="Avatar" />
                </div>
                <div className={styles.details}>
                  <div className={styles.stage}>Profile photo</div>
                  <div className={styles.text}>
                    We recommend an image of at least 400x400. Gifs work too{" "}
                    <span role="img" aria-label="hooray">
                      🙌
                    </span>
                  </div>
                  <div className={styles.file}>
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                    >
                      Upload
                    </button>
                    <input className={styles.load} type="file" />
                  </div>
                </div>
              </div>
            </div> */}
            <div className={styles.col}>
              <div className={styles.list}>
                <div className={styles.item}>
                  {/* <div className={styles.category}>Account info</div> */}
                  <div className={styles.fieldset}>

                  {submitbutton ? (
                      <>
                      <TextInput
                      className={styles.field}
                      label="Enter One Time Password (OTP) emailed to you"
                      name="Name"
                      type="text"
                      placeholder="Enter Six Digit Otp Number"
                      value={otpnumber}
                      onChange={(e)=>{
                        setotpnumber(e.target.value)
                      }}
                      required
                    />
                      </>
                  ):
                  (
                    <>
<TextInput
                      className={styles.field}
                      label="Email"
                      name="Name"
                      type="text"
                      placeholder="Enter your Email Name"
                      value={email}
                      onChange={(e)=>{
setemail(e.target.value)
                      }}
                      required
                    />
                    </>
                  )}

                  
                  <div style={{marginTop:""}}>

{submitbutton ? (
<>
  <button
  className={cn("button-stroke button-small", styles.button)}
  onClick={()=>{
    submitotpfunction()
   
  }}
>
  <Icon name="plus-circle" size="16" />
  <span>Confirm OTP</span>
</button>{' '}

<button
  className={cn("button-stroke button-small", styles.button)}
  style={{marginTop:"2%"}}
  onClick={()=>{
   
    resendotpfunction()
  }}
>
  <Icon name="plus-circle" size="16" />
  <span>Resend OTP to Registered Email </span>
</button>
</>
                ): (
                  <>
               
                  <center>

                    <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={()=>{
                    
                      loginsubmitfunction()
                      
                    }}
                  >
                    <Icon name="plus-circle" size="16" />
                    <span>Submit</span>
                  </button></center>
                  </>
                )
}


<div className={styles.note} style={{marginTop:"5%"}}>
{responsemessage}
</div>
<div className={styles.btns} style={{backgroundColor:"",marginLeft:""}}>
             
             <button
                className={cn("button-stroke button-small")}
                onClick={()=>{
                  window.location="/signup"
                }}
                style={{marginLeft:"15%"}}
                
               
              >
                <Icon name="plus-circle" size="16" />
                <span>Signup</span>
              </button>
            
           <button   className={cn("button-stroke button-small", styles.button)} style={{marginLeft:"5%"}}  onClick={()=>{
              window.location= '/'
            }}>
              <Icon name="circle-close" size="16" />
              <span>Home</span>
            </button>
            
              
              
             
             
          </div>


                 
              </div>
              
              </div>
              
              
                </div>
                {/* <div className={styles.note}>
                
                {responsemessage}
            
              
                    
                    
                  </div> */}
                {/* <div className={styles.item}>
                  <div className={styles.category}>Social</div>
                  <div className={styles.fieldset}>
                    <TextInput
                      className={styles.field}
                      label="portfolio or website"
                      name="Portfolio"
                      type="text"
                      placeholder="Enter URL"
                      required
                    />
                    <div className={styles.box}>
                      <TextInput
                        className={styles.field}
                        label="twitter"
                        name="Twitter"
                        type="text"
                        placeholder="@twitter username"
                        required
                      />
                      <button
                        className={cn(
                          "button-stroke button-small",
                          styles.button
                        )}
                      >
                        Verify account
                      </button>
                    </div>
                  </div>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    <Icon name="plus-circle" size="16" />
                    <span>Add more social account</span>
                  </button>
                </div> */}

              {/* <div style={{marginTop:""}}>

{submitbutton ? (

  <center><button
  className={cn("button-stroke button-small", styles.button)}
  onClick={()=>{
    submitotpfunction()
   
  }}
>
  <Icon name="plus-circle" size="16" />
  <span>Confirm</span>
</button>{' '}<button
  className={cn("button-stroke button-small", styles.button)}
  onClick={()=>{
   
    resendotpfunction()
  }}
>
  <Icon name="plus-circle" size="16" />
  <span>Resend OTP</span>
</button></center>
                ): (
                  <>
               
                  <center>

                    <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={()=>{
                    
                      loginsubmitfunction()
                      
                    }}
                  >
                    <Icon name="plus-circle" size="16" />
                    <span>Submit</span>
                  </button></center>
                  </>
                )
}
                 
              </div> */}
              </div>
              {/* <div className={styles.note}>
                
                {responsemessage}
            
              </div> */}
              
             
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
