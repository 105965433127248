import React, { useState,useEffect } from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import moment from "moment"
import Icon from "../../../components/Icon";

const Users = ({ className, items,tokendetails,contractaddress,tokenhistory }) => {
  console.log(items)
  console.log(tokendetails)
  const [indexnumber,setindexnumber] = useState(3)
  const [blockChainname,setblockChainname]=useState("")
  const [blockChainUrl,setblockChainUrl]=useState("")

  useEffect(() => {
    setblockChainName()

  },[])

  const setblockChainName = async()=>{
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion
    console.log(checknetworkId)
    
    let blockChainName
    let blockChainUrl
    if(checknetworkId == 3){
      blockChainName = "Ropsten"
      blockChainUrl = "https://ropsten.etherscan.io"
    }
    else if(checknetworkId == 80001){
      blockChainName = "Polygon Mumbai"
      blockChainUrl = "https://mumbai.polygonscan.com"
    }
    console.log(blockChainName)
setblockChainUrl(blockChainUrl)
    setblockChainname(blockChainName)

    
  }

  //indexnumber

  return (
    <div className={cn(styles.users, className)}>
      <div className={styles.list}>
    
        <div className={styles.item} key={0}>
        
        {items == 1 &&
        <div className={styles.details} style={{cursor:"pointer"}} onClick={()=>{
          window.open(`${blockChainUrl}/address/${tokendetails.currentOwner}`)
        }}>
          <div className={styles.position}>Owner</div>
          {tokendetails &&
          <div className={styles.name}>{tokendetails.currentOwner}
          
          </div>
}

        </div>
        
        
        }
        
      
          
            <div className={styles.details}>
        
              
            <div className={styles.code}  >
         
              </div>    
              
              
            </div>
          </div>

          {items == 0 &&
          <>
          
          <div className={styles.item} >
            
            
            <div className={styles.details} style={{cursor:"pointer"}} onClick={()=>{
                window.open(`${blockChainUrl}/address/${contractaddress}`)
              }}>
              <div className={styles.position}>Contract Address</div>
              <div className={styles.name}>{contractaddress}</div>
              
            </div>
            <div className={styles.code}  > 
            <div title="Copy Address" className="tooltip"  >
            <button className={styles.copy}   onClick={() =>
               navigator.clipboard.writeText(contractaddress)
               }>
                <Icon name="copy" size="16" />
              </button>
              </div>
              </div>

            
          </div>
          
          <div className={styles.item} >
            
            <div className={styles.details}>
              <div className={styles.position}>Token Id</div>
              <div className={styles.name}>{localStorage.PricetokenId}</div>
              
            </div>
            <div className={styles.code}  > 
                <div title="Copy Token Id" className="tooltip"  >
                 <button className={styles.copy}   onClick={() => navigator.clipboard.writeText(localStorage.PricetokenId)}>
                <Icon name="copy" size="16" />
              </button>
              </div>
              </div>       

           
          </div>
          
          <div className={styles.item} >
            
            <div className={styles.details}>
              <div className={styles.position}>Token Standard</div>
              <div className={styles.name}>ERC-721</div>
              
            </div>

           
          </div>
          <div className={styles.item} >
            
            <div className={styles.details}>
              <div className={styles.position}>Blockchain</div>
              <div className={styles.name}>{blockChainname}</div>
              
            </div>

           
          </div>
          </>
          }
          {items == 2 &&
        <>
        

         
          <div className={styles.item} >
             <div className={styles.details}>
              <div className={styles.position}>Event</div>
             
              
              
            </div>
            <div className={styles.details}>
              <div className={styles.position}>From</div>
              
              
              
            </div>
            <div className={styles.details}>
              <div className={styles.position}>To</div>
            
              
              
            </div>
            <div className={styles.details}>
              <div className={styles.position}>Hash</div>
           
              
              
            </div>
            <div className={styles.details}>
              <div className={styles.position}>Date</div>
           
              
              
            </div>
            </div>
            {tokenhistory.length &&
<>
{tokenhistory.map((x, index) => (
            <>

            {index <= indexnumber &&
            <>
             <div className={styles.item} >
          
            
          <div className={styles.details}>
         
         <div className={styles.name}>{x.action}</div>
            
            
          </div>
          <div className={styles.details}>

            {x.from == "Null" ? (
<div className={styles.name} style={{marginLeft:""}} >Null</div>
            ): (
              <div className="tooltip" title={x.from} style={{marginLeft:""}} style={{cursor:"pointer"}} onClick={()=>{
                window.open(`${blockChainUrl}/address/${x.from}`)
              }}>{x.from.slice(0,3)}...{x.from.slice(x.from.length - 3)}</div>
            )}
        
           
            
            
          </div>
          <div className={styles.details}>
          {x.to == "Null" ? (
<div className={styles.name} style={{marginLeft:""}} >Null</div>
            ): (
       
            <div className="tooltip" title={x.to} style={{marginLeft:""}} style={{cursor:"pointer"}} onClick={()=>{
              window.open(`${blockChainUrl}/address/${x.to}`)
            }}>{x.to.slice(0,3)}...{x.to.slice(x.to.length - 3)}</div>
            )}
            
            
          </div>
          <div className={styles.details}>


<div className="tooltip" title={x.transactionHash} style={{marginLeft:""}} style={{cursor:"pointer"}} onClick={()=>{
              window.open(`${blockChainUrl}/tx/${x.transactionHash}`)
            }}>{x.transactionHash.slice(0,3)}...{x.transactionHash.slice(x.transactionHash.length - 3)}</div>





</div>
          <div className={styles.details}>
           
           
            <div className={styles.name}> {moment(`${x.date}`, 'YYYY-MM-DD h:mm:ss a').fromNow()}</div>
            
            
          </div>
         
         
        </div>

            </>
            }
           
               
         

          </>
        ))} 
        <br/>

         {tokenhistory.length > 3 &&
         <>
          {indexnumber == 3 &&
        <span style={{cursor:"pointer"}} onClick={()=>{
          setindexnumber(20)
        }}><center>See More</center></span>
        }
        {indexnumber > 4 &&
        <span style={{cursor:"pointer"}} onClick={()=>{
          setindexnumber(3)
        }}><center>See Less</center></span>
        }
         </>
         
         }   

       

        
        </>
}

          
                   
      </>
        
      }
      
      
      </div>
    </div>
  );
};

export default Users;
