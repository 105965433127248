import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCollectionAPI,
  getPremintAssetByCollectionIdAPI,
  getPremintAssetByIdAPI,
  getAllPremintAssetAPI,
  getPremintAssetCollectionNameAndBioByCollectionIdAPI,
  updateTransactionHashAPI,
  getUpdateTransactionHashAPI,
  getSmartContractAddressAPI,
  updateOwnerAddressAPI,
} from "../../service/collectionApi";
import getinstancesParamter from "../../components/getInstancesParameter/Constant";
import createWeb3ContractMethodInstance from "../../components/CreateWeb3Instance/Web3instance";

// calling all collections
export const getCollection = createAsyncThunk(
  "collection/getCollection",
  async (data, thunkAPI) => {
    try {
      return await getCollectionAPI();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// calling all premintAsset
export const getAllPremintAsset = createAsyncThunk(
  "getAllPremintAssetAPI/Assest",
  async (_, thunkAPI) => {
    try {
      return await getAllPremintAssetAPI();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// calling getPremintAssetCollectionNameAndBioByCollectionId
export const getPremintAssetCollectionNameAndBioByCollectionId =
  createAsyncThunk(
    "collectionNameAndBio/premintAssest",
    async (id, thunkAPI) => {
      try {
        return await getPremintAssetCollectionNameAndBioByCollectionIdAPI(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  // calling premintAsset by collection ID
export const getPremintAssetByCollectionID = createAsyncThunk(
  "collection/premintAssest",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(getPremintAssetCollectionNameAndBioByCollectionId(id));
      return await getPremintAssetByCollectionIdAPI(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

// calling and getting premintasset detials by asset ID 
export const getPremintAssestById = createAsyncThunk(
  "premintAssest/premintAssestById",
  async (id, thunkAPI) => {
    try {
      const res = await getPremintAssetByIdAPI(id);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// calling and send transactionHash to database
export const updateTransactionHash = createAsyncThunk(
  "updateTrxHash/premintupdateTransactionHash",
  async (data, thunkAPI) => {
    try {
      return await updateTransactionHashAPI(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// calling and getting transactionDetails to show and hide buy button and show owner address
export const GetUpdateTransactionHash = createAsyncThunk(
  "getupdateTrxHash/premintupdateTransactionHash",
  async (id, thunkAPI) => {
    try {
      return await getUpdateTransactionHashAPI(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const getSmartContractAddress = createAsyncThunk(
  "getSmartContractAddress/premintgetSmartContractAddress",
  async (data, thunkAPI) => {
    try {
      return await getSmartContractAddressAPI(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// calling and updating new owner address after buy premint asset 
export const updateOwnerAddress = createAsyncThunk(
  "updateOwnerAddressAPI/premintupdateOwnerAddressAPI",
  async (data, thunkAPI) => {
    try {
      return await updateOwnerAddressAPI(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const buyPremintAssest = createAsyncThunk(
  "buyPremintAssest/Assest",
  async (data, thunkAPI) => {
    try {
      const {
        walletAddress,
        tokenId,
        uri,
        assetPrice,
        ownerAdress,
        adminAdress,
        adminShareBaisePoint,
      } = data;
      // console.log(
      //   walletAddress,
      //   tokenId,
      //   uri,
      //   assetPrice,
      //   ownerAdress,
      //   adminAdress,
      //   adminShareBaisePoint
      // );
      const { nftRegistrationtype, Blockchaintype, networkType, erctype } =
        await getinstancesParamter();
      let contract_Methods = await createWeb3ContractMethodInstance(
        nftRegistrationtype,
        Blockchaintype,
        networkType,
        erctype
      );
      const contract = await contract_Methods.contract_Methods.methods
        .Buypremintasset(
          walletAddress,
          tokenId,
          uri,
          assetPrice,
          ownerAdress,
          adminAdress,
          adminShareBaisePoint
        )
        .send({ from: walletAddress, value: assetPrice })
        .on("transactionHash", function (hash) {
          const timer = setInterval(() => {
            window.web3.eth
              .getTransactionReceipt(hash)
              .then((res) => {
                console.log("gettrnx", res);
                if (res.status) {
                  // dispatch(
                  updateTransactionHash({
                    _id: data._id,
                    transactionHash: res.transactionHash,
                    transactionStatus: res.status,
                  });
                  // );
                  clearInterval(timer);
                }
              })
              .catch((error) => console.log(error));
          }, 3000);
          console.log("transactionHash", hash);
        });
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      console.error(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
