import Web3 from "web3";
import axios from "axios";
const constant = require("../../components/Constant/Constant");
var apibaseurl = constant.getAPiUrl();

const callStatusfunctionforRopsten = async (value, id) => {
  let checkUpdateStatusforRopsten = await axios({
    method: "get",
    url: `${apibaseurl}/api/admin/getIsUpdatedStatus/${id}`,
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  }).catch((error) => {
    console.log(error);
  });

  sessionStorage.setItem(value, checkUpdateStatusforRopsten.data.isUpdated);
};

const callStatusfunctionforRinkeyBy = async (value, id) => {
  let checkUpdateStatusforRinkeyBy = await axios({
    method: "get",
    url: `${apibaseurl}/api/admin/getIsUpdatedStatus/${id}`,
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  }).catch((error) => {
    console.log(error);
  });

  sessionStorage.setItem(value, checkUpdateStatusforRinkeyBy.data.isUpdated);
};
const callStatusfunctionforPolygonMumbai = async (value, id) => {
  let checkUpdateStatusforPolygonMumbai = await axios({
    method: "get",
    url: `${apibaseurl}/api/admin/getIsUpdatedStatus/${id}`,
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  }).catch((error) => {
    console.log(error);
  });

  sessionStorage.setItem(
    value,
    checkUpdateStatusforPolygonMumbai.data.isUpdated
  );
};

const updatedContractforRinkeyBy = async (
  registrationType,
  blockchaintype,
  networktype,
  erctype
) => {
  let getresponse = await axios({
    method: "get",
    url: `${apibaseurl}/api/admin/getSmartContractAddress/${registrationType}/${blockchaintype}/${networktype}/${erctype}`,
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  }).catch((error) => {
    console.log(error);
  });

  var contract_Methods;

  if (registrationType == 0) {
    sessionStorage.setItem(
      "nftsmartContractforRinkeyBy",
      JSON.stringify(getresponse.data.data[0])
    );
    callStatusfunctionforRopsten(
      "nftCheckStatusforRinkeyBy",
      getresponse.data.data[0]._id
    );
  } else if (registrationType == 1) {
    sessionStorage.setItem(
      "marketsmartContractforRinkeyBy",
      JSON.stringify(getresponse.data.data[0])
    );
    callStatusfunctionforRopsten(
      "marketCheckStatusforRinkeyBy",
      getresponse.data.data[0]._id
    );
  }

  return getresponse.data.data[0];
};

const updatedContractforPolygonMumbai = async (
  registrationType,
  blockchaintype,
  networktype,
  erctype
) => {
  let getresponse = await axios({
    method: "get",
    url: `${apibaseurl}/api/admin/getSmartContractAddress/${registrationType}/${blockchaintype}/${networktype}/${erctype}`,
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  }).catch((error) => {
    console.log(error);
  });

  var contract_Methods;

  if (registrationType == 0) {
    sessionStorage.setItem(
      "nftsmartContractforPolygonMumbai",
      JSON.stringify(getresponse.data.data[0])
    );
    callStatusfunctionforRopsten(
      "nftCheckStatusforPolygonMumbai",
      getresponse.data.data[0]._id
    );
  } else if (registrationType == 1) {
    sessionStorage.setItem(
      "marketsmartContractforPolygonMumbai",
      JSON.stringify(getresponse.data.data[0])
    );
    callStatusfunctionforRopsten(
      "marketCheckStatusforPolygonMumbai",
      getresponse.data.data[0]._id
    );
  }

  return getresponse.data.data[0];
};
const updatedContractforRopsten = async (
  registrationType,
  blockchaintype,
  networktype,
  erctype
) => {
  let getresponse = await axios({
    method: "get",
    url: `${apibaseurl}/api/admin/getSmartContractAddress/${registrationType}/${blockchaintype}/${networktype}/${erctype}`,
    headers: {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  }).catch((error) => {
    console.log(error);
  });

  var contract_Methods;

  if (registrationType == 0) {
    sessionStorage.setItem(
      "nftsmartContractforRopsten",
      JSON.stringify(getresponse.data.data[0])
    );
    callStatusfunctionforRopsten(
      "nftCheckStatusforRopsten",
      getresponse.data.data[0]._id
    );
  } else if (registrationType == 1) {
    sessionStorage.setItem(
      "marketsmartContractforRopsten",
      JSON.stringify(getresponse.data.data[0])
    );
    callStatusfunctionforRopsten(
      "marketCheckStatusforRopsten",
      getresponse.data.data[0]._id
    );
  }

  return getresponse.data.data[0];
};

async function createWeb3ContractMethodInstanceCopy(
  registrationType,
  blockchaintype,
  networktype,
  erctype
) {
  await window.ethereum.enable();
  const checknetworkId = window.ethereum.networkVersion;
  sessionStorage.setItem("checknetworkId", checknetworkId);
  console.log(checknetworkId, "line no 71");

  if (
    sessionStorage.marketsmartContractforRopsten &&
    sessionStorage.nftsmartContractforRopsten &&
    checknetworkId == 3
  ) {
    // alert(true)
    let checkIdStatus;
    if (registrationType == 0) {
      let parseObject = JSON.parse(sessionStorage.nftsmartContractforRopsten);
      checkIdStatus = parseObject._id;
    } else if (registrationType == 1) {
      let parseObject = JSON.parse(
        sessionStorage.marketsmartContractforRopsten
      );
      checkIdStatus = parseObject._id;
    }

    let checkUpdateStatusforRopsten = await axios({
      method: "get",

      url: `${apibaseurl}/api/admin/getIsUpdatedStatus/${checkIdStatus}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    }).catch((error) => {
      console.log(error);
    });

    var contract_Methods;
    let getvalueofsupportedABIfileandAddresss;

    if (registrationType == 0) {
      if (
        `${checkUpdateStatusforRopsten.data.isUpdated}` ==
        sessionStorage.nftCheckStatusforRopsten
      ) {
          getvalueofsupportedABIfileandAddresss = JSON.parse(
          sessionStorage.nftsmartContractforRopsten
        );
      } else {
        console.log("else part");
        getvalueofsupportedABIfileandAddresss = await updatedContractforRopsten(
          registrationType,
          blockchaintype,
          networktype,
          erctype
        );
      }
    } else if (registrationType == 1) {
      if (
        `${checkUpdateStatusforRopsten.data.isUpdated}` ==
        sessionStorage.marketCheckStatusforRopsten
      ) {
        getvalueofsupportedABIfileandAddresss = JSON.parse(
          sessionStorage.marketsmartContractforRopsten
        );
      } else {
        // console.log("else part")
        getvalueofsupportedABIfileandAddresss = await updatedContractforRopsten(
          registrationType,
          blockchaintype,
          networktype,
          erctype
        );
      }
    }
    console.log(getvalueofsupportedABIfileandAddresss);
    await loadWeb3();
    contract_Methods = await commoncontractmethod(
      getvalueofsupportedABIfileandAddresss
    );

    //end part
  } else if (
    sessionStorage.marketsmartContractforPolygonMumbai &&
    sessionStorage.nftsmartContractforPolygonMumbai &&
    checknetworkId == 80001
  ) {
    let checkIdStatus;
    if (registrationType == 0) {
      let parseObject = JSON.parse(
        sessionStorage.nftsmartContractforPolygonMumbai
      );
      checkIdStatus = parseObject._id;
    } else if (registrationType == 1) {
      let parseObject = JSON.parse(
        sessionStorage.marketsmartContractforPolygonMumbai
      );
      checkIdStatus = parseObject._id;
    }

    let checkUpdateStatusforPolygonMumbai = await axios({
      method: "get",
      url: `${apibaseurl}/api/admin/getIsUpdatedStatus/${checkIdStatus}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    }).catch((error) => {
      console.log(error);
    });

    var contract_Methods;
    let getvalueofsupportedABIfileandAddresss;

    if (registrationType == 0) {
      if (
        `${checkUpdateStatusforPolygonMumbai.data.isUpdated}` ==
        sessionStorage.nftCheckStatusforPolygonMumbai
      ) {
        getvalueofsupportedABIfileandAddresss = JSON.parse(
          sessionStorage.nftsmartContractforPolygonMumbai
        );
      } else {
        console.log("else part");
        getvalueofsupportedABIfileandAddresss =
          await updatedContractforPolygonMumbai(
            registrationType,
            blockchaintype,
            networktype,
            erctype
          );
      }
    } else if (registrationType == 1) {
      if (
        `${checkUpdateStatusforPolygonMumbai.data.isUpdated}` ==
        sessionStorage.marketCheckStatusforPolygonMumbai
      ) {
        getvalueofsupportedABIfileandAddresss = JSON.parse(
          sessionStorage.marketsmartContractforPolygonMumbai
        );
      } else {
        // console.log("else part")
        getvalueofsupportedABIfileandAddresss =
          await updatedContractforPolygonMumbai(
            registrationType,
            blockchaintype,
            networktype,
            erctype
          );
      }
    }
    console.log(getvalueofsupportedABIfileandAddresss);
    await loadWeb3();
    contract_Methods = await commoncontractmethod(
      getvalueofsupportedABIfileandAddresss
    );

    // var contract_Methods
    // let getvalueofsupportedABIfileandAddresss
    //     if(registrationType == 0){
    //       getvalueofsupportedABIfileandAddresss   = JSON.parse(sessionStorage.nftsmartContractforPolygonMumbai)
    //     }
    //     else if(registrationType == 1){
    //       getvalueofsupportedABIfileandAddresss   = JSON.parse(sessionStorage.marketsmartContractforPolygonMumbai)
    //     }
    //     await loadWeb3();
    //     contract_Methods = await commoncontractmethod(getvalueofsupportedABIfileandAddresss);
  } else if (
    sessionStorage.marketsmartContractforRinkeyBy &&
    sessionStorage.nftsmartContractforRinkeyBy &&
    checknetworkId == 4
  ) {
    var contract_Methods;
    let getvalueofsupportedABIfileandAddresss;
    if (registrationType == 0) {
      getvalueofsupportedABIfileandAddresss = JSON.parse(
        sessionStorage.nftsmartContractforRinkeyBy
      );
    } else if (registrationType == 1) {
      getvalueofsupportedABIfileandAddresss = JSON.parse(
        sessionStorage.marketsmartContractforRinkeyBy
      );
    }
    await loadWeb3();
    contract_Methods = await commoncontractmethod(
      getvalueofsupportedABIfileandAddresss
    );
  } else {
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;

    let getresponse = await axios({
      method: "get",
      url: `${apibaseurl}/api/admin/getSmartContractAddress/${registrationType}/${blockchaintype}/${networktype}/${erctype}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    }).catch((error) => {
      console.log(error);
    });

    var contract_Methods;

    if (checknetworkId == 3) {
      if (registrationType == 0) {
        sessionStorage.setItem(
          "nftsmartContractforRopsten",
          JSON.stringify(getresponse.data.data[0])
        );
        callStatusfunctionforRopsten(
          "nftCheckStatusforRopsten",
          getresponse.data.data[0]._id
        );
      } else if (registrationType == 1) {
        sessionStorage.setItem(
          "marketsmartContractforRopsten",
          JSON.stringify(getresponse.data.data[0])
        );
        callStatusfunctionforRopsten(
          "marketCheckStatusforRopsten",
          getresponse.data.data[0]._id
        );
      }
    } else if (checknetworkId == 4) {
      if (registrationType == 0) {
        sessionStorage.setItem(
          "nftsmartContractforRinkeyBy",
          JSON.stringify(getresponse.data.data[0])
        );
      } else if (registrationType == 1) {
        sessionStorage.setItem(
          "marketsmartContractforRinkeyBy",
          JSON.stringify(getresponse.data.data[0])
        );
      }
    } else if (checknetworkId == 80001) {
      if (registrationType == 0) {
        sessionStorage.setItem(
          "nftsmartContractforPolygonMumbai",
          JSON.stringify(getresponse.data.data[0])
        );
        callStatusfunctionforPolygonMumbai(
          "nftCheckStatusforPolygonMumbai",
          getresponse.data.data[0]._id
        );
      } else if (registrationType == 1) {
        sessionStorage.setItem(
          "marketsmartContractforPolygonMumbai",
          JSON.stringify(getresponse.data.data[0])
        );
        callStatusfunctionforPolygonMumbai(
          "marketCheckStatusforPolygonMumbai",
          getresponse.data.data[0]._id
        );
      }
    }

    await loadWeb3();
    contract_Methods = await commoncontractmethod(getresponse.data.data[0]);
  }

  console.log(contract_Methods);

  return {
    contract_Methods,
  };
}

async function commoncontractmethod(commoncontractobject) {
  console.log(commoncontractobject, "line no 36");

  return await new window.web3.eth.Contract(
    commoncontractobject.abi.abi,
    commoncontractobject.address
  );
}

async function loadWeb3() {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    window.ethereum.enable();
  }
}

export default createWeb3ContractMethodInstanceCopy;
