import React, { useState,useEffect } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Users from "./Users";
import Control from "./Control";
import Options from "./Options";
import Web3 from "web3";
import axios from "axios";
import VideoThumbnail from "react-video-thumbnail";
import Modal from "../../components/Modal";
import VideoModal from "../VideoModal";
import mp3image from "../../assets/images/mp3icon.png"

 const contractABI = require('../../components/ABI/NFTSalePurchase.json')
 const contract = require("../../components/ContractAddress/Contract")
 const marketcontractABI = require('../../components/ABI/NFTMarketPlace.json')
 const marketcontract = require("../../components/ContractAddress/Contract")
 const constant = require("../../components/Constant/Constant")

 

 var apiBaseUrl = constant.getAPiUrl();

// const navLinks = ["Info", "Owners", "History"/**, "Bids" */];
const navLinks = ["Info", "Owners","History", /**, "Bids" */];

const categories = [
  // {
  //   category: "black",
  //   content: "art",
  // },
  // {
  //   category: "purple",
  //   content: "unlockable",
  // },
];

const users = {
  Info: [{
    name: "Raquel Will",
    position: "Owner",
    avatar: "/images/content/avatar-2.jpg",
    reward: "/images/content/reward-1.svg",
  },
  {
    name: "Selina Mayert",
    position: "Info",
    avatar: "/images/content/avatar-1.jpg",
  }],
  Owners: [{
    name: "Raquel Will",
    position: "History",
    avatar: "/images/content/avatar-2.jpg",
    reward: "/images/content/reward-1.svg",
  },
  {
    name: "Selina Mayert",
    position: "Creator",
    avatar: "/images/content/avatar-1.jpg",
  }],
  History: [{
    name: "Raquel Will",
    position: "Owner",
    avatar: "/images/content/avatar-2.jpg",
    reward: "/images/content/reward-1.svg",
  },
  {
    name: "Selina Mayert",
    position: "Creator",
    avatar: "/images/content/avatar-1.jpg",
  }]
}
;

const Item = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [itemaddress,setitemaddress]= useState("");
  const [itemname,setitemname]= useState("");
  const [itedesc,setitedesc]= useState("");
  const [saleprice,setsaleprice]= useState("");
  const [videomodaltoggle,setvideomodaltoggle]=useState(false)
  const [tokeniddetails,settokeniddetails]= useState();
  const [tokenhistory,settokenhistory]= useState([]);
  const [showmorehide,setshowmorehide]=useState(true)
  const [showunlockurltoggle,setshowunlockurltoggle]=useState(false)
  const [showunlockbutton,setshowunlockbutton]=useState(false)
  const [unlockurl,setunlockurl]= useState("");
  const [unlockurldescription,setunlockurldescription]= useState("");
  const [contractAddress,setcontractAddress]= useState("");

  useEffect(() => {
   
    let itemaddress = localStorage.getItem("Itemvalue")
    let itemname = localStorage.getItem("ItemDetails")
    let itedesc = localStorage.getItem("ItemDesc")

    gettokeniddetailsfetch() 
    
    
    setitemaddress(itemaddress)
    setitemname(itemname)
    setitedesc(itedesc)

  },[])


  const  gettokeniddetailsfetch = async()=>{
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion
      axios({
        method: 'get',
       
         url: `${apiBaseUrl}/api/user/getTokenIdDetails/${localStorage.PricetokenId}/${checknetworkId}`,
        headers: {
          "Authorization":localStorage.token ? `Bearer ${localStorage.token}` : undefined,
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
    
    }).then((response)=>{

  if(response.data.status == 200){
   
  
    if(response.data.message.tokenEventHistory.eventHistory.length){
      settokenhistory(response.data.message.tokenEventHistory.eventHistory.reverse())
    }
    unlockpurchasefunction(response.data.message.tokenIdDetails)
    if(response.data.message.address){
      setcontractAddress(response.data.message.address)
    }
    settokeniddetails(response.data.message.tokenIdDetails)
  
  
  }
  
    }).catch((error)=>{
      console.log(error)
    })



  }

  const unlockpurchasefunction = async(getobject)=>{  
  
     if(getobject.currentOwner == localStorage.CurrentAddress ){
      await window.ethereum.enable();
      const checknetworkId = window.ethereum.networkVersion
 
      axios({
        method: 'get',       
         url: `${apiBaseUrl}/api/user/getUnlockPurchaseDetails/${localStorage.PricetokenId}/${checknetworkId}`,
        headers: {
          "Authorization":localStorage.token ? `Bearer ${localStorage.token}` : undefined,
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
    
    })
    .then((response)=>{
      
      if(response.data.message.url){
   
        setunlockurl(response.data.message.url)
        setunlockurldescription(response.data.message.description)
        setshowunlockbutton(true)
      
    
      }
    })
    .catch((error)=>{
      console.log(error)
    })
     }
     else {
       console.log("flase")
     }
    
      }
  
  

  async function checkPrice() {
    try {
      
      if(localStorage.PricetokenId){

        const price = await window.marketcontract.methods.TokenPrice(localStorage.PricetokenId).call();
  
          let gotbalaenc =  await window.web3.utils.fromWei(price)
     
  let saleprice = gotbalaenc.slice(0,6)

  setsaleprice(saleprice)
        
      

      }
     
     
   
    
      
    } catch (error) {
      console.log("error", error);
    }
  }

  const videotoggle = (value)=>{
    setvideomodaltoggle(false)
  }



  return (
    <>
      <div className={cn("section", styles.section)}>
      <Modal
    
    visible={showunlockurltoggle}
  

 >
   
   <div onClick={()=>{

    setshowunlockurltoggle(false)
   }}>
  
  <center><h3 style={{cursor:"pointer",color:"#3772FF"}} onClick={()=>{
     window.open(unlockurl);
   }}>{unlockurl}</h3></center>
   <br/>
   <center><h4>{unlockurldescription}</h4></center>

   
  <br/>
  <center> <h4><span style={{marginLeft:"",cursor:"pointer"}}  onClick={()=>{
  
  }}>OK</span></h4></center>



   <div >
   
                </div>
   </div>
   </Modal>
      <Modal
        visible={videomodaltoggle}
        >
      < VideoModal videotoggle={videotoggle} videoimage={itemaddress} /> 

          </Modal>
        <div className={cn("container", styles.container)}>
          <div className={styles.bg}>
            <div className={styles.preview}>
              <div className={styles.categories}>
                {categories.map((x, index) => (
                  <div
                    className={cn(
                      { "status-black": x.category === "black" },
                      { "status-purple": x.category === "purple" },
                      styles.category
                    )}
                    key={index}
                  >
                    {x.content}
                  </div>
                ))}
              </div>
              {localStorage.ItemfileType == "video/mp4" && 
              <div style={{maxWidth:"550px",maxHeight:"550px",width:"550px",height:"550px",cursor:"pointer"}} onClick={()=>{
                setvideomodaltoggle(true)
              }}>
           <center>
             <VideoThumbnail
                
                // videoUrl={"https://gateway.pinata.cloud/ipfs/Qmem4exur6JCXTkJuc4XSYttEzFJg1U6RejgKrot7DNuCB"}
                videoUrl={itemaddress}
                thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                
              
          
              />
           </center>
           </div>
        }
        {localStorage.ItemfileType != "video/mp4" && localStorage.ItemfileType != "audio/mpeg" &&
              <img
                srcSet={itemaddress}
                src="/images/content/item-pic.jpg"
                alt="Item"
                style={{maxWidth:"550px",maxHeight:"550px",width:"550px",height:"550px",cursor:"pointer"}}
              />
      }

<div style={{cursor:"pointer"}} onClick={()=>{
        setvideomodaltoggle(true)
      }}>

{  localStorage.ItemfileType == "audio/mpeg" &&
              <img
                srcSet={mp3image}
                src="/images/content/item-pic.jpg"
                alt="Item"
                style={{maxWidth:"550px",maxHeight:"550px",width:"550px",height:"550px",cursor:"pointer"}}
              />
      }
      </div>
              
            </div>
            
          <Options className={styles.options} /> 
          </div>
          <div className={styles.details}>
          
          <h1 className={cn("h3", styles.title)}>{itemname}</h1>         
        
            <div className={styles.cost}>
              <div className={cn("status-stroke-green", styles.price)}>
                {saleprice} ETH
              </div>
         

{showunlockbutton &&
              <div className={cn("status-stroke-green", styles.price)} style={{marginLeft:"35%",cursor:"pointer"}} onClick={()=>{
                     setshowunlockurltoggle(true)
                   }}>
           <span >unlock</span>
           

                


              </div>
}
            </div>
            
            <div className={styles.info}>
            {showmorehide &&
              <>
            
              {itedesc.length > 100 ? (
                <>
                  {itedesc.slice(0,100)}... <span style={{color:"white",cursor:"pointer"}} onClick={()=>{
                    setshowmorehide(false)
                  }}>see more</span> 
                  </>
              ): (
                <>
                {itedesc.slice(0,100)}
                </>
              )
              }
                </>}
                {!showmorehide &&
              <>
            
             
                  {itedesc}... <span style={{color:"white",cursor:"pointer"}} onClick={()=>{
                    setshowmorehide(true)
                  }}>see less</span> 
                  </>
             
              }
              
            </div>
            <div className={styles.nav}>
              {navLinks.map((x, index) => (
                <button
                  className={cn(
                    { [styles.active]: index === activeIndex },
                    styles.link
                  )}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
           
            <Users className={styles.users}  items={activeIndex} tokenhistory={tokenhistory} tokendetails={tokeniddetails} contractaddress={contractAddress}  />
            
            <Control className={styles.control} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
