import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./User.module.sass";
import Icon from "../../../components/Icon";
import Report from "../../../components/Report";
import Modal from "../../../components/Modal";
import axios from "axios";
const constant = require("../../../components/Constant/Constant");
var apiBaseUrl = constant.getAPiUrl();

const User = ({ className }) => {
  const [visibleModalReport, setVisibleModalReport] = useState(false);
  const [getaddress, setgetaddress] = useState("");
  const [userName, setuserName] = useState("");
  const [userImage, setuserImage] = useState("");
  const [websiteurl, setwebsiteurl] = useState("");
  const [biodata, setbiodata] = useState("");
  const [showmorehide, setshowmorehide] = useState(true);
  const [membersince, setmembersince] = useState("");
  const [tooltipname, settooltipname] = useState("Copy Address");

  useEffect(() => {
    getaccountaddress();
  }, []);

  //get profile data by passing current wallet address and network id

  async function getaccountaddress() {
    let account = localStorage.CurrentAddress;
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;
    setgetaddress(account);
    const headers = {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(
        `${apiBaseUrl}/api/user/getProfile/${account.toLowerCase()}/${checknetworkId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log(response);

        if (response.data.status == 200) {
          if (response.data.message.profilePhoto) {
            let completename = `${response.data.message.firstName} ${response.data.message.lastName}`;

            setuserName(completename);
            setuserImage(response.data.message.profilePhoto);
            setwebsiteurl(response.data.message.websiteUrl);
            setbiodata(response.data.message.bio);
            setmembersince(response.data.message.createdAt);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const tooltipfunction = () => {
    settooltipname("copied!");
  };

  return (
    <>
      <div className={cn(styles.user, className)}>
        {userImage && (
          <div className={styles.avatar}>
            <img src={userImage} alt="Avatar" />
          </div>
        )}

        <div className={styles.name}>{userName}</div>

        <div className={styles.code} style={{ cursor: "pointer" }}>
          {getaddress && (
            <div
              className={styles.number}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(
                  `https://ropsten.etherscan.io/address/${getaddress}`
                );
              }}
            >
              {getaddress.slice(0, 10)}...
              {getaddress.slice(getaddress.length - 10)}
            </div>
          )}

          <div title={tooltipname} className="tooltip">
            <button
              className={styles.copy}
              style={{ cursor: "pointer" }}
              onClick={() => navigator.clipboard.writeText(getaddress)}
            >
              <Icon name="copy" size="16" />
            </button>
          </div>
        </div>
        <div className={styles.info}>
          {showmorehide && (
            <>
              {biodata.length > 100 ? (
                <>
                  {biodata.slice(0, 100)}...{" "}
                  <strong
                    style={{ color: "", cursor: "pointer" }}
                    onClick={() => {
                      setshowmorehide(false);
                    }}
                  >
                    See More
                  </strong>
                </>
              ) : (
                <>{biodata.slice(0, 100)}</>
              )}
            </>
          )}
          {!showmorehide && (
            <>
              {biodata}...{" "}
              <strong
                style={{ color: "", cursor: "pointer" }}
                onClick={() => {
                  setshowmorehide(true);
                }}
              >
                See Less
              </strong>
            </>
          )}
        </div>
        {websiteurl && (
          <a
            className={styles.site}
            href={`${websiteurl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="globe" size="16" />
            <span>{websiteurl}</span>
          </a>
        )}
        <br />
      </div>
      <Modal
        visible={visibleModalReport}
        onClose={() => setVisibleModalReport(false)}
      >
        <Report />
      </Modal>
    </>
  );
};

export default User;
