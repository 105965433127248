import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import cn from "classnames";
import styles from "./ConnectWallet.module.sass";
import Icon from "../../components/Icon";
// import Checkbox from "../../components/Checkbox";
// import Web3 from "web3";
// import Modal from "../../components/Modal";
import metaMask from "../../assets/images/metamask.png";
// import axios from "axios";
import { Modal } from "antd";
import Page from "../../components/Page/index";
const constant = require("../../components/Constant/Constant");
// var apiBaseUrl = constant.getAPiUrl();

const menu = [
  {
    title: "Meta Mask",
    color: "#45B26B",
  },
];

const Connect = () => {
  const [start, getStart] = useState(false);
  const [timeby, settimeby] = useState(1);
  const [connectnowmodal, setconnectnowmodal] = useState(false);
  const [disconnectmodal, setdisconnectmodal] = useState(false);
  const [CurrentAddress, setCurrentAddress] = useState(
    localStorage.CurrentAddress
  );
  // const getStarted = (event) => {
  //   localStorage.setItem("start", true);
  //   getStart(true);
  // };

  useEffect(() => {
    setInterval(function () {
      //get wallet address using getaccountaddress function
      getaccountaddress();

      settimeby(timeby + 1);
    }, 2000);
  }, []);

  async function getaccountaddress() {
    try {
      await window.ethereum.enable();
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const account = accounts[0];

      //if wallet address found set into currentAddress State

      setCurrentAddress(account);

      window.ethereum.on("accountsChanged", function (accounts) {
        setCurrentAddress(account[0]);
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  if (start) {
    return <Redirect to="/" />;
  }

  //connect to wallet
  const onClickConnect = async () => {
    window.location.reload();
  };

  return (
    <Page>
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.head}>
            <Modal
              visible={disconnectmodal}
              footer={false}
              onCancel={() => {
                setdisconnectmodal(false);
              }}
            >
              <div>
                <center>
                  <h4>Would you like to Disconnect with your Wallet</h4>
                </center>
                <br />

                <center>
                  <h4>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.location = "/";
                        localStorage.removeItem("isconnected");
                        localStorage.clear();
                        console.log("1");
                        localStorage.setItem("darkMode", true);
                      }}
                    >
                      Yes
                    </span>{" "}
                    <span
                      style={{ marginLeft: "10%", cursor: "pointer" }}
                      onClick={() => {
                        setdisconnectmodal(false);
                      }}
                    >
                      No
                    </span>
                  </h4>
                </center>

                <div></div>
              </div>
            </Modal>
            <Modal
              visible={connectnowmodal}
              footer={false}
              onCancel={() => {
                setconnectnowmodal(false);
              }}
            >
              <div>
                <center>
                  <h4>Would you like to Connect with your Wallet</h4>
                </center>
                <br />

                <center>
                  <h4>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.location = "/";
                        localStorage.setItem("isconnected", true);
                      }}
                    >
                      Yes
                    </span>{" "}
                    <span
                      style={{ marginLeft: "10%", cursor: "pointer" }}
                      onClick={() => {
                        setconnectnowmodal(false);
                      }}
                    >
                      No
                    </span>
                  </h4>
                </center>

                <div></div>
              </div>
            </Modal>
            <Link className={styles.back} to="/">
              <Icon name="arrow-prev" size="24" />
              <div className={cn("h2", styles.stage)}>Connect your wallet</div>
            </Link>
          </div>
          <div className={styles.body}>
            <div className={styles.menu}>
              {menu.map((x, index) => (
                <div
                  className={cn({ [styles.active]: index === 1 }, styles.link)}
                  key={index}
                >
                  <div
                    className={styles.icon}
                    style={{ backgroundColor: x.color }}
                  >
                    <img
                      src={metaMask}
                      alt="meta mask"
                      height="64px"
                      width="64px"
                    />
                  </div>
                  <span>{x.title}</span>
                  <div className={styles.arrow}>
                    <Icon name="arrow-next" size="14" />
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.wrapper}>
              <div className={styles.item}>
                <div className={styles.btns}>
                  {CurrentAddress && localStorage.isconnected == undefined && (
                    <button
                      className={cn("button", styles.button)}
                      style={{ marginTop: "6%" }}
                      onClick={() => {
                        setconnectnowmodal(true);
                      }}
                    >
                      Connect Now
                    </button>
                  )}

                  {!CurrentAddress && (
                    <button
                      className={cn("button", styles.button)}
                      style={{ marginTop: "8%" }}
                      onClick={() => {
                        onClickConnect();
                      }}
                    >
                      MetaMask not Connected
                    </button>
                  )}
                  {localStorage.isconnected && (
                    <button
                      className={cn("button", styles.button)}
                      style={{ marginTop: "8%" }}
                      onClick={() => {
                        setdisconnectmodal(true);
                      }}
                    >
                      Disconnect
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Connect;
