
import cn from "classnames";
import styles from "./Faq.module.sass";
import style from "./style.css"
import cardimage from "./card-icons/card.svg"
 import expirydate from "./card-icons/exp-date.svg"
 import cvv from "./card-icons/cvv.svg"
// import logo-payment-method from "./"
// import HotBid from "../../components/HotBid";

const Samplepage = () => {
  return (
   
       <div className={cn("section", styles.section)} style={{backgroundColor:""}}>       
      <div className={cn("container", styles.container)} style={{backgroundColor:""}}>

      <form
      id="payment-form"
      method="POST"
      action="https://merchant.com/charge-card"
    >
      <label for="card-number">Card number</label>
      <div class="input-container card-number">
        <div class="icon-container">
          <img
            id="icon-card-number"
            src={cardimage}
            alt="PAN"
          />
        </div>
        <div class="card-number-frame"></div>
        <div class="icon-container payment-method">
          <img id="logo-payment-method" />
        </div>
        <div class="icon-container">
          <img id="icon-card-number-error" src="images/card-icons/error.svg" />
        </div>
      </div>

      <div class="date-and-code">
        <div>
          <label for="expiry-date">Expiry date</label>
          <div class="input-container expiry-date">
            <div class="icon-container">
              <img
                id="icon-expiry-date"
                src={expirydate}
                alt="Expiry date"
              />
            </div>
            <div class="expiry-date-frame"></div>
            <div class="icon-container">
              <img
                id="icon-expiry-date-error"
                src="card-icons/error.svg"
              />
            </div>
          </div>
        </div>

        <div >
          <label for="cvv" style={{borderColor:""}}>Security code</label>
          <div class="input-container cvv" style={{backgroundColor:""}}>
            <div class="icon-container" style={{backgroundColor:""}}>
              <img id="icon-cvv" src={cvv} alt="CVV" />
            </div>
            <div class="cvv-frame" style={{backgroundColor:""}}></div>
            <div class="icon-container" >
              <img id="icon-cvv-error" src="images/card-icons/error.svg" />
            </div>
          </div>
        </div>
      </div>

      <button id="pay-button" disabled="">
        PAY GBP 25.00
      </button>

      <div>
        <span class="error-message error-message__card-number"></span>
        <span class="error-message error-message__expiry-date"></span>
        <span class="error-message error-message__cvv"></span>
      </div>

      <p class="success-payment-message"></p>
    </form>
      </div>
      </div>
     
 
    
  );
};

export default Samplepage;
