import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Items.module.sass";
import Card from "../../../components/ProfileCard";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import axios from "axios";
import { Fragment } from "react/cjs/react.development";
// import Web3 from "web3";
const constant = require("../../../components/Constant/Constant");

// const contractABI = require("../../../components/ABI/NFTSalePurchase.json");
// const contract = require("../../../components/ContractAddress/Contract");
// const marketcontractABI = require("../../../components/ABI/NFTMarketPlace.json");
// const marketcontract = require("../../../components/ContractAddress/Contract");
var apiBaseUrl = constant.getAPiUrl();

const Items = ({ className, items }) => {
  const [arrayofmintvalue, setarrayofmintvalue] = useState([]);

  const [arraylength, setarraylength] = useState(0);
  const [timeby, settimeby] = useState(1);
  const [nottokenmessage, setnottokenmessage] = useState("");
  const [tokenexpiredtoggle, settokenexpiredtoggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [collectionoftokenId, setcollectionoftokenId] = useState([]);

  const data = arrayofmintvalue;

  useEffect(() => {
    getcreatedassetcallapi();
  }, []);

  const getcreatedassetcallapi = async () => {
    let accountaddress = localStorage.getItem("CurrentAddress");

    let url = "";
    if (accountaddress) {
      url = accountaddress.toLowerCase();
    }

    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;

    axios({
      method: "get",
      url: `${apiBaseUrl}/api/user/getCreatedTokenId/${url}/${checknetworkId}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          if (response.data.message.CreatedTokenId.length) {
            console.log(response.data.message);
            setcollectionoftokenId(response.data.message.CreatedTokenId);

            setarraylength(response.data.message.length);
          } else {
            setnottokenmessage("No NFTs Are Available");
          }
        } else if (response.data.status == 404) {
          setnottokenmessage("No NFTs are Available");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        if (error.message == "Request failed with status code 400") {
          settokenexpiredtoggle(true);
          // setmodaltoggle(true)
        } else {
          setnottokenmessage("No NFTs are Available");
        }
        setIsLoading(false);
      });
  };

  return (
    <div className={cn(styles.items, className)}>
      <Modal visible={tokenexpiredtoggle}>
        <div>
          <center>
            <h4>Your Session Has Expired</h4>
          </center>
          <br />
          <center>
            <h4>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location = "/login";
                }}
              >
                Login
              </span>{" "}
              <span
                style={{ marginLeft: "10%", cursor: "pointer" }}
                onClick={() => {
                  settokenexpiredtoggle(false);
                }}
              >
                Cancel
              </span>
            </h4>
          </center>

          <div></div>
        </div>
      </Modal>
      <div className={styles.list}>
        <>
          <center>
            <h3 style={{ marginTop: "50%" }}>{nottokenmessage}</h3>
          </center>
          {isLoading ? (
            <Loader />
          ) : (
            <Fragment>
              {collectionoftokenId.map((x, index) => (
                <Card className={styles.card} item={x} key={index} />
              ))}
            </Fragment>
          )}
        </>
      </div>
    </div>
  );
};

export default Items;
