import React from "react";
import Page from "../../components/Page/index";
import Hero from "./dani/Hero";
import "./daniHome.sass";

const DaniHome = () => {
  return (
    <Page>
      <div>
        <Hero />
      </div>
    </Page>
  );
};

export default DaniHome;
