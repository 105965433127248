import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./ProfileEdit.module.sass";
import Control from "../../components/BacktoprofileControl";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import axios from "axios"
const constant = require("../../components/Constant/Constant")
var apiBaseUrl = constant.getAPiUrl();



const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Edit Profile",
  },
];





const ProfileEdit = () => {
  const [showalertmessagetoggle,setshowalertmessagetoggle]= useState(false);
  const [alertmessageshow,setalertmessageshow] = useState('');
  const [checktype,setchecktype]= useState('')
  const [previewImage,setpreviewImage]=useState("https://gateway.pinata.cloud/ipfs/QmWzVBtcM6bAjUTm8bZ9KAy6rbCLW1viKeKt2N43as8XPj")
  const [blockloader,setblockloader]=useState(false)
  const [alertmessage,setalertmessage]=useState("")

  const [firstName,setfirstName]=useState("")
  const [lastName,setlastName]=useState("")
  const [email,setemail]=useState("")
  const [prophoto,setprophoto]=useState({})
  // const [profilePhoto,setprofilePhoto]=useState("https://gateway.pinata.cloud/ipfs/QmSXaq9tUxdwq2cErtLGWqGU68LtkjHZsEVJiSEhBeVSyj")
  const [profilePhoto,setprofilePhoto]=useState("")
  const [profilekey,setprofilekey]=useState("")
  const [websiteUrl,setwebsiteUrl]=useState("")
  const [bio,setbio]=useState("")
const [edittoggle,setedittoggle]=useState(false)
const [invalidfiletypetoggle,setinvalidfiletypetoggle]=useState(false)
  const [state, setstate] = useState({
    
    firstName:"",
    lastName:"",
    profilePhoto:previewImage,
    websiteUrl:"",
    bio:""

  });
  useEffect(()=>{
    
     getaccountaddress()
    
  },[])


 



  async function getaccountaddress() {

    let account = localStorage.CurrentAddress
    await window.ethereum.enable();
  const checknetworkId = window.ethereum.networkVersion
    
    const headers = {
      "Authorization":localStorage.token ? `Bearer ${localStorage.token}` : undefined,
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
    }



   
   
    
  


    axios.get(`${apiBaseUrl}/api/user/getProfile/${account.toLowerCase()}/${checknetworkId}`, {
      headers: headers
    })
    .then((response) => {
     
   
 if(response.data.message.firstName){
  setedittoggle(true)
 }



 if(response.data.message.profilePhoto == undefined){

  setprofilePhoto("https://gateway.pinata.cloud/ipfs/QmSXaq9tUxdwq2cErtLGWqGU68LtkjHZsEVJiSEhBeVSyj")

 }
     
     
          setfirstName(response.data.message.firstName)
   
          setlastName(response.data.message.lastName)
    
        
          setwebsiteUrl(response.data.message.websiteUrl)
    setemail(response.data.message.email)
     
          setbio(response.data.message.bio)
           setprofilePhoto(response.data.message.profilePhoto)
           setprofilekey(response.data.message.key)
    
        

       
       
      
    

    })
    .catch((error) => {
   
      setprofilePhoto("https://gateway.pinata.cloud/ipfs/QmSXaq9tUxdwq2cErtLGWqGU68LtkjHZsEVJiSEhBeVSyj")
   
     
    })
  }

  
  const onFileChange = async({ target }) => {
    setchecktype(target.files[0].type)

   


    setprophoto(target.files[0])
   
    const fileSize = target.files[0].size / 1024 / 1024
  if (fileSize > 1) {
    alert('File size exceeds 1 MB');

  } else {
    
  

    if(target.files[0].type == "video/mp4" ||  target.files[0].type =="audio/mpeg")
    {
      
      setinvalidfiletypetoggle(true)
      setalertmessage("We Support JPEG, PNG, GIF Only")
      
    }
    else{

    
  
  
  
    if (target.files[0]) {
      setpreviewImage("")
      setprofilePhoto("")
      setblockloader(true)
  
      setalertmessage("File Uploading...")
  
      await window.ethereum.enable();
      const checknetworkId = window.ethereum.networkVersion
      let accountaddress = localStorage.getItem("CurrentAddress")


       const data = new FormData();
    data.append("profilePhoto",target.files[0] );
    data.append("networkId",checknetworkId)
    data.append("walletAddress",accountaddress.toLowerCase())
    const headers = {
         
      "content-type": "multipart/form-data",

    }

    axios.post(`${apiBaseUrl}/api/user/uploadImage`, data, {
      headers: headers
    })
    .then((response) => {
    
 
     
      if(response.data.status == 200){
       
        setprofilePhoto(response.data.message.profilePhoto)
        setprofilekey(response.data.message.key)
        setblockloader(false)
        
     
       
      }
    

    })
    .catch((error) => {
      console.log(error)
  
     
    })

  
       
      
  
  
  
      
    }

  }

}
  
  
  }

  const onTextFieldChange = ({ target }) => {

 
      
   

    if(target.name == "firstName"){
      setfirstName(target.value.replace(/[^a-zA-Z]/g, ''))
    }
    else if(target.name == "lastName"){
      setlastName(target.value.replace(/[^a-zA-Z]/g, ''))

    }
    else if(target.name == "email"){
      setemail(target.value)

    }
    else if(target.name == "websiteUrl"){
      setwebsiteUrl(target.value)

    }
    else if(target.name == "bio"){
      
      
        setbio(target.value)
      
      

    }
    
 
  };

  const updateprofilefunction = async()=>{

    if(firstName == ""){
      setshowalertmessagetoggle(true)
      setalertmessageshow("Please Mention First Name")
    }
    else if(lastName == ""){
      setshowalertmessagetoggle(true)
      setalertmessageshow("Please Mention Last Name")
    }
    else if(email == ""){
      setshowalertmessagetoggle(true)
      setalertmessageshow("Please Mention Email Address")
    }
    else if(bio.length > 300){
      setshowalertmessagetoggle(true)
      setalertmessageshow("Bio Data must be less than 300 Character")
    }
    
   

    else{
      
      setalertmessage("Please Wait ...")
      
      setblockloader(true)
    const account = localStorage.getItem("CurrentAddress")
    await window.ethereum.enable();
  const checknetworkId = window.ethereum.networkVersion
  
 
    let data = {
      firstName,
    lastName,
    profilePhoto,
    websiteUrl,
    email,
    bio,
    walletAddress:account.toLowerCase(),
    key:profilekey,
    "networkId":checknetworkId

    }

    
    let apicreateoredit =`${apiBaseUrl}/api/user/setProfile`
    
    const headers = {
            "Authorization":localStorage.token ? `Bearer ${localStorage.token}` : undefined,
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
          }
         
         
          
        


          axios.post(apicreateoredit, data, {
            headers: headers
          })
          .then((response) => {
        
       
           
            if(response.data.status == 200){
              
              
                   window.location ="/profile"
             
            }
          

          })
          .catch((error) => {
            console.log(error)
         
           
          })


        }


  }

 
  return (
    <div className={styles.page}>
      <Control className={styles.control} item={breadcrumbs} />
      <Modal
    
    visible={showalertmessagetoggle}
  

 >
   
   <div onClick={()=>{
     setshowalertmessagetoggle(false)
   }}>
  
   <center><h4>{alertmessageshow}</h4></center>
   
  <br/>
  <center> <h4><span style={{marginLeft:"",cursor:"pointer"}}  onClick={()=>{
  
  }}>OK</span></h4></center>



   <div >
   
                </div>
   </div>
   </Modal>
   <Modal
    
    visible={invalidfiletypetoggle}
  

 >
   
   <div onClick={()=>{
     setblockloader(false)
   }}>
  
   <center><h4>{alertmessage}</h4></center>
   
  <br/>
  <center> <h4><span style={{marginLeft:"",cursor:"pointer"}}  onClick={()=>{
  setinvalidfiletypetoggle(false)
  }}>OK</span></h4></center>



   <div >
   
                </div>
   </div>
   </Modal>
      <Modal
    
    visible={blockloader}
  

 >
   
   <div onClick={()=>{
     setblockloader(false)
   }}>
  
   <center><h4>{alertmessage}</h4></center>
   
  <br/>
  



   <div >
   
                </div>
   </div>
   </Modal>
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.top}>
            <h1 className={cn("h2", styles.title)}>Edit profile</h1>
            <div className={styles.info}>
              You can set preferred display name, create{" "}
              <strong>your profile URL</strong> and manage other personal
              settings.
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.user}>
                <div className={styles.avatar}>
                
                  {profilePhoto != "" &&
                  <img src={profilePhoto} alt="Avatar" />
                  }
                  
                </div>
                <div className={styles.details}>
                  <div className={styles.stage}>Profile photo</div>
                  <div className={styles.text}>
                  
                    We recommend an image upto 1 MB. Gifs work too{" "}
                    <span role="img" aria-label="hooray">
                      🙌
                    </span>
                  </div>
                  <div className={styles.file}>
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                    >
                      Upload
                    </button>
                    <input className={styles.load} type="file" onChange={onFileChange}/>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.category}>Account info</div>
                  <div className={styles.fieldset}>
                    <TextInput
                      className={styles.field}
                      label="* First Name"
                      name="firstName"
                      type="text"
                      onChange={onTextFieldChange}
                      value={firstName}
                      placeholder="Enter your First name"
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="* Last Name "
                      name="lastName"
                      type="text"
                      value={lastName}
                      onChange={onTextFieldChange}
                      placeholder="Enter your last name"
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="* Email Address "
                      name="email"
                      type="text"
                      value={email}
                      onChange={onTextFieldChange}
                      placeholder="Enter your Email Address"
                      required
                    />
                    <TextInput
                      className={styles.field}
                      label="Custom url (Optional) "
                      name="websiteUrl"
                      type="text"
                      value={websiteUrl}
                      onChange={onTextFieldChange}
                      placeholder="ui8.net/Your custom URL"
                      required
                    />
                    <TextArea
                      className={styles.field}
                      label="Bio (Optional)"
                      name="bio"
                      value={bio}
                      onChange={onTextFieldChange}
                      placeholder="About yourselt in a few words"
                      required="required"
                    />
                  </div>
                </div>
               
              </div>
          
              <div className={styles.btns}>
                <button className={cn("button", styles.button)} onClick={()=>{
                  updateprofilefunction()
                }}>
                  Update Profile
                </button>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
