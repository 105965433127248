import React from "react";
import Hero from "./Hero";

// import HotBid from "../../components/HotBid";

const Faq = () => {
  return (
    <div style={{backgroundColor:"#141416",height:""}}>
      <Hero />
      {/* <HotBid classSection="section-pb" /> */}
    </div>
  );
};

export default Faq;
