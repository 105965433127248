import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
// import Group from "./Group";
// import DisabledGroup from "./DisabledGroup";
import Image from "../Image";
// import Form from "../Form";
import Theme from "../Theme";
import logoimage from "../../../src/assets/images/logobluetransparent.png";

// const items = [
//   {
//     title: "The NFT Brewery",
//     menu: [
//       {
//         title: "Discover",
//         url: "/search01",
//       },
//       {
//         title: "Connect wallet",
//         url: "/connect-wallet",
//       },
//     ],
//   },
//   // {
//   //   title: "Info",
//   //   menu: [

//   //     {
//   //       title: "Mint NFT",
//   //       url: "/upload-variants",
//   //     },
//   //   ],
//   // },
// ];

const mintnft = [
  {
    title: "Info",
    menu: [
      // {
      //   title: "FAQ",
      //   url: "/faq",
      // },
      {
        title: "Mint NFT",
        url: "/upload-variants",
      },
    ],
  },
];

const Footers = () => {
  const [email, setEmail] = useState("");
  const [checknft, setchecknft] = useState("false");

  const handleSubmit = (e) => {
    alert();
  };

  useEffect(() => {
    setInterval(async function () {
      //  console.log(typeof localStorage.showhidenft)
      setchecknft(localStorage.showhidenft);
    }, 2000);
  }, []);

  // console.log(localStorage.showhidenft)
  // console.log("rrrrrrrrrrrrrrrrffffffffffffffffffffffhhhhhhhhhhhhhhhhhhhhh")

  return (
    <footer className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            {/* <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src={logoimage}
                srcDark={logoimage}
                // src="/images/logo.jpg"
                // srcDark="/images/logo.jpg"
                alt="Fitness Pro"
              />
            </Link> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className={styles.info}>
                <>
                  Create your own NFT experiences at the click of a button{" "}
                  <br /> Learn More about{" "}
                  <Link
                    style={{ color: "#fff", textDecoration: 'underline' }}
                    target="_blank"
                    to="//thenftbrewery.com"
                  >
                    The NFT Brewery
                  </Link>
                </>
              </div>
              <button className={styles.footerBtn}>
                <h3 style={{ marginBottom: "0" }}>
                  <Link target="_blank" to='//form.typeform.com/to/wNiviw?typeform-source=localhost'>Get Featured with The NFT Brewery Drops</Link>
                </h3>
              </button>
              <span className={styles.desc} style={{ marginTop: "0px" }}>
                <Link
                  style={{ color: "#fff" }}
                  target="_blank"
                  to="//thenftbrewery.com/EventsTermsandConditions.pdf "
                >
                  Terms and Conditions
                </Link>
              </span>
            </div>
            {/* <div className={styles.version}>
              <div className={styles.details}>Dark theme</div>
              <Theme className="theme-big" />
            </div> */}
          </div>
          {/* <div className={styles.col}>
            {items.map((x, index) => (
              <Group className={styles.group} item={x} key={index} />
            ))}
            {checknft === "true" && (
              <>
                {mintnft.map((x, index) => (
                  <Group className={styles.group} item={x} key={index} />
                ))}
              </>
            )}
            {checknft === "false" && (
              <>
                {mintnft.map((x, index) => (
                  <DisabledGroup
                    className={styles.group}
                    item={x}
                    key={index}
                  />
                ))}
              </>
            )}
          </div> */}

          {/* <div className={styles.col}>
            <div className={styles.category}>Join Newsletter</div>
            <div className={styles.text}>
              Subscribe our newsletter to get more free design course and
              resource
            </div>
            <Form
              className={styles.form}
              value={email}
              setValue={setEmail}
              onSubmit={() => handleSubmit()}
              placeholder="Enter your email"
              type="email"
              name="email"
            />
          </div> */}
        </div>
        {/* <div className={styles.foot}>
          <div className={styles.copyright}>
            Copyright © 2021 The NFT Brewery. All rights reserved
          </div>
          <div className={styles.note}>
            We use cookies for better service. <a href="/#">Accept</a>
          </div>
        </div> */}
      </div>
    </footer>
  );
};

export default Footers;
// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import cn from "classnames";
// import styles from "./Footer.module.sass";
// import Group from "./Group";
// import DisabledGroup from "./DisabledGroup";
// import Image from "../Image";
// import Form from "../Form";
// import Theme from "../Theme";
// import logoimage from "../../../src/assets/images/logobluetransparent.png";

// const items = [
//   {
//     title: "The NFT Brewery",
//     menu: [
//       {
//         title: "Discover",
//         url: "/search01",
//       },
//       {
//         title: "Connect wallet",
//         url: "/connect-wallet",
//       },
//     ],
//   },
//   // {
//   //   title: "Info",
//   //   menu: [

//   //     {
//   //       title: "Mint NFT",
//   //       url: "/upload-variants",
//   //     },
//   //   ],
//   // },
// ];

// const mintnft = [
//   {
//     title: "Info",
//     menu: [
//       // {
//       //   title: "FAQ",
//       //   url: "/faq",
//       // },
//       {
//         title: "Mint NFT",
//         url: "/upload-variants",
//       },
//     ],
//   },
// ];

// const Footers = () => {
//   const [email, setEmail] = useState("");
//   const [checknft, setchecknft] = useState("false");

//   const handleSubmit = (e) => {
//     alert();
//   };

//   useEffect(() => {
//     setInterval(async function () {
//       //  console.log(typeof localStorage.showhidenft)
//       setchecknft(localStorage.showhidenft);
//     }, 2000);
//   }, []);

//   // console.log(localStorage.showhidenft)
//   // console.log("rrrrrrrrrrrrrrrrffffffffffffffffffffffhhhhhhhhhhhhhhhhhhhhh")

//   return (
//     <footer className={styles.footer}>
//       <div className={cn("container", styles.container)}>
//         <div className={styles.row}>
//           <div className={styles.col}>
//             <Link className={styles.logo} to="/">
//               <Image
//                 className={styles.pic}
//                 src={logoimage}
//                 srcDark={logoimage}
//                 // src="/images/logo.jpg"
//                 // srcDark="/images/logo.jpg"
//                 alt="Fitness Pro"
//               />
//             </Link>
//             <div className={styles.info}>The New Creative Economy.</div>
//             <div className={styles.version}>
//               <div className={styles.details}>Dark theme</div>
//               <Theme className="theme-big" />
//             </div>
//           </div>
//           <div className={styles.col}>
//             {items.map((x, index) => (
//               <Group className={styles.group} item={x} key={index} />
//             ))}
//             {checknft === "true" && (
//               <>
//                 {mintnft.map((x, index) => (
//                   <Group className={styles.group} item={x} key={index} />
//                 ))}
//               </>
//             )}
//             {checknft === "false" && (
//               <>
//                 {mintnft.map((x, index) => (
//                   <DisabledGroup
//                     className={styles.group}
//                     item={x}
//                     key={index}
//                   />
//                 ))}
//               </>
//             )}
//           </div>

//           {/* <div className={styles.col}>
//             <div className={styles.category}>Join Newsletter</div>
//             <div className={styles.text}>
//               Subscribe our newsletter to get more free design course and
//               resource
//             </div>
//             <Form
//               className={styles.form}
//               value={email}
//               setValue={setEmail}
//               onSubmit={() => handleSubmit()}
//               placeholder="Enter your email"
//               type="email"
//               name="email"
//             />
//           </div> */}
//         </div>
//         <div className={styles.foot}>
//           <div className={styles.copyright}>
//             Copyright © 2021 The NFT Brewery. All rights reserved
//           </div>
//           {/* <div className={styles.note}>
//             We use cookies for better service. <a href="/#">Accept</a>
//           </div> */}
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footers;
