import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./ProfileEdit.module.sass";
import Control from "../../components/Control";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Icon from "../../components/Icon";

const breadcrumbs = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Login",
  },
];

const Forgotpassword = () => {
  const [emailsubmittoggle, setemailsubmittoggle] = useState(true);
  const [otpsubmittoggle,setotpsubmittoggle] = useState(false);
  const [passwordsubmittoggle,setpasswordsubmittoggle]= useState(false);
  const [finalsubmittoggle,setfinalsubmittoggle]= useState(false);

  return (
    <div className={styles.page}>
      {/* <Control className={styles.control} item={breadcrumbs} /> */}
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.top}>
            <h1 className={cn("h2", styles.title)}>Forgot Password</h1>
            {otpsubmittoggle &&
            <div className={styles.info}>
            OTP send to your Register Email{" "}
            {/* <strong>your profile URL</strong> and manage other personal
            settings. */}
          </div>
            }

            {finalsubmittoggle &&
            <div className={styles.info}>
          Successfully Create New Password{" "}
            <strong>you can login </strong> with new password
          </div>
            }
            
          </div>
          <div className={styles.row}>
            {/* <div className={styles.col}>
              <div className={styles.user}>
                <div className={styles.avatar}>
                  <img src="/images/content/avatar-1.jpg" alt="Avatar" />
                </div>
                <div className={styles.details}>
                  <div className={styles.stage}>Profile photo</div>
                  <div className={styles.text}>
                    We recommend an image of at least 400x400. Gifs work too{" "}
                    <span role="img" aria-label="hooray">
                      🙌
                    </span>
                  </div>
                  <div className={styles.file}>
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                    >
                      Upload
                    </button>
                    <input className={styles.load} type="file" />
                  </div>
                </div>
              </div>
            </div> */}
            
            <div className={styles.col}>

              {emailsubmittoggle &&
              <>
              
              
            <div className={styles.list}>
                <div className={styles.item}>
               
                  <div className={styles.fieldset}>
                 
                    
                    <TextInput
                      className={styles.field}
                      label="Email"
                      name="Name"
                      type="text"
                      placeholder="Enter your Email name"
                      required
                    />
                   
                  </div>
                </div>
               
              </div>
             
              <div className={styles.btns}>
              <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={()=>{
                      setemailsubmittoggle(false)
                      setotpsubmittoggle(true)
                    }}
                  >
                    <Icon name="plus-circle" size="16" />
                    <span>Submit</span>
                  </button>
                  
                  
              </div>
              </>}

              {otpsubmittoggle &&
              <>


              <div className={styles.list}>
                <div className={styles.item}>
               
                  <div className={styles.fieldset}>
                 
                    
                    <TextInput
                      className={styles.field}
                      label="OTP"
                      name="Name"
                      type="password"
                      placeholder="Enter Six Digit OTP Number"
                      required
                    />
                   
                  </div>
                </div>
               
              </div>
             
              <div className={styles.btns}>
              <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={()=>{
                      setpasswordsubmittoggle(true)
                      setotpsubmittoggle(false)
                    }}
                  >
                    <Icon name="plus-circle" size="16" />
                    <span>Submit</span>
                  </button>
                  
                  
              </div>
              </>}
              {passwordsubmittoggle &&
              <>


              <div className={styles.list}>
                <div className={styles.item}>
               
                  <div className={styles.fieldset}>
                 
                    
                    <TextInput
                      className={styles.field}
                      label="new password"
                      name="Name"
                      type="password"
                      placeholder="Enter New Password"
                      required
                    />

<TextInput
                      className={styles.field}
                      label="confirm password"
                      name="Name"
                      type="password"
                      placeholder="Enter Confirm Password"
                      required
                    />
                   
                  </div>
                </div>
               
              </div>
             
              <div className={styles.btns}>
              <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={()=>{
                      setpasswordsubmittoggle(false)
                      setfinalsubmittoggle(true)
                    }}
                  >
                    <Icon name="plus-circle" size="16" />
                    <span>Submit</span>
                  </button>
                  
                  
              </div>
              </>}
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
