import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Users from "./Users";
import Control from "./Control";
// import Options from "./Options";
import Web3 from "web3";
import axios from "axios";
import VideoThumbnail from "react-video-thumbnail";
import Modal from "../../components/Modal";
import VideoModal from "../VideoModal";
import mp3image from "../../assets/images/mp3icon.png";
import createWeb3ContractMethodInstance from "../../components/CreateWeb3Instance/Web3instance";
import getinstancesParamter from "../../components/getInstancesParameter/Constant";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import "./mark.css";
import Page from "../../components/Page";

const contractABI = require("../../components/ABI/NFTSalePurchase.json");
const contract = require("../../components/ContractAddress/Contract");
const constant = require("../../components/Constant/Constant");

var apiBaseUrl = constant.getAPiUrl();

// const navLinks = ["Info", "Owners", "History"/**, "Bids" */];
const navLinks = ["Info", "Owners", "History" /**, "Bids" */];

const categories = [
  // {
  //   category: "black",
  //   content: "art",
  // },
  // {
  //   category: "purple",
  //   content: "unlockable",
  // },
];

// const users = {
//   Info: [
//     {
//       name: "Raquel Will",
//       position: "Owner",
//       avatar: "/images/content/avatar-2.jpg",
//       reward: "/images/content/reward-1.svg",
//     },
//     {
//       name: "Selina Mayert",
//       position: "Info",
//       avatar: "/images/content/avatar-1.jpg",
//     },
//   ],
//   Owners: [
//     {
//       name: "Raquel Will",
//       position: "History",
//       avatar: "/images/content/avatar-2.jpg",
//       reward: "/images/content/reward-1.svg",
//     },
//     {
//       name: "Selina Mayert",
//       position: "Creator",
//       avatar: "/images/content/avatar-1.jpg",
//     },
//   ],
//   History: [
//     {
//       name: "Raquel Will",
//       position: "Owner",
//       avatar: "/images/content/avatar-2.jpg",
//       reward: "/images/content/reward-1.svg",
//     },
//     {
//       name: "Selina Mayert",
//       position: "Creator",
//       avatar: "/images/content/avatar-1.jpg",
//     },
//   ],
// };
const Item = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [itemaddress, setitemaddress] = useState("");
  const [itemname, setitemname] = useState("");
  const [itedesc, setitedesc] = useState("");
  const [saleprice, setsaleprice] = useState("");
  const [videomodaltoggle, setvideomodaltoggle] = useState(false);
  const [tokeniddetails, settokeniddetails] = useState();
  const [tokenhistory, settokenhistory] = useState([]);
  const [showmorehide, setshowmorehide] = useState(true);
  const [showunlockurltoggle, setshowunlockurltoggle] = useState(false);
  const [showunlockbutton, setshowunlockbutton] = useState(false);
  const [unlockurl, setunlockurl] = useState("");
  const [unlockurldescription, setunlockurldescription] = useState("");
  const [contractAddress, setcontractAddress] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // const [collectionoftokenId, setcollectionoftokenId] = useState([]);

  // console.log(collectionoftokenId);

  useEffect(() => {
    // getallminttokenasset(80001, 1)
    let itemaddress = localStorage.getItem("Itemvalue");
    let itemname = localStorage.getItem("ItemDetails");
    let itedesc = localStorage.getItem("ItemDesc");
    let uniqueid = localStorage.getItem("_id");

    if (localStorage.CurrentAddress != localStorage.CurrentOwner) {
      const headers = {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      };

      let data = {
        _id: uniqueid,
        networkId: sessionStorage.networkIdParameter,
      };
      axios
        .post(`${apiBaseUrl}/api/user/viewCount/`, data, {
          headers: headers,
        })
        .then((response) => {
          // // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // getAssetDetails();
    gettokeniddetailsfetch();

    setitemaddress(itemaddress);
    setitemname(itemname);
    setitedesc(itedesc);
  }, []);

  const gettokeniddetailsfetch = async () => {
    axios({
      method: "get",

      url: `${apiBaseUrl}/api/user/getTokenIdDetails/${localStorage.PricetokenId}/${sessionStorage.networkIdParameter}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        // console.log(response)
        if (response.data.status == 200) {
          if (response.data.message.address) {
            setcontractAddress(response.data.message.address);
          }
          settokeniddetails(response.data.message.tokenIdDetails);

          if (localStorage.CurrentAddress) {
            unlockpurchasefunction(response.data.message.tokenIdDetails);

            checkPrice();
          }

          if (response.data.message.tokenEventHistory.eventHistory.length) {
            settokenhistory(
              response.data.message.tokenEventHistory.eventHistory.reverse()
            );
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getWeb3Instance = async (
    registrationType,
    blockchaintype,
    networktype,
    erctype
  ) => {
    let contract_Methods = await createWeb3ContractMethodInstance(
      registrationType,
      blockchaintype,
      networktype,
      erctype
    );

    return contract_Methods.contract_Methods;
  };

  const unlockpurchasefunction = async (getobject) => {
    if (getobject.currentOwner == localStorage.CurrentAddress) {
      await window.ethereum.enable();
      const checknetworkId = window.ethereum.networkVersion;

      axios({
        method: "get",
        url: `${apiBaseUrl}/api/user/getUnlockPurchaseDetails/${localStorage.PricetokenId}/${sessionStorage.networkIdParameter}`,
        headers: {
          Authorization: localStorage.token
            ? `Bearer ${localStorage.token}`
            : undefined,
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => {
          if (response.data.message.url) {
            setunlockurl(response.data.message.url);
            setunlockurldescription(response.data.message.description);
            setshowunlockbutton(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  };
  async function load() {
    await loadWeb3();
    window.contract = await loadContract();
  }
  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      window.ethereum.enable();
    }
  }

  async function loadContract() {
    return await new window.web3.eth.Contract(
      contractABI.abi,
      contract.getcontractaddress()
    );
  }

  async function checkPrice() {
    try {
      const getWeb3InstanceParameter = await getinstancesParamter();

      let contractmethod = await getWeb3Instance(
        getWeb3InstanceParameter.martketRegistrationtype,
        getWeb3InstanceParameter.Blockchaintype,
        getWeb3InstanceParameter.networkType,
        getWeb3InstanceParameter.erctype
      );

      const getadminfees = await contractmethod.methods
        .GetAdminConvFees()
        .call();

      localStorage.setItem("adminfees", getadminfees);

      if (localStorage.PricetokenId) {
        const price = await contractmethod.methods
          .TokenPrice(localStorage.PricetokenId)
          .call();

        let gotbalaenc = await window.web3.utils.fromWei(price);
        let orignalvalue = gotbalaenc.slice(0, 6);
        localStorage.setItem("saleprice", orignalvalue);
        setsaleprice(orignalvalue);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const videotoggle = (value) => {
    setvideomodaltoggle(false);
  };

  return (
    <Page>
      <div
        className={cn("section", styles.section)}
        style={{ backgroundColor: "" }}
      >
        <Modal visible={showunlockurltoggle}>
          <div
            onClick={() => {
              setshowunlockurltoggle(false);
            }}
          >
            <center>
              <h3
                style={{ cursor: "pointer", color: "#3772FF" }}
                onClick={() => {
                  window.open(unlockurl);
                }}
              >
                {unlockurl}
              </h3>
            </center>
            <br />
            <center>
              <h4>{unlockurldescription}</h4>
            </center>

            <br />
            <center>
              {" "}
              <h4>
                <span
                  style={{ marginLeft: "", cursor: "pointer" }}
                  onClick={() => {}}
                >
                  OK
                </span>
              </h4>
            </center>

            <div></div>
          </div>
        </Modal>
        <Modal visible={videomodaltoggle}>
          <VideoModal videotoggle={videotoggle} videoimage={itemaddress} />
        </Modal>
        <Modal visible={isLoading}>
          <center>
            {" "}
            <h4>
              <span
                style={{ marginLeft: "", cursor: "pointer" }}
                onClick={() => {}}
              >
                Loading......
              </span>
            </h4>
          </center>
        </Modal>
        <div className={cn("container", styles.container)}>
          <div className={styles.bg}>
            <div className={styles.preview} className="small-preview">
              <div className={styles.categories}>
                {categories.map((x, index) => (
                  <div
                    className={cn(
                      { "status-black": x.category === "black" },
                      { "status-purple": x.category === "purple" },
                      styles.category
                    )}
                    key={index}
                  >
                    {x.content}
                  </div>
                ))}
              </div>
              {localStorage.ItemfileType == "video/mp4" && (
                <div
                  style={{ cursor: "pointer", backgroundColor: "" }}
                  onClick={() => {
                    setvideomodaltoggle(true);
                  }}
                >
                  <center>
                    <VideoThumbnail
                      // videoUrl={"https://gateway.pinata.cloud/ipfs/Qmem4exur6JCXTkJuc4XSYttEzFJg1U6RejgKrot7DNuCB"}
                      videoUrl={itemaddress}
                      // thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                      width={950}
                      height={950}
                    />
                  </center>
                </div>
              )}
              {localStorage.ItemfileType != "video/mp4" &&
                localStorage.ItemfileType != "audio/mpeg" && (
                  <div >
                    <img  className="small-img"
                      srcSet={itemaddress}
                      src="/images/content/item-pic.jpg"
                      alt="Item"
                      // style={{
                      //   maxWidth: "550px",
                      //   maxHeight: "550px",
                      //   width: "550px",
                      //   height: "550px",
                      //   cursor: "pointer",
                      // }}
                    />
                  </div>
                )}
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setvideomodaltoggle(true);
                }}
              >
                {localStorage.ItemfileType == "audio/mpeg" && (
                  <img
                    srcSet={mp3image}
                    src="/images/content/item-pic.jpg"
                    alt="Item"
                    style={{
                      maxWidth: "550px",
                      maxHeight: "550px",
                      width: "550px",
                      height: "550px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              <Control className={styles.control} itemname={itemname} className="small-control" />
              {tokeniddetails?.isPutOnSell && (
                <div
                  className="about"
                  style={{ marginTop: "32px", padding: "0px 0px" }}
                >
                  <h1>{"This NFT includes:"}</h1>
                  <Row>
                    <Col>
                      The holder of the NFT will be able to enjoy with one of
                      the best Spanish pilots and racing car drivers, a
                      completely different kind of experience, a one-of-a-kind,
                      exhilarating, high speed experience at a racing circuit.
                      Feel the speed for yourself while riding along with Dani
                      Clos in his own race car. And once you are done… you will
                      have the chance to talk about cars, fashion, sports and
                      travel while sharing a meal with him.
                    </Col>
                    <br />
                    <h2>{"*This NFT allows its holder to:"}</h2>
                    <Col>
                      - Enjoy a 3h with ride in{" "}
                      <Link
                        className={styles.site}
                        // to={`//${websiteurl}`}
                        target="_blank"
                        // rel="noopener noreferrer"
                        to="//circuitparcmotor.com/es/es/"
                      >
                        Parc Motor circuit
                      </Link>{" "}
                      in his race car.
                    </Col>
                    <Col>- Lunch with Dani Clos</Col>
                    <br />
                    <h2>{"Not included:"}</h2>
                    <Col>• Travel to and from the circuit</Col>
                    <Col>
                      *The availability of the experience is subject to Dani
                      Clos’ availability.{" "}
                    </Col>
                    <br />
                    <h2>{"About the NFT Brewery:"}</h2>
                    <Col>
                      The NFT Brewery provides the easiest way for companies,
                      creators, and influencers to adopt NFTs and create unique
                      experiences for their communities. We provide the
                      expertise for our customers to boldly embrace Web3
                      technology and create new engagement channels for a new
                      generation.
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
          {!tokeniddetails?.isPutOnSell && (
            <div className={styles.details}>
              <h1 className={cn("h3", styles.title)}>{itemname}</h1>

              <div className={styles.cost}>
                {showunlockbutton && (
                  <div
                    className={cn("status-stroke-green", styles.price)}
                    style={{ marginLeft: "35%", cursor: "pointer" }}
                    onClick={() => {
                      setshowunlockurltoggle(true);
                    }}
                  >
                    <span>unlock</span>
                  </div>
                )}
              </div>
              <div className={styles.info}>
                {showmorehide && (
                  <>
                    {itedesc.length > 100 ? (
                      <>
                        {itedesc.slice(0, 100)}...{" "}
                        <span
                          style={{ color: "white", cursor: "pointer" }}
                          onClick={() => {
                            setshowmorehide(false);
                          }}
                        >
                          see more
                        </span>
                      </>
                    ) : (
                      <>{itedesc.slice(0, 100)}</>
                    )}
                  </>
                )}
                {!showmorehide && (
                  <>
                    {itedesc}...{" "}
                    <span
                      style={{ color: "white", cursor: "pointer" }}
                      onClick={() => {
                        setshowmorehide(true);
                      }}
                    >
                      see less
                    </span>
                  </>
                )}
              </div>
              <div className={styles.nav}>
                {navLinks.map((x, index) => (
                  <button
                    className={cn(
                      { [styles.active]: index === activeIndex },
                      styles.link
                    )}
                    onClick={() => setActiveIndex(index)}
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div>

              <Users
                className={styles.users}
                items={activeIndex}
                tokenhistory={tokenhistory}
                tokendetails={tokeniddetails}
                contractaddress={contractAddress}
                tokenid={localStorage.PricetokenId}
              />
            </div>
          )}
          {tokeniddetails?.isPutOnSell && (
            <div className={styles.details}>
              <h1 className={cn("h3", styles.title)}>
                A high-speed experience on the circuit with <br /> Dani Clos
              </h1>
              <Row>
                <Col>Edition of 1.</Col>
                <Col>
                  A Unique Opportunity to spend a day with the Maestro himself
                  and his supercars.
                </Col>
                <Col>
                  <strong>Created By:</strong> The NFT Brewery for Dani Clos
                </Col>
                <Col>Released: April 1, 2022</Col>
                <Col className={styles.mrgb}>Type: Experience</Col>
                <Col className={styles.mrgb}>
                  <strong>About Dani Clos:</strong> Daniel Clos Álvarez (born 23
                  October 1988) is a Spanish professional racecar driver who was
                  the test driver for the Williams F1 and a reserve driver for
                  the HRT Formula One teams.
                </Col>
                <Col className={styles.mrgb}>
                  Dani's career began in 1997, and right off the start he
                  achieved several victories in Formula Junior 1.6 in his
                  Spanish homeland – Dani was the Catalan champion, Spanish
                  champion, champion of champion and world champion in Karting
                  junior category
                </Col>
                <Col className={styles.mrgb}>
                  "All my life I wanted to be a racing driver. Although my dream
                  came true, I continued to work hard to become a top driver.
                  Racing is my passion, and driving is my life!”{" "}
                </Col>
                <Col className={styles.mrgb}>
                  As Renault 2.0 Italian Champion in 2006 he won 12 races in a
                  row in between European and Italian championship. In 2008, he
                  was a test driver for the Williams F1 team - the second
                  youngest driver to make his Formula 1 debut (2012: 3rd driver
                  in HRT F1 team). In addition to successful engagements in
                  Formula 1, race winner in GP2 championship (2012: 4th in
                  championship) and the international GT and Endurance series,
                  Dani has also discovered his talent as a coach for racing
                  drivers and as a TV and radio commentator.
                </Col>
                <Col className={styles.mrgb}>
                  Dani, born in Barcelona, was a successful karter at the ICA
                  Junior level of karting, winning the Catalan district of the
                  Spanish ICA Junior Championship in 2001. In 2002, Dani won the
                  Copa Campeones Trophy and finished 10th in the Italian Open
                  Masters. Having tested for both GP2 Series teams and ones in
                  the Formula Renault 3.5 Series, Clos was signed by the Racing
                  Engineering team for the 2009 - 2011 GP2 Series, winning the
                  2010 Turkish GP2 round in Istanbul and then raced for Barwa
                  Addax Team in 2012 and MP Motorsport in 2013.{" "}
                </Col>
                <Col className={styles.mrgb}>
                  Dani first came to Formula One as a test driver for Williams,
                  back in September of 2008. He moved his attention to the HRT
                  team, who employed him for the Abu Dhabi Young Driver Test in
                  2011, and then went on to give him the reserve driver role for
                  the following season.
                </Col>
                <Col className={styles.mrgb}>
                  Dani made his first appearance in the F1 paddock with the HRT
                  team, participating in several Friday Free Practice sessions
                  and the Young Driver testing with them.
                </Col>
                <Col className={styles.mrgb}>
                  In the meantime Dani is not only a successful racing driver,
                  but also has become the biggest Spanish car influencer. With
                  nearly 1 million followers on his Youtube channel and over
                  380,000 followers on Instagram, he is clearly one of the
                  social stars of the scene.
                </Col>
                <Col>
                  <strong>Social Links: </strong>
                </Col>
                <Col>
                  Follow Dani Clos on your favorite social media channel
                  including{" "}
                  <Link
                    className={styles.site}
                    // to={`//${websiteurl}`}
                    target="_blank"
                    // rel="noopener noreferrer"
                    to="//www.youtube.com/channel/UCigXhxKUIQr9_xJPIHArPiw"
                  >
                    Youtube,
                  </Link>{" "}
                  <Link
                    className={styles.site}
                    // to={`//${websiteurl}`}
                    target="_blank"
                    // rel="noopener noreferrer"
                    to="//www.instagram.com/daniclos_oficial/"
                  >
                    {" "}
                    Instagram,
                  </Link>{" "}
                  <Link
                    className={styles.site}
                    // to={`//${websiteurl}`}
                    target="_blank"
                    // rel="noopener noreferrer"
                    to="//m.facebook.com/DANICLOS/?_rdr"
                  >
                    {" "}
                    Facebook,
                  </Link>{" "}
                  and
                  <Link
                    className={styles.site}
                    // to={`//${websiteurl}`}
                    target="_blank"
                    // rel="noopener noreferrer"
                    to="//www.twitter.com"
                  >
                    {" "}
                    Twitter
                  </Link>{" "}
                </Col>
                <Col>
                  Follow The NFT Brewery on the{" "}
                  <Link to="//thenftbrewery.com/" target="_blank">
                    Web
                  </Link>{" "}
                  , Twitter and{" "}
                  <Link
                    to="//www.linkedin.com/company/the-nft-brewery/"
                    target="_blank"
                  >
                    {/* <Link to="//www.linkedin.com/checkpoint/challengesV2/AQG_9n_3suWrowAAAX_jxShHUqNl2sIAMh08o00iwzb2F3YnBzCgciyUAMxB5yXPwGROAd2WQS5lZPc71kRzmpq_kf_1BCE36w" target="_blank"> */}
                    LinkedIn
                  </Link>{" "}
                  to keep up with the latest NFT experiences.{" "}
                </Col>
              </Row>
            </div>
          )}
          {tokeniddetails?.isPutOnSell && (
            <div
              className="about-small"
              style={{ marginTop: "32px", padding: "0px 0px" }}
            >
              <h1>{"This NFT includes:"}</h1>
              <Row>
                <Col>
                  The holder of the NFT will be able to enjoy with one of the
                  best Spanish pilots and racing car drivers, a completely
                  different kind of experience, a one-of-a-kind, exhilarating,
                  high speed experience at a racing circuit. Feel the speed for
                  yourself while riding along with Dani Clos in his own race
                  car. And once you are done… you will have the chance to talk
                  about cars, fashion, sports and travel while sharing a meal
                  with him.
                </Col>
                <br />
                <h2>{"*This NFT allows its holder to:"}</h2>
                <Col>
                  - Enjoy a 3h with ride in{" "}
                  <Link
                    className={styles.site}
                    // to={`//${websiteurl}`}
                    target="_blank"
                    // rel="noopener noreferrer"
                    to="//circuitparcmotor.com/es/es/"
                  >
                    Parc Motor circuit
                  </Link>{" "}
                  in his race car.
                </Col>
                <Col>- Lunch with Dani Clos</Col>
                <br />
                <h2>{"Not included:"}</h2>
                <Col>• Travel to and from the circuit</Col>
                <Col>
                  *The availability of the experience is subject to Dani Clos’
                  availability.{" "}
                </Col>
                <br />
                <h2>{"About the NFT Brewery:"}</h2>
                <Col>
                  The NFT Brewery provides the easiest way for companies,
                  creators, and influencers to adopt NFTs and create unique
                  experiences for their communities. We provide the expertise
                  for our customers to boldly embrace Web3 technology and create
                  new engagement channels for a new generation.
                </Col>
              </Row>
            </div>
          )}
        </div>

        {/* {tokeniddetails?.isPutOnSell && (
          <div style={{ marginTop: "32px", padding: "0px 80px" }}>
            <h1>{"This NFT includes:"}</h1>
            <Row>
              <Col>
                The holder of the NFT will be able to enjoy with one of the best
                Spanish pilots and racing car drivers, a completely different
                kind of experience, a one-of-a-kind, exhilarating, high speed
                experience at a racing circuit. Feel the speed for yourself
                while riding along with Dani Clos in his own race car. And once
                you are done… you will have the chance to talk about cars,
                fashion, sports and travel while sharing a meal with him.
              </Col>
              <br />
              <h2>{"*This NFT allows its holder to:"}</h2>
              <Col>
                - Enjoy a 3h with ride in{" "}
                <Link
                  className={styles.site}
                  // to={`//${websiteurl}`}
                  target="_blank"
                  // rel="noopener noreferrer"
                  to="//circuitparcmotor.com/es/es/"
                >
                  Parc Motor circuit
                </Link>{" "}
                in his race car.
              </Col>
              <Col>- Lunch with Dani Clos</Col>
              <br />
              <h2>{"Not included:"}</h2>
              <Col>• Travel to and from the circuit</Col>
              <Col>
                *The availability of the experience is subject to Dani Clos’
                availability.{" "}
              </Col>
              <br />
              <h2>{"About the NFT Brewery:"}</h2>
              <Col>
                The NFT Brewery provides the easiest way for companies,
                creators, and influencers to adopt NFTs and create unique
                experiences for their communities. We provide the expertise for
                our customers to boldly embrace Web3 technology and create new
                engagement channels for a new generation.
              </Col>
            </Row>
          </div>
        )} */}
      </div>
    </Page>
  );
};

export default Item;
