import React, { Fragment } from "react";
import { Card } from "antd";
import { useHistory } from "react-router-dom";
import eth from "../../../../assets/images/eth.webp";

const FeaturedCard = (props) => {
  const { collectionName, assetPrice, jsonDetails, _id } = props;
  const history = useHistory();

  return (
    <Fragment>
        <div className="featuredCard">
          <Card
            onClick={() => history.push(`/assetPage/${_id}`)}
            className="card"
            style={{ width: "100%" }}
          >
            <div className="img-container">
              <div className="img-box">
                <div className="img">
                  <img src={jsonDetails[0]?.jsonDetails?.image} alt="" />
                </div>
              </div>
            </div>
            <div className="content">
              <h3>
                <strong>{collectionName}</strong>
              </h3>
              {/* <p>{jsonDetails[0]?.jsonDetails?.description}</p> */}
              <h2>
                <strong>
                  {assetPrice}
                  <img
                    className="priceCard-eth-img"
                    style={{ marginRight: "10px" }}
                    src={eth}
                    alt=""
                  />
                </strong>
              </h2>
            </div>
          </Card>
        </div>
    </Fragment>
  );
};

export default FeaturedCard;
