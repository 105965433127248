import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Discover.module.sass";
import { Range, getTrackBackground } from "react-range";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import Card from "../../../components/HomeCard";
import Dropdown from "../../../components/Dropdown";
import SelectRangeDropdown from "../SelectRangeDropdown";
import SelectAllRangeDropdown from "../SelectAllRangeDropdown";
import SelectNetworkDropdown from "../SelectNetworkDropdown";
import Modal from "../../../components/Modal";
import Loader from "../../../components/Loader";
// import { Spin } from "antd";

import axios from "axios";
// import Web3 from "web3";
// import createWeb3ContractMethodInstance from "../../../components/CreateWeb3Instance/Web3instance";
// import getinstancesParamter from "../../../components/getInstancesParameter/Constant";
const constant = require("../../../components/Constant/Constant");

var apiBaseUrl = constant.getAPiUrl();
const navLinks = ["All items", "Photography", "Music", "Video"];
const networkIdoption = [
  "Polygon Test Network",
  "Ropsten Test Network",
  "Mainnet",
];
const dateOptions = [
  "Default",
  "Recently Listed",
  "Recently Sold",
  "Price Low to High",
  "Price High to Low",
  "Most Viewed",
  "Oldest First",
];

const priceOptions = ["Default", "Price Low to High", "Price High to Low"];
const likesOptions = ["Most liked", "Least liked"];

const sortingOptions = [];
navLinks.map((x) => sortingOptions.push(x));

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Discover = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectnetworkId, setselectnetworkId] = useState(networkIdoption[0]);
  const [date, setDate] = useState("Default");
  const [price, setPrice] = useState("Default");
  const [likes, setLikes] = useState(likesOptions[0]);
  const [sorting, setSorting] = useState(sortingOptions[0]);
  const [arrayofsalevalue, setarrayofsalevalue] = useState([]);
  const [values, setValues] = useState([100]);
  const [visible, setVisible] = useState(false);

  const [nottokenmessage, setnottokenmessage] = useState("");
  const [tokenexpiredtoggle, settokenexpiredtoggle] = useState(false);

  const [arrayofpush, setarrayofpush] = useState([]);
  const [loadmoretoggle, setloadmoretoggle] = useState(false);
  const [filterarrayofvalue, setfilterarrayofvalue] = useState([]);
  const [assendingdesendingorder, setassendingdesendingorder] = useState([]);
  const [dataloadingtoggle, setdataloadingtoggle] = useState(true);
  const [showloading, setshowloading] = useState(false);
  const [collectionoftokenId, setcollectionoftokenId] = useState([]);
  const [pageNumberValue, setpageNumberValue] = useState(1);
  const [networkIdParameter, setnetworkIdParameter] = useState(80001);
  const [filterCopyValue, setfilterCopyValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // console.log(collectionoftokenId)
  const STEP = 0.1;
  const MIN = 0.1;
  const MAX = 100;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 100000,
        settings: "unslick",
      },
    ],
  };

  useEffect(() => {
    //calling getallminttokenasset function for passing networkid and number of asset to show
    getallminttokenasset(networkIdParameter, pageNumberValue);
  }, []);

  const oncheckNetworkfunction = (value) => {
    if (value == "Ropsten Test Network") {
      setnetworkIdParameter(3);
      getallminttokenasset(3, pageNumberValue);
    } else if (value == "Polygon Test Network") {
      getallminttokenasset(80001, pageNumberValue);
      setnetworkIdParameter(80001);
    } else if (value == "Mainnet") {
      getallminttokenasset(1, pageNumberValue);
      setnetworkIdParameter(1);
    }
  };

  //using allTokens api for getting number of token asset
  const getallminttokenasset = async (inputvalue, pageNumberValue) => {
    let url = "";
    setnottokenmessage(nottokenmessage == "");
    setActiveIndex(0);
    setDate("Default");

    setcollectionoftokenId([]);
    setfilterCopyValue([]);

    setloadmoretoggle(false);

    axios({
      method: "get",
      url: `${apiBaseUrl}/api/user/allTokens/${inputvalue}/${pageNumberValue}/10`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        // setIsLoading(true);
        if (response.data.status == 200) {
          if (response.data.data.length) {
            setcollectionoftokenId(response.data.data);
            console.log(response.data.data);
            // localStorage.setItem('assestTokenId', response.data.data[0].tokenId)
            // sessionStorage.setItem(
            //   "networkIdParameter",
            //   networkIdParameter
            // );
            // sessionStorage.setItem("checkStatus", response.data.data[0].checkStatus);
            // sessionStorage.setItem("checkStatusPrice", response.data.data[0].price);
            // localStorage.setItem("Itemvalue", response.data.data[0].image);
            // localStorage.setItem("ItemDetails", response.data.data[0].name);
            // localStorage.setItem("ItemDesc", response.data.data[0].description);
            // localStorage.setItem("PricetokenId", response.data.data[0].tokenId);
            // localStorage.setItem("_id", response.data.data[0]._id);
            // localStorage.setItem("CurrentOwner", response.data.data[0].currentOwner);
            // localStorage.setItem("ItemfileType", response.data.data[0].assettype);


            //calling function for convert pinata uri to json object
            getUriToJsonfunction(response.data.data, inputvalue);
            setloadmoretoggle(true);
          }
        } else if (response.data.status == 404) {
          setnottokenmessage("No NFT items are available");
        }
        // if(collectionoftokenId){
        //     sessionStorage.setItem("checkStatus", collectionoftokenId[0].checkStatus);
        //     sessionStorage.setItem("checkStatusPrice",collectionoftokenId[0].price);
        //     localStorage.setItem("Itemvalue", collectionoftokenId[0].image);
        //     localStorage.setItem("ItemDetails", collectionoftokenId[0].name);
        //     localStorage.setItem("ItemDesc", collectionoftokenId[0].description);
        //     localStorage.setItem("PricetokenId", collectionoftokenId[0].tokenId);
        //     localStorage.setItem("_id", collectionoftokenId[0]._id);
        //     localStorage.setItem("CurrentOwner", collectionoftokenId[0].currentOwner);
        //     localStorage.setItem("ItemfileType", collectionoftokenId[0].assettype);
        // }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.message == "Request failed with status code 400") {
          settokenexpiredtoggle(true);
        } else {
          setnottokenmessage("No NFT items are Available");
        }
        setIsLoading(false);
      });
  };

  async function getUriToJsonfunction(arrayvalue, networkId) {
    let copyofFilterValue = [];

    for (const value of arrayvalue) {
      value.filterprice = +value.price;
      value.putonsalestatus = value.checkStatus;

      let getValue = await getJsonFromApi(value);

      getValue.networkId = networkId;
      if (getValue.data) {
        getValue.createdAt = getValue.data.createdAt;
        getValue.creator = getValue.data.creator;
        getValue.currentOwner = getValue.data.currentOwner;
        getValue.isPutOnSell = getValue.data.isPutOnSell;
        getValue.isSold = getValue.data.isSold;
        getValue.unlockOncePurchased = getValue.data.unlockOncePurchased;
        getValue.updatedAt = getValue.data.updatedAt;
        getValue.viewCount = getValue.data.viewCount;
        getValue._id = getValue.data._id;
      }
      copyofFilterValue.push(getValue);
    }

    setfilterCopyValue(copyofFilterValue);
    setassendingdesendingorder(copyofFilterValue);
    setfilterarrayofvalue(copyofFilterValue);
  }

  async function getJsonFromApi(details) {
    try {
      //fetch the data from uri
      const response = await fetch(details.uri, { mode: "cors" });

      //getting response in json object
      const data = await response.json();

      if (data) {
        details.assettype = data.assettype;
        details.attributes = data.attributes;
        details.description = data.description;
        details.external_url = data.external_url;
        details.image = data.image;
        details.name = data.name;

        filterCopyValue.push(details);

        data.tokenId = details.tokenId;
        data.checkStatus = details.checkStatus;
        data.price = details.price;
        data.uri = details.uri;

        return details;
      }
    } catch (e) {
      console.log(e);
    }
  }

  //filter the asset regarding to price,latest update and most viewed
  const rangefunction = (e) => {
    setValues(e);
    let getrange = e[0];

    let checkputonsale = assendingdesendingorder.filter((value) => {
      return value.checkStatus == true && networkIdParameter == value.networkId;
    });

    let filtervalue = checkputonsale.filter((value) => {
      return value.price <= getrange;
    });

    setcollectionoftokenId(filtervalue);
  };

  const resetfilter = () => {
    setValues([100]);
    setActiveIndex(0);
    setcollectionoftokenId(assendingdesendingorder);
  };

  //for filter assets
  const settypefunction = (index) => {
    setDate("Default");
    setActiveIndex(index);

    if (index == 0) {
      let filtervalue = filterCopyValue.filter((value) => {
        return value.networkId == networkIdParameter;
      });

      if (filtervalue.length) {
        setcollectionoftokenId(filtervalue);
        setnottokenmessage("");
      } else {
        setcollectionoftokenId([]);
        setnottokenmessage("No NFT items are Available");
      }
    } else if (index == 1) {
      let filtervalue = filterCopyValue.filter((value) => {
        return (
          value.assettype == "image/jpeg" &&
          value.networkId == networkIdParameter
        );
      });

      if (filtervalue.length) {
        setcollectionoftokenId(filtervalue);
        setnottokenmessage("");
      } else {
        setcollectionoftokenId([]);
        setnottokenmessage("No NFT items are Available");
      }
    } else if (index == 2) {
      let filtervalue = filterCopyValue.filter((value) => {
        return (
          value.assettype == "audio/mpeg" &&
          value.networkId == networkIdParameter
        );
      });

      if (filtervalue.length) {
        setcollectionoftokenId(filtervalue);
        setnottokenmessage("");
      } else {
        setcollectionoftokenId([]);
        setnottokenmessage("No NFT items are Available");
      }
    } else if (index == 3) {
      let filtervalue = filterCopyValue.filter((value) => {
        return (
          value.assettype == "video/mp4" &&
          value.networkId == networkIdParameter
        );
      });

      if (filtervalue.length) {
        setcollectionoftokenId(filtervalue);
        setnottokenmessage("");
      } else {
        setcollectionoftokenId([]);
        setnottokenmessage("No NFT items are Available");
      }
    }
  };

  const Network = (value) => {
    setarrayofsalevalue(value);
    setActiveIndex(-1);
  };

  const ondefaultcheckfunction = (value) => {
    setnottokenmessage("");
    setarrayofsalevalue(value);
    setActiveIndex(-1);
  };

  const oncheckfunction = (value) => {
    setnottokenmessage("");

    setcollectionoftokenId(value);
    setActiveIndex(-1);
  };
  return (
    <div
      className={cn("section", styles.section)}
      style={{ marginTop: "-25%" }}
    >
      {/* <Modal visible={isLoading}>
        <center>
          <h4>Loading Data..</h4>
        </center>
      </Modal> */}
      <Modal visible={showloading}>
        <div>
          <center>
            <h4>Loading...</h4>
          </center>
          <br />

          <center>
            <h4>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setshowloading(false);
                }}
              >
                OK
              </span>{" "}
            </h4>
          </center>

          <div></div>
        </div>
      </Modal>
      <div className={cn("container", styles.container)}>
        <div className={styles.top} style={{ backgroundColor: "" }}>
          <h3 className={cn("h3", styles.title)}>Collections</h3>
          <div className={styles.dropdown} style={{ backgroundColor: "" }}>
            <SelectNetworkDropdown
              className={styles.dropdown}
              value={selectnetworkId}
              setValue={setselectnetworkId}
              options={networkIdoption}
              oncheckNetworkfunction={oncheckNetworkfunction}
            />
          </div>
        </div>
        {dataloadingtoggle && (
          <div
            className={styles.top}
            style={{ backgroundColor: "", marginTop: "-6%" }}
          >
            <div className={styles.dropdown} style={{ backgroundColor: "" }}>
              <SelectAllRangeDropdown
                className={styles.dropdown}
                value={date}
                setValue={setDate}
                options={dateOptions}
                sortingarray={assendingdesendingorder}
                networkIdParameter={networkIdParameter}
                filterarrayofvalue={filterarrayofvalue}
                Network={Network}
                ondefaultcheckfunction={ondefaultcheckfunction}
                oncheckfunction={oncheckfunction}
              />
            </div>

            <div className={styles.nav}>
              {navLinks.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => settypefunction(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>

            <div className={cn("tablet-show", styles.dropdown)}>
              <Dropdown
                className={styles.dropdown}
                value={sorting}
                setValue={setSorting}
                options={sortingOptions}
              />
            </div>
            <button
              className={cn(styles.filter, { [styles.active]: visible })}
              onClick={() => setVisible(!visible)}
            >
              <div className={styles.text}>Filter</div>
              <div className={styles.toggle}>
                <Icon name="filter" size="18" />
                <Icon name="close" size="10" />
              </div>
            </button>
          </div>
        )}
        <div className={cn(styles.filters, { [styles.active]: visible })}>
          <div className={styles.sorting}>
            <div className={styles.cell}>
              <div className={styles.label}>Price</div>
              <br />
              <SelectRangeDropdown
                className={styles.dropdown}
                value={price}
                setValue={setPrice}
                options={priceOptions}
                sortingarray={assendingdesendingorder}
                filterarrayofvalue={filterarrayofvalue}
                networkIdParameter={networkIdParameter}
                filterCopyValue={filterCopyValue}
                Network={Network}
                ondefaultcheckfunction={ondefaultcheckfunction}
                oncheckfunction={oncheckfunction}
              />
            </div>
            <div className={styles.cell}>
              <div className={styles.label}>likes</div>
              <br />
              <Dropdown
                className={styles.dropdown}
                value={likes}
                setValue={setLikes}
                options={likesOptions}
              />
            </div>

            <div className={styles.cell}>
              <div className={styles.label}>Price range</div>
              <br />
              <Range
                values={values}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={(e) => {
                  rangefunction(e);
                }}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: "27px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: "8px",
                        width: "100%",
                        borderRadius: "4px",
                        background: getTrackBackground({
                          values,
                          colors: ["#3772FF", "#E6E8EC"],
                          min: MIN,
                          max: MAX,
                        }),
                        alignSelf: "center",
                      }}
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "24px",
                      width: "24px",
                      borderRadius: "50%",
                      backgroundColor: "#3772FF",
                      border: "4px solid #FCFCFD",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-33px",
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontFamily: "Poppins",
                        padding: "4px 8px",
                        borderRadius: "8px",
                        backgroundColor: "#141416",
                      }}
                    >
                      {values[0].toFixed(1)}
                    </div>
                  </div>
                )}
              />
              <div className={styles.scale}>
                <div className={styles.number}>0.1 ETH</div>
                <div className={styles.number}>100 ETH</div>
              </div>
            </div>
            <div className={styles.cell}>
              <div className={styles.reset} onClick={resetfilter}>
                <div className={styles.label}>
                  <Icon name="close-circle-fill" size="24" /> Reset Filter
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Spin loading={isLoading}> */}
        <div className={styles.list}>
          {isLoading ? (
            <div className={styles.Loaderclasses}>

            <Loader />
            </div>
          ) : (
            <Slider
              className={cn("discover-slider", styles.slider)}
              {...settings}
            >
              {/* {!isLoading && collectionoftokenId.length === 0 && <strong>No NFT items are available</strong>} */}
              {collectionoftokenId.length > 0 &&
                collectionoftokenId.map((x, index) => (
                  <>
                    <Card
                      className={styles.card}
                      item={x}
                      key={index}
                      networkIdParameter={networkIdParameter}
                      pricevalue={x.price}
                    />
                  </>
                ))}
            </Slider>
          )}
        </div>
        {/* </Spin> */}
        {/* <h4 className={cn("h4", styles.title)} style={{ marginTop: "5%" }}>
          {nottokenmessage}
        </h4> */}

        <div className={styles.btns}>
          {pageNumberValue > 1 && nottokenmessage == "" && (
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={() => {
                setpageNumberValue(pageNumberValue - 1);
                getallminttokenasset(networkIdParameter, pageNumberValue - 1);
              }}
            >
              <span>Back</span>
            </button>
          )}{" "}
          {loadmoretoggle && nottokenmessage == "" && (
            <button
              className={cn("button-stroke button-small", styles.button)}
              onClick={() => {
                setpageNumberValue(pageNumberValue + 1);
                getallminttokenasset(networkIdParameter, pageNumberValue + 1);
              }}
            >
              <span>Next</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Discover;
