import React, { useState,useEffect } from "react";
import cn from "classnames";
import ReactPlayer from "react-player";



 const Videoupload = (props)=>{
     console.log(props.videoimage)
    return (
        <>

        <div style={{marginTop:"-3%"}}>
           <ReactPlayer
              playing={true}
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              onContextMenu={(e) => e.preventDefault()}
            //   url={"https://gateway.pinata.cloud/ipfs/Qmem4exur6JCXTkJuc4XSYttEzFJg1U6RejgKrot7DNuCB"}
            url={props.videoimage}
              className="react-player"
              controls
              width="100%"
              height="100%"
            />
            </div>
            <br/>

<center><h4><span style={{marginLeft:"",cursor:"pointer"}}  onClick={()=>{
   props.videotoggle(false)
  }}>Cancel</span></h4></center>
        </>



    )
}

export default Videoupload