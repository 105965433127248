import React, { useState,useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Icon from "../Icon";
import axios from "axios";
import Web3 from "web3";
import createWeb3ContractMethodInstance from "../../components/CreateWeb3Instance/Web3instance"
import getinstancesParamter  from "../../components/getInstancesParameter/Constant"
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
 import VideoThumbnail from "react-video-thumbnail";
 import mp3image from "../../assets/images/mp3icon.png"
 import moment from "moment" 
const constant = require("../../components/Constant/Constant")
 const contract = require("../../components/ContractAddress/Contract")
 var apiBaseUrl = constant.getAPiUrl();

const Card = ({ className, item }) => {
  
  const [modaltoggle,setmodaltoggle]= useState(false);
  const [accountaddress,setaccountaddress] = useState("")
  const [priceforsale,setpriceforsale]= useState("")
  const [blockloader,setblockloader]=useState(false)
  const [alertmessage,setalertmessage]=useState("")
  const [tranfertoken,settranfertoken]=useState("Sold")
  const [objectValue,setobjectValue] = useState();  

  useEffect(() => {
        //call the function for getting the pinata url for fecting the json data
    getcollectionData()

    //get accountaddress from local Storage
    let accountaddress = localStorage.getItem("CurrentAddress")
    if(accountaddress){
      setaccountaddress(accountaddress)
    }  


  })


   //for getting the token id Details using this function

 const  checkstatussoldapi = async ()=>{
  await window.ethereum.enable();
  const checknetworkId = window.ethereum.networkVersion
    axios({
      method: 'get',
       
       url: `${apiBaseUrl}/api/user/getTokenIdDetails/${item}/${checknetworkId}`,
      headers: {
        "Authorization":localStorage.token ? `Bearer ${localStorage.token}` : undefined,
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
  
  })
  .then((response)=>{
 
    if(response.data.message.tokenEventHistory.eventHistory.length){
      let arrayofhistory = response.data.message.tokenEventHistory.eventHistory
     

let checktransfer = arrayofhistory[arrayofhistory.length - 1]



if(checktransfer.action == "Transfer"){

settranfertoken("transfer")
}
else{

console.log("not tranfer")
}

      
    }
  })
  .catch((error)=>{
    console.log(error)
  })

  }

  const getcollectionData = async ()=>{

    const getWeb3InstanceParameter = await getinstancesParamter();

  
         //calling and creating run time instance for block chain method
      let contractmethod = await (getWeb3Instance(getWeb3InstanceParameter.nftRegistrationtype,getWeb3InstanceParameter.Blockchaintype,getWeb3InstanceParameter.networkType,getWeb3InstanceParameter.erctype))
       

       let marketcontractmethod = await (getWeb3Instance(getWeb3InstanceParameter.martketRegistrationtype,getWeb3InstanceParameter.Blockchaintype,getWeb3InstanceParameter.networkType,getWeb3InstanceParameter.erctype))

//check status wheater asset is putonsale or not
       let checkstatus =  await marketcontractmethod.methods.checkTokenStatus(item).call()
          
       
       let accountaddress = localStorage.getItem("CurrentAddress")
 
//filter only putonsale item 
       if(checkstatus){
        const tokenURI = await contractmethod.methods.tokenURI(item).call();
    
        // let makeobject = await JSON.parse(decodeURIComponent(tokenURI))
        let makeobject = tokenURI
  
        makeAPICall(makeobject,item,checkstatus,accountaddress);

       }
   
        
        
  }
  
//pinata api call and fetching the url data in json format
  const makeAPICall = async (pinatapath,value,checkstatus,accountaddress) => {
    try {
      const response = await fetch(pinatapath, {mode:'cors'});
 
    
      const data = await response.json();
     
      if(data) {

  
        const getWeb3InstanceParameter = await getinstancesParamter();
        let contractmethod = await (getWeb3Instance(getWeb3InstanceParameter.nftRegistrationtype,getWeb3InstanceParameter.Blockchaintype,getWeb3InstanceParameter.networkType,getWeb3InstanceParameter.erctype))


        let owneraddress = await contractmethod.methods.ownerOf(value).call()
        
     
      if(owneraddress.toLowerCase() == accountaddress){
      
        data.tokenId = value
        data.status = checkstatus
        data.tokensold = false
     
      }

      else{
   

        data.tokenId = value
        data.status = checkstatus
        data.tokensold = true
        checkstatussoldapi()
      }
  

      setobjectValue(data)
          
          
      
      }
     
      
    }
    catch (e) {
      console.log(e)
    }
    
  }


  //get smartcontract Address
  const getaddressofMarketFunction = async(registrationType,blockchaintype,networktype,erctype)=>{
    let getresponse = await  axios({
      method: 'get',
      url: `${apiBaseUrl}/api/admin/getSmartContractAddress/${registrationType}/${blockchaintype}/${networktype}/${erctype}`,
      headers: {
        "Authorization":localStorage.token ? `Bearer ${localStorage.token}` : undefined,
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
  
  }) 
  
  .catch((error)=>{
    console.log(error)
  })
 
  return getresponse.data.data[0]

  }
  const getWeb3Instance = async (registrationType,blockchaintype,networktype,erctype)=>{
   
    let contract_Methods = await createWeb3ContractMethodInstance(registrationType,blockchaintype,networktype,erctype);
 
    
    return contract_Methods.contract_Methods
    
    }  

  const submitfunction = async ()=>{    
    try{

let numberofpricevalue = +priceforsale
      if(numberofpricevalue > 0){
    
     if( priceforsale !="" ){
      
      setmodaltoggle(!modaltoggle)
      setalertmessage("Please Wait ...")
      setblockloader(true)
      
    
      const getWeb3InstanceParameter = await getinstancesParamter();
    
    let contractmethod = await (getWeb3Instance(getWeb3InstanceParameter.martketRegistrationtype,getWeb3InstanceParameter.Blockchaintype,getWeb3InstanceParameter.networkType,getWeb3InstanceParameter.erctype))

   
      var tokenPrice  = Web3.utils.toWei(priceforsale,'ether');

   let response = await  contractmethod.methods.PutNFTonSale(objectValue.tokenId,tokenPrice).send({from: accountaddress})
   .on('transactionHash', function(hash){
  
})

    

    let nftContractmethod = await (getWeb3Instance(getWeb3InstanceParameter.nftRegistrationtype,getWeb3InstanceParameter.Blockchaintype,getWeb3InstanceParameter.networkType,getWeb3InstanceParameter.erctype))
  

    let getaddressofMarket = await (getaddressofMarketFunction(getWeb3InstanceParameter.martketRegistrationtype,getWeb3InstanceParameter.Blockchaintype,getWeb3InstanceParameter.networkType,getWeb3InstanceParameter.erctype))

    let getapprove = await  nftContractmethod.methods.setApprovalForAll(getaddressofMarket.address,true).send({from: accountaddress})



  
    if(response ){
     
      await window.ethereum.enable();
      const checknetworkId = window.ethereum.networkVersion
        const headers = {
          "Authorization":localStorage.token ? `Bearer ${localStorage.token}` : undefined,
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
        const data = {
          walletAddress:accountaddress,
          tokenId:objectValue.tokenId,
          "action":"On Sale", 
    "from":accountaddress, 
    "to": contract.getmarketcontractaddress(), 
    
    "date": moment().format('YYYY-MM-DD, h:mm:ss a'),
    "transactionHash":response.transactionHash,
    "networkId":checknetworkId
          
        }

        const selldata = {
          tokenId:objectValue.tokenId,
        }
       


        let approveforsale =  await  axios.post(`${apiBaseUrl}/api/user/putOnSellTokenId`, data, {
          headers: headers
        }).then((response)=>{
          
         
             setalertmessage("Token Approved For Sale")
               window.location = "/profile"
          
          
        }) .catch((error)=>{
          alert(error)
        })


         
    }
   
  }

}
else {
  if(priceforsale == ""){
    alert("Please Enter Price")

}
else{
  alert (`Enter Price Should Be Greater Than ${priceforsale}`)
}
  
 
}

  }

catch (error) {
  
    alert(error) 

}


  }

 
 

  return (
    <>
    
  {objectValue &&
    <div className={cn(styles.card, className)} >

           <Link className={styles.link} to={"item"} onClick={()=>{
        
            localStorage.setItem("Itemvalue",objectValue.image)
             localStorage.setItem("ItemDetails",objectValue.name)
             localStorage.setItem("ItemDesc",objectValue.description)
             localStorage.setItem("PricetokenId",objectValue.tokenId)         
            localStorage.setItem("ItemfileType",objectValue.assettype)


           }}>
      <div className={styles.preview} style={{backgroundColor:"black"}}>    
        {objectValue.assettype != "video/mp4" &&  objectValue.assettype !="audio/mpeg"  && 
        <> 
        <center><img srcSet={`${objectValue.image} 2x`} src={objectValue.image} alt="Card"  style={{maxWidth:"180px",maxHeight:"180px",width:"170px",height:"170px"}}/></center>  
          
         </>
         
          }

{ objectValue.assettype =="audio/mpeg"  && 
<>    

<center><img srcSet={`${mp3image} 2x`} src={mp3image} alt="Card"  style={{maxWidth:"180px",maxHeight:"180px",width:"170px",height:"170px"}}/></center>  
</>

}          
     
        <div className={styles.control}>
          
          <div
            className={cn(
              { "status-green": "green" === "green" },
              "green"
            )}
            style={{marginTop:"5%"}}
          >
       
           Details
          </div>
        
        </div>
    
        {objectValue.assettype == "video/mp4" && 
        <div style={{maxWidth:"180px",maxHeight:"180px",width:"170px",height:"170px"}}>
           <center>
             <VideoThumbnail              
           
                videoUrl={objectValue.image}
                thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                
              
          
              />
           </center>
           </div>
        }
      </div>
      
      </Link>
  

     
        <div className={styles.body} onClick={()=>{
   
        }}>
          <div className={styles.line} style={{cursor:"pointer"}}>
          {objectValue.name &&
            <div className={styles.title} title={objectValue.name}>{objectValue.name}</div>
          }
       
            {objectValue.tokensold ? (
              <>
              <div className={styles.price}>{tranfertoken}</div>             
             
              </>
               
            ) : (
              <>
               {objectValue.status ? (
     <div className={styles.price}>already on sale</div>
            ):(
                  <div className={styles.price} onClick={()=>{
                    setmodaltoggle(!modaltoggle)
                  }}>put on Sale</div>
            )}

              </>
            )}
           
         
      
          </div>
          <div className={styles.line}>
            <div className={styles.users}>
              
              
              
            </div>
            <div className={styles.counter}>{objectValue.counter}</div>
          </div>
        </div>
        
        <div className={styles.foot}>
        
          <div
            className={styles.bid}
            dangerouslySetInnerHTML={{ __html: item.bid }}
          />
        </div>
      
     
     
    </div>
}

    {blockloader ? <>
      <Modal
    
    visible={blockloader}
  

 >
   
   <div >
  
   <center><h4>{alertmessage}</h4></center>
   <br/>

   <div >
   
                </div>
   </div>
   </Modal>
    </> : 
    
     <Modal
    
         visible={modaltoggle}
         onClick={()=>{
           setmodaltoggle(!modaltoggle)
         }}
   
      >
        
        <h3 style={{fontSize:"25px"}}>Put on Sale</h3>

        <div style={{marginTop:"5%"}}>
{objectValue &&
  <TextInput
                      className={styles.field}
                      label="Item name"
                      name="name"
                      type="text"
                     
                       value={objectValue.name}
                      placeholder=''
                      required
                    />
}
        
                    <br/>
                     <TextInput
                      className={styles.field}
                      label="Price (ETH)"
                      name="price"
                      type="number"                      

                       onChange={(e)=>{
                         setpriceforsale(e.target.value.replace("-",""))
                       }}
                       value={priceforsale}
                      placeholder=''
                      required
                    />

                    </div>
                    <br/>
       <div  style={{marginTop:"10%"}}> 

        <button className={cn("button", styles.button)} style={{marginLeft:"-10%"}} onClick={()=>{
          submitfunction()
        }}>Submit</button>
        <button className={cn("button-stroke", styles.button)} style={{marginLeft:"15%"}}  onClick={()=>{
          setmodaltoggle(!modaltoggle)
        }}>Cancel</button>
      </div>
      </Modal>
}
    </>
    
    
  );
};

export default Card;
