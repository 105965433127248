import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Control.module.sass";
import Checkout from "./Checkout";
import Connect from "../../../components/Connect";
import Bid from "../../../components/Bid";
import Accept from "./Accept";
import PutSale from "./PutSale";
// import SuccessfullyPurchased from "./SuccessfullyPurchased";
import Modal from "../../../components/Modal";
import Web3 from "web3";
import axios from "axios";
import TextInput from "../../../components/TextInput";
import moment from "moment";
import createWeb3ContractMethodInstance from "../../../components/CreateWeb3Instance/Web3instance";
import getinstancesParamter from "../../../components/getInstancesParameter/Constant";

const contractABI = require("../../../components/ABI/NFTSalePurchase.json");
const contract = require("../../../components/ContractAddress/Contract");
const marketcontractABI = require("../../../components/ABI/NFTMarketPlace.json");
const marketcontract = require("../../../components/ContractAddress/Contract");
const constant = require("../../../components/Constant/Constant");
var apiBaseUrl = constant.getAPiUrl();

const Control = ({ className, itemname }) => {
  const [visibleModalPurchase, setVisibleModalPurchase] = useState(false);
  const [visibleModalBid, setVisibleModalBid] = useState(false);
  const [visibleModalAccept, setVisibleModalAccept] = useState(false);
  const [visibleModalSale, setVisibleModalSale] = useState(false);
  const [tokenroyalities, settokenroyalities] = useState(0);
  const [totalvalue, settotalvalue] = useState(0);
  const [tokenprice, settokenprice] = useState(0);
  const [convFess, setconvFess] = useState(0);
  const [adminfees, setadminfees] = useState(0);
  const [saleprice, setsaleprice] = useState(0);
  const [tokenname, settokenname] = useState("");
  const [blockloader, setblockloader] = useState(false);
  const [alertmessage, setalertmessage] = useState("");
  const [connectmodaltoggle, setconnectmodaltoggle] = useState(false);
  const [disablebutton, setdisablebutton] = useState(false);
  const [creator, setcreator] = useState("");
  const [creatorowner, setcreatorowner] = useState("");
  const [putonsalebutton, setputonsalebutton] = useState(false);
  const [modaltoggle, setmodaltoggle] = useState(false);
  const [priceforsale, setpriceforsale] = useState("");
  const [putonsalestatusfalse, setputonsalestatusfalse] = useState(false);
  const [waitloader, setwaitloader] = useState(false);
  const [hidepreveiwdetails, sethidepreveiwdetails] = useState(false);
  const [hideBothbutton, sethideBothbutton] = useState(false);
  // console.log(totalvalue)
  useEffect(() => {
    gettokeniddetailsfetch();

    setwaitloader(true);
  }, []);

  const checkAuthenticalofPutonSale = async () => {
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;
    if (checknetworkId == sessionStorage.networkIdParameter) {
      setmodaltoggle(!modaltoggle);
    } else {
      if (sessionStorage.networkIdParameter == 80001) {
        alert("Please Change Network To PolygonMumbai");
      } else if (sessionStorage.networkIdParameter == 3) {
        alert("Please Change Network To Ropsten Test Network");
      }
    }
  };
  const checkAuthenticalofPurchaseNow = async () => {
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;
    if (checknetworkId == sessionStorage.networkIdParameter) {
      purchasenowfunction();
    } else {
      if (sessionStorage.networkIdParameter == 80001) {
        alert("Please Change Network To PolygonMumbai");
      } else if (sessionStorage.networkIdParameter == 3) {
        alert("Please Change Network To Ropsten Test Network");
      }
    }
  };

  const getWeb3Instance = async (
    registrationType,
    blockchaintype,
    networktype,
    erctype
  ) => {
    console.log("calll");

    let contract_Methods = await createWeb3ContractMethodInstance(
      registrationType,
      blockchaintype,
      networktype,
      erctype
    );

    return contract_Methods.contract_Methods;
  };

  const gettokeniddetailsfetch = async () => {
    let getvalue = await axios({
      method: "get",

      url: `${apiBaseUrl}/api/user/getTokenIdDetails/${localStorage.PricetokenId}/${sessionStorage.networkIdParameter}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status == 200) {
          if (localStorage.CurrentAddress) {
            getroyalitiescheckfunction(
              response.data.message.tokenIdDetails.creator,
              response.data.message.tokenIdDetails.currentOwner
            );
          } else {
            checkFunctionWithoutMetaMask(
              response.data.message.tokenIdDetails.creator,
              response.data.message.tokenIdDetails.currentOwner
            );
          }
          setcreator(response.data.message.tokenIdDetails.creator);
          setcreatorowner(response.data.message.tokenIdDetails.currentOwner);
          console.log(response.data.message.tokenIdDetails.creator);
          console.log(response.data.message.tokenIdDetails.currentOwner);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkFunctionWithoutMetaMask = async (creator, owner) => {
    sethidepreveiwdetails(true);

    sethideBothbutton(true);

    // if(sessionStorage.checkStatus == "false") {

    //  }
    //  else{

    //  }
    if (creator != owner) {
      if (sessionStorage.checkStatus == "false") {
        setputonsalestatusfalse(true);
      }

      let gotbalaenc;

      if (sessionStorage.checkStatusPrice) {
        gotbalaenc = sessionStorage.checkStatusPrice;
      } else {
        gotbalaenc = 0;
      }

      let saleprice = gotbalaenc;
      console.log(saleprice);

      setsaleprice(saleprice);

      let percentvalue = (saleprice * adminfees) / 100;

      settokenprice(+saleprice);

      let conFess = (saleprice * 3) / 100;

      setconvFess(conFess);

      let totalvalue = +saleprice + conFess;
      settotalvalue(totalvalue);
    } else {
      if (sessionStorage.checkStatus == "false") {
        setputonsalestatusfalse(true);
      }
      settokenroyalities(0);

      let gotbalaenc = sessionStorage.checkStatusPrice;

      let saleprice = gotbalaenc;
      console.log(saleprice);
      setsaleprice(saleprice);

      let percentvalue = (saleprice * adminfees) / 100;

      let combinevalue = percentvalue;

      settokenprice(+saleprice);

      let conFess = (saleprice * 3) / 100;

      setconvFess(conFess);

      let totalvalue = +saleprice + conFess;
      settotalvalue(totalvalue);

      console.log(totalvalue);
    }
  };

  const getroyalitiescheckfunction = async (creator, owner) => {
    console.log(creator);
    console.log(owner);

    sethidepreveiwdetails(true);

    let accounts = [];
    if (window.web3.eth) {
      accounts = await window.web3.eth.getAccounts();
      console.log(accounts[0]);

      console.log(owner.toLowerCase());
    }

    if (owner == accounts[0].toLowerCase()) {
      setdisablebutton(true);

      if (sessionStorage.checkStatus == "false") {
        setputonsalebutton(true);
      } else {
      }
    }

    if (creator != owner) {
      const getWeb3InstanceParameter = await getinstancesParamter();

      if (sessionStorage.checkStatus == "false") {
        setputonsalestatusfalse(true);
      }

      let gotbalaenc;

      if (sessionStorage.checkStatusPrice) {
        gotbalaenc = sessionStorage.checkStatusPrice;
      } else {
        gotbalaenc = 0;
      }

      let saleprice = gotbalaenc;
      console.log(saleprice);

      setsaleprice(saleprice);

      let percentvalue = (saleprice * adminfees) / 100;

      settokenprice(+saleprice);

      let conFess = (saleprice * 3) / 100;

      setconvFess(conFess);

      let totalvalue = +saleprice + conFess;
      settotalvalue(totalvalue);
    } else {
      if (sessionStorage.checkStatus == "false") {
        setputonsalestatusfalse(true);
      }
      settokenroyalities(0);

      let gotbalaenc = sessionStorage.checkStatusPrice;

      let saleprice = gotbalaenc;
      console.log(saleprice);
      setsaleprice(saleprice);

      let percentvalue = (saleprice * adminfees) / 100;

      let combinevalue = percentvalue;

      settokenprice(+saleprice);

      let conFess = (saleprice * 3) / 100;

      setconvFess(conFess);

      let totalvalue = +saleprice + conFess;
      // settotalvalue(totalvalue);
      settotalvalue(+saleprice);

      console.log(totalvalue);
    }
  };

  async function load() {
    await loadWeb3();
    window.contract = await loadContract();
    window.marketcontract = await marketloadContract();

    console.log(creator);
    console.log(creatorowner);

    const tokenname = await window.marketcontract.methods.name().call();
    settokenname(tokenname);
    console.log("name", tokenname);
  }
  async function loadWeb3() {
    if (window.ethereum) {
      // console.log(window.ethereum)
      window.web3 = new Web3(window.ethereum);
      window.ethereum.enable();
    }
  }

  async function marketloadContract() {
    return await new window.web3.eth.Contract(
      marketcontractABI.abi,
      contract.getmarketcontractaddress()
    );
  }

  async function loadContract() {
    return await new window.web3.eth.Contract(
      contractABI.abi,
      contract.getcontractaddress()
    );
  }

  console.log(localStorage.saleprice);
  console.log(localStorage.adminfees);

  let royaltiespercent = 10;

  const alertmessagefunction = (value) => {
    setalertmessage(value);
  };

  const blockloaderfunction = (value) => {
    setblockloader(value);
  };

  const handleContactSubmission = (value) => {
    setVisibleModalPurchase(value);
  };

  const purchasenowfunction = () => {
    if (localStorage.isconnected) {
      setVisibleModalPurchase(true);
    } else {
      console.log("please connect to the wallet address");
      setconnectmodaltoggle(true);
    }
  };
// console.log(priceforsale)
  const submitfunction = async () => {
    try {
      let numberofpricevalue = +priceforsale;
      let pricetokenid = localStorage.PricetokenId;
      let accountaddress = localStorage.getItem("CurrentAddress");
      if (numberofpricevalue > 0) {
        // alert("number greate")

        if (priceforsale != "") {
          console.log("inside price");
          setmodaltoggle(!modaltoggle);
          setalertmessage("Please Wait ...");
          setblockloader(true);

          const getWeb3InstanceParameter = await getinstancesParamter();

          let contractmethod = await getWeb3Instance(
            getWeb3InstanceParameter.martketRegistrationtype,
            getWeb3InstanceParameter.Blockchaintype,
            getWeb3InstanceParameter.networkType,
            getWeb3InstanceParameter.erctype
          );
          // console.log(contractmethod.methods, "line no 64444444444444444");
          //  alert("getre")
          var tokenPrice = Web3.utils.toWei(priceforsale, "ether");

          let response = await contractmethod.methods
            .PutNFTonSale(pricetokenid, tokenPrice)
            .send({ from: accountaddress })
            .on("transactionHash", function (hash) {
              console.log(hash);
            });

          let nftContractmethod = await getWeb3Instance(
            getWeb3InstanceParameter.nftRegistrationtype,
            getWeb3InstanceParameter.Blockchaintype,
            getWeb3InstanceParameter.networkType,
            getWeb3InstanceParameter.erctype
          );

          let getaddressofMarket = await getaddressofMarketFunction(
            getWeb3InstanceParameter.martketRegistrationtype,
            getWeb3InstanceParameter.Blockchaintype,
            getWeb3InstanceParameter.networkType,
            getWeb3InstanceParameter.erctype
          );

          let getapprove = await nftContractmethod.methods
            .setApprovalForAll(getaddressofMarket.address, true)
            .send({ from: accountaddress });
          // console.log(getapprove);

          // console.log(response);

          if (response) {
            let uniqueid = localStorage.getItem("_id");
            await window.ethereum.enable();
            const checknetworkId = window.ethereum.networkVersion;

            const header = {
              Authorization: localStorage.token
                ? `Bearer ${localStorage.token}`
                : undefined,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            };

            let dataofsold = {
              _id: uniqueid,
              isSold: false,
            };
            axios
              .post(`${apiBaseUrl}/api/user/isSoldStatus`, dataofsold, {
                headers: header,
              })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });

            const headers = {
              Authorization: localStorage.token
                ? `Bearer ${localStorage.token}`
                : undefined,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
            };
            const data = {
              walletAddress: accountaddress,
              tokenId: pricetokenid,
              action: "On Sale",
              from: accountaddress,
              to: contract.getmarketcontractaddress(),

              date: moment().format("YYYY-MM-DD, h:mm:ss a"),
              transactionHash: response.transactionHash,
              networkId: checknetworkId,
            };

            const selldata = {
              tokenId: pricetokenid,
            };

            let approveforsale = await axios
              .post(`${apiBaseUrl}/api/user/putOnSellTokenId`, data, {
                headers: headers,
              })
              .then((response) => {
                // console.log(response);

                setalertmessage("Token Approved For Sale");
                window.location = "/";
              })
              .catch((error) => {
                alert(error);
              });
          }
        }
        //   else if(priceforsale == "0.0" || priceforsale == "0.00" || priceforsale == "0.000" || priceforsale == "0.0000" || priceforsale == "0.00000" || priceforsale == "0.000000" || priceforsale == "0.0000000" || priceforsale == "0.00000000"){
        //     alert(`Enter Price Should Be Greater Than ${priceforsale}`)

        // }

        //     else if(priceforsale == ""){
        //       alert("Please Enter Price")

        // }
      } else {
        if (priceforsale == "") {
          alert("Please Enter Price");
        } else {
          alert(`Enter Price Should Be Greater Than ${priceforsale}`);
        }
      }
    } catch (error) {
      console.log("error", error);
      // setmodaltoggle(!modaltoggle)
      window.location.reload();
      // setblockloader(false)
    }

    // console.log("msgshow");
  };

  const getaddressofMarketFunction = async (
    registrationType,
    blockchaintype,
    networktype,
    erctype
  ) => {
    let getresponse = await axios({
      method: "get",
      url: `${apiBaseUrl}/api/admin/getSmartContractAddress/${registrationType}/${blockchaintype}/${networktype}/${erctype}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    }).catch((error) => {
      console.log(error);
    });
    console.log(getresponse.data.data[0]);
    return getresponse.data.data[0];
  };

  return (
    <>
      <Modal
        visible={modaltoggle}
        onClick={() => {
          setmodaltoggle(!modaltoggle);
        }}
      >
        {/* <PutSale /> */}
        <h3 style={{ fontSize: "25px" }}>Put on Sale</h3>

        <div style={{ marginTop: "5%" }}>
          <TextInput
            className={styles.field}
            label="Item name"
            name="name"
            type="text"
            value={itemname}
            placeholder=""
            required
          />
          <br />
          <TextInput
            className={styles.field}
            label="Price (ETH)"
            name="price"
            type="number"
            // pattern="[0-9]*"
            // inputmode="numeric"

            onChange={(e) => {
              setpriceforsale(e.target.value.replace("-", ""));
            }}
            value={priceforsale}
            placeholder=""
            required
          />
        </div>
        <br />
        <div style={{ marginTop: "10%" }}>
          <button
            className={cn("button", styles.button)}
            style={{ marginLeft: "15%" }}
            onClick={() => {
              submitfunction();
            }}
          >
            Submit
          </button>
          <button
            className={cn("button-stroke", styles.button)}
            style={{ marginLeft: "2%" }}
            onClick={() => {
              setmodaltoggle(!modaltoggle);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>

      <Modal visible={connectmodaltoggle}>
        <div>
          <center>
            <h4>Please Connect To Wallet Before Purchase</h4>
          </center>
          <br />
          <center>
            <h4>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  localStorage.setItem("isconnected", true);
                  window.location.reload();
                }}
              >
                Connect
              </span>{" "}
              <span
                style={{ marginLeft: "10%", cursor: "pointer" }}
                onClick={() => {
                  setconnectmodaltoggle(false);
                }}
              >
                Cancel
              </span>
            </h4>
          </center>

          <div></div>
        </div>
      </Modal>
      <Modal visible={blockloader}>
        <div>
          <center>
            <h4>{alertmessage}</h4>
          </center>
          <br />

          <div></div>
        </div>
      </Modal>
      {waitloader && (
        <>
          {putonsalebutton && !hideBothbutton && (
            <div
              className={cn(styles.control, className)}
              style={{ backgroundColor: "", width: "85%" }}
            >
              <div className={styles.foot}>
                <button
                  className={cn("button", styles.button)}
                  onClick={() => {
                    // setmodaltoggle(!modaltoggle)

                    checkAuthenticalofPutonSale();
                  }}
                  // onClick={() => purchasenowfunction()}
                >
                  Put On Sale
                </button>
              </div>
            </div>
          )}
          {/* {!putonsalestatusfalse && !putonsalebutton && hidepreveiwdetails && */}
          {!putonsalestatusfalse &&
            !putonsalebutton &&
            hidepreveiwdetails &&
            sessionStorage.checkStatus == "true" && (
              <div
                className={cn(styles.control, className)}
                style={{ backgroundColor: "", width: "85%" }}
              >
                {!putonsalebutton && (
                  <div className={styles.head}>
                    <div className={styles.avatar}>
                      {/* <img src="/images/content/avatar-4.jpg" alt="Avatar" /> */}
                      <img
                        src="/images/content/etherium-circle.jpg"
                        alt="Avatar"
                      />
                    </div>
                    <div className={styles.details}>
                      <div className={styles.cost}>
                        <div className={styles.price}>{saleprice} ETH</div>
                      </div>
                    </div>
                  </div>
                )}

                {!disablebutton &&
                  !hideBothbutton &&
                  sessionStorage.checkStatus == "true" && (
                    <div className={styles.foot}>
                      <button
                        className={cn("button", styles.button)}
                        onClick={() => {
                          // setmodaltoggle(!modaltoggle)

                          checkAuthenticalofPurchaseNow();
                        }}
                        // onClick={() => purchasenowfunction()}
                      >
                        Purchase now
                      </button>
                    </div>
                  )}

                {!putonsalebutton && sessionStorage.checkStatus == "true" && (
                  <>
                    <div className={styles.text}>
                      Token Price
                      <span className={styles.percent}>{tokenprice} ETH</span>
                      <br />
                      {/* <br /> */}
                      {/* Service Fees */}
                      {/* <span className={styles.percent}>{convFess} ETH</span> */}
                    </div>

                    <div className={styles.text}>
                      Total Amount
                      <span className={styles.percent}>{totalvalue} ETH</span>
                    </div>
                    <div className={styles.text}>
                      Inclusive of Convinience Fee
                    </div>

                    <div className={styles.note}>
                      You are setting this token on NFT Brewery Market Place
                    </div>
                  </>
                )}
              </div>
            )}
        </>
      )}
      <Modal
        visible={visibleModalPurchase}
        onClose={() => setVisibleModalPurchase(false)}
      >
        {/* <Checkout   handleContactSubmission={handleContactSubmission} calculatevalue = {totalvalue}/> */}
        <Checkout
          handleContactSubmission={handleContactSubmission}
          calculatevalue={totalvalue}
          tokenprice={tokenprice}
          conFees={convFess}
          royalities={tokenroyalities}
          saleprice={saleprice}
          adminfees={adminfees}
          tokenname={tokenname}
          blockloader={blockloaderfunction}
          alertmessage={alertmessagefunction}
        />
      </Modal>
      <Modal
        visible={visibleModalBid}
        onClose={() => setVisibleModalBid(false)}
      >
        <Connect />
        <Bid />
      </Modal>
      <Modal
        visible={visibleModalAccept}
        onClose={() => setVisibleModalAccept(false)}
      >
        <Accept />
      </Modal>
      <Modal
        visible={visibleModalSale}
        onClose={() => setVisibleModalSale(false)}
      >
        <PutSale />
      </Modal>
    </>
  );
};

export default Control;
