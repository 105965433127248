import React from "react";
import cn from "classnames";
import styles from "./Burn.module.sass";

const Burn = ({ className ,...props}) => {
  console.log(props)
  const continuefunction = ()=>{
    // props.onChangeBurnfunction(false)
    props.onBurnContunefunction(false)


  }
  const cancelfunction = ()=>{
    props.onChangeBurnfunction(false)
  }
  return (
    <div className={cn(className, styles.transfer)}>
      <div className={cn("h4", styles.title)}>Burn Token</div>
      <div className={styles.text}>
        Are you sure to burn this token? This action cannot be undone. Token
        will be transfered to zero address
      </div>
      <div className={styles.btns}>
        <button className={cn("button-pink", styles.button)} onClick={()=>{
          continuefunction()
        }}>Continue</button>
        <button className={cn("button-stroke", styles.button)} onClick={()=>{
          cancelfunction()
        }}>Cancel</button>
      </div>
    </div>
  );
};

export default Burn;
