import React, { useState,useEffect } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Actions.module.sass";
import Transfer from "../Transfer";
import RemoveSale from "../RemoveSale";
import Burn from "../Burn";
import Report from "../Report";
import Icon from "../Icon";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import Web3 from "web3";
const constant = require("../../components/Constant/Constant")
const contractABI = require('../../components/ABI/NFTSalePurchase.json')
const marketcontractABI = require('../../components/ABI/NFTMarketPlace.json')
 const marketcontract = require("../../components/ContractAddress/Contract")
 const contract = require("../../components/ContractAddress/Contract")

 var apiBaseUrl = constant.getAPiUrl();

const Actions = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const [visibleModalTransfer, setVisibleModalTransfer] = useState(false);
  const [visibleModalRemoveSale, setVisibleModalRemoveSale] = useState(false);
  const [visibleModalBurn, setVisibleModalBurn] = useState(false);
  const [visibleModalReport, setVisibleModalReport] = useState(false);
  const [changepricetoggle,setchangepricetoggle]= useState(false);
  const [saleprice ,setsaleprice]=useState("")
  const [updatetoggle,setupdatetoggle]= useState(false);

  useEffect(() => {
    
     
// setsaleprice(localStorage.saleprice)
    load();

    
  },[])

  async function loadContract() {
    return await new window.web3.eth.Contract(contractABI.abi,contract.getcontractaddress())
  }

  const checkprice = async()=>{
    try {
      
      if(localStorage.PricetokenId){
        console.log(localStorage.PricetokenId)
        const price = await window.marketcontract.methods.TokenPrice(localStorage.PricetokenId).call();
      console.log(price)
          let gotbalaenc =  await window.web3.utils.fromWei(price)
          console.log(gotbalaenc)
  let saleprice = gotbalaenc.slice(0,6)
  console.log(saleprice)
  setsaleprice(saleprice)
        
      

      }
     
    
   
    
      
    } catch (error) {
      console.log("error", error);
    }
//     if(localStorage.saleprice == undefined){

  


//       const price = await window.marketcontract.methods.TokenPrice(localStorage.PricetokenId).call();

//       let gotbalaenc =  await window.web3.utils.fromWei(price)
// let orignalvalue = gotbalaenc.slice(0,6)
// setsaleprice(orignalvalue)

//     }
//     else if(localStorage.saleprice){
//       setsaleprice(localStorage.saleprice)
//     }
        

  }
   

  async function load() {

    await loadWeb3();
     window.contract = await loadContract();
     window.marketcontract = await marketloadContract()
     checkprice()

    
    //      console.log(tokenURI)
    // console.log(JSON.parse(decodeURIComponent(tokenURI)));    
    
 
  }

  async function marketloadContract(){
    return await new window.web3.eth.Contract(marketcontractABI.abi,contract.getmarketcontractaddress())
  }
  async function loadWeb3() {
    if (window.ethereum) {
      // console.log(window.ethereum)
       window.web3 = new Web3(window.ethereum);
       window.ethereum.enable();
            
    }
  }

  const items = [
    {
      title: "Change price",
      icon: "coin",
      action: () =>
      setchangepricetoggle(true)
       
    },
    // {
    //   title: "Transfer token",
    //   icon: "arrow-right-square",
    //   action: () => setVisibleModalTransfer(true),
    // },
    {
      title: "Remove from sale",
      icon: "close-circle",
      action: () => setVisibleModalRemoveSale(true),
    },
    // {
    //   title: "Burn token",
    //   icon: "close-circle",
    //   action: () => setVisibleModalBurn(true),
    // },
    // {
    //   title: "Report",
    //   icon: "info-circle",
    //   action: () => setVisibleModalReport(true),
    // },
  ];

  const changepricesubmitfunction =async ()=>{
    var tokenPrice  = Web3.utils.toWei(saleprice,'ether');
    
    
    setupdatetoggle(true)
    // localStorage.setItem(saleprice,tokenPrice)
   let response = await  window.marketcontract.methods.EditSalePrice(localStorage.PricetokenId,tokenPrice).send({from: localStorage.CurrentAddress})
    console.log(response)
 
    if(response){

      const price = await window.marketcontract.methods.TokenPrice(localStorage.PricetokenId).call();
        let gotbalaenc =  await window.web3.utils.fromWei(price)
let orignalvalue = gotbalaenc.slice(0,6)
        setsaleprice(orignalvalue )
        localStorage.setItem("saleprice",orignalvalue)
//         let gotbalaenc =  await window.web3.utils.fromWei(price)
// let orignalvalue = gotbalaenc.slice(0,6)
//         setsaleprice(orignalvalue )
    
      
      // setupdatetoggle(false)
      window.location.reload()


    }

    

  }

  const removecancel = (value)=>{
    setVisibleModalRemoveSale(value)
  }

  return (
    <>
    
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div
          className={cn(styles.actions, className, {
            [styles.active]: visible,
          })}
        >
          <button
            className={cn("button-circle-stroke", styles.button)}
            onClick={() => setVisible(!visible)}
          >
            <Icon name="more" size="24" />
          </button>
          <div className={styles.body}>
            {items.map((x, index) => (
              <div className={styles.item} key={index} onClick={x.action}>
                <Icon name={x.icon} size="20" />
                <span>{x.title}</span>
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
      <Modal
         visible={changepricetoggle}
        // onClose={() => setVisibleModalTransfer(false)}
      >

        {/* <h2>Update Change Price</h2> */}
        <div className={cn("h4", styles.title)}>Change Price</div>
        <br/>



        <TextInput
                      className={styles.field}
                      label="Item name"
                      name="name"
                      type="text"
                      // onChange={onTextFieldChange}
                       value={localStorage.ItemDetails}
                      placeholder=''
                      required
                    />
                    <br/>
                     <TextInput
                      className={styles.field}
                      label="Price"
                      name="price"
                      type="number"
                       onChange={(e)=>{
                         setsaleprice(e.target.value)
                       }}
                       value={saleprice}
                      placeholder=''
                      required
                    />
                    <br/>
      <div className={styles.btns}>
        {updatetoggle ? (
<>
<center><button className={cn("button", styles.button)} onClick={()=>{
          // changepricesubmitfunction()
        }}>Please Wait</button></center>{' '}
       
</>
        ):(
          <>
          <button className={cn("button", styles.button)} onClick={()=>{
          changepricesubmitfunction()
        }}>Submit</button>{' '}
        <button className={cn("button-stroke", styles.button)} on onClick={()=>{
          setchangepricetoggle(false)
        }}>Cancel</button>
          </>

        )}
        
      </div>
      </Modal>
      <Modal
        visible={visibleModalTransfer}
        onClose={() => setVisibleModalTransfer(false)}
      >
        <Transfer />
      </Modal>
      <Modal
        visible={visibleModalRemoveSale}
        onClose={() => setVisibleModalRemoveSale(false)}
      >
        <RemoveSale removecancel = {removecancel}/>
      </Modal>
      <Modal
        visible={visibleModalBurn}
        onClose={() => setVisibleModalBurn(false)}
      >
        <Burn />
      </Modal>
      <Modal
        visible={visibleModalReport}
        onClose={() => setVisibleModalReport(false)}
      >
        <Report />
      </Modal>
    </>
  );
};

export default Actions;
