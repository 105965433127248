import React, { useState, useEffect, Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import axios from "axios";
// import Icon from "../Icon";
import Image from "../Image";
// import Modal from "../../components/Modal";
import { Modal, Popover, Button, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

// import Notification from "./Notification";
import User from "./User";

// import lightimage from "../../../src/assets/images/wakanomyblack.png";
// import logoimage from "../../../src/assets/images/logobluetransparent.png";
import logoimage from "../../../src/assets/images/nftNlogo.png";
import walletimage from "../../../src/assets/images/walleticon.png";
// import whiteimage from "../../../src/assets/images/wakanomywhite.png";
// import Blockchainloader from "../Blockchain/blockloader";
// import Theme from "../Theme";
import Web3 from "web3";

const constant = require("../../components/Constant/Constant");

var apiBaseUrl = constant.getAPiUrl();

// const nav = [
//   {
//     url: "/search01",
//     title: "Discover",
//   },
// ];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [search, setSearch] = useState("");
  const [unabledisabletoggle, setunabledisabletoggle] = useState(false);
  const [account, setaccount] = useState("");
  const [isloginmodaltoggle, setisloginmodaltoggle] = useState(false);
  const [statusmodal, setstatusmodal] = useState(false);
  const [supportednetwork, setsupportednetwork] = useState([]);
  const [networkdId, setnetworkdId] = useState();
  const [supporttoggle, setsupporttoggle] = useState(false);

  useEffect(() => {
    //for getting balance ,current address and current network

    setInterval(async function () {
      let accounts = [];
      window.web3 = new Web3(window.ethereum);
      window.ethereum.enable();
      // if (window.web3) {
      //   accounts = await window.web3.eth.getAccounts();
      // }

      // if (window.web3) {
      //   if (accounts.length == 0) {
      //     const currentURL = window.location.href;
      //     const pathname = window.location.pathname;

      //     if (pathname != "/") {
      //       localStorage.clear();
      //       window.location = "/";
      //     }
      //   }
      // }

      if (window.web3) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];

        let currentBalance = await window.web3.eth.getBalance(accounts[0]);

        let gotbalaenc = await window.web3.utils.fromWei(currentBalance);

        let chanevale = +gotbalaenc;

        let orignalvalue = chanevale.toFixed(4);

        localStorage.setItem("CurrentBalance", orignalvalue);

        if (localStorage.CurrentAddress != account) {
          setstatusmodal(true);
          localStorage.setItem("CurrentAddress", account);

          window.location = "/";
        }

        if (getNetworkName(window.ethereum.networkVersion) == "Unknown") {
          // localStorage.clear()

          localStorage.setItem("darkMode", true);
        } else {
          let newtworkname = getNetworkName(window.ethereum.networkVersion);

          if (newtworkname == "Mainnet") {
            setNetwork(`Ethereum ${newtworkname}`);
          } else {
            setNetwork(`${newtworkname}`);
          }
        }
      }
    }, 2000);
    if (window.ethereum) {
      getsupportblockchainnetwork();
    }
  }, []);

  const getsupportblockchainnetwork = async () => {
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;
    sessionStorage.setItem("checknetworkId", checknetworkId);

    const headers = {
      Authorization: localStorage.token
        ? `Bearer ${localStorage.token}`
        : undefined,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${apiBaseUrl}/api/admin/getSupportedBlockchainNetwork`, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == 200) {
          setsupportednetwork(response.data.data);
          sessionStorage.setItem(
            "SupportedBlockChain",
            JSON.stringify(response.data.data)
          );
        }
      })
      .catch((error) => {});
  };

  const [network, setNetwork] = useState("");
  const [blockloader, setblockloader] = useState(false);
  const [networksupportedtoggle, setnetworksupportedtoggle] = useState(false);

  //by getting network Id find the network name by matching id
  // console.log(network)
  function getNetworkName(id) {
    var networkName;

    switch (id) {
      case "1":
        networkName = " Ethereum Mainnet";
        break;
      case "2":
        networkName = "Morden";
        break;
      case "3":
        networkName = "Ropsten Test Network";
        break;
      case "4":
        networkName = "Rinkeby Test Network";
        break;
      case "42":
        networkName = "Kovan Test Network";
        break;
      case "80001":
        networkName = "PolygonMumbai";
        break;
      default:
        networkName = "Unknown";
    }
    return networkName;
  }
  if (window.ethereum) {
    window.ethereum.on("networkChanged", function (value) {
      setnetworksupportedtoggle(true);

      setnetworkdId(value);

      const checknetworkId = window.ethereum.networkVersion;

      if (checknetworkId != sessionStorage.checknetworkId) {
        window.location = "/";
      }
    });
  } else {
  }

  //for checking wheather current address have minting rights or not

  const setfunctionformintasset = async (accountaddress) => {
    setaccount(accountaddress);
    await window.ethereum.enable();
    const checknetworkId = window.ethereum.networkVersion;

    axios({
      method: "get",
      url: `${apiBaseUrl}/api/user/getInvite/${accountaddress}/${checknetworkId}`,
      headers: {
        Authorization: localStorage.token
          ? `Bearer ${localStorage.token}`
          : undefined,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        if (res.data.status == 200) {
          if (
            window.ethereum.networkVersion == 80001 ||
            window.ethereum.networkVersion == 4 ||
            window.ethereum.networkVersion == 3
          ) {
            window.location = "/upload-details";
          } else {
            setsupporttoggle(true);
          }
        } else if (res.data.status == 406) {
        }
      })
      .catch((error) => {
        // alert(error)
        alert("You are not authorised for Mint NFT");

        if (error.message == "Request failed with status code 400") {
          let currentaddress = localStorage.CurrentAddress;
          localStorage.clear();
          localStorage.setItem("CurrentAddress", currentaddress);
        }
      });
  };

  return (
    <header className={styles.header} style={{ backgroundColor: "" }}>
      <Modal
        visible={supporttoggle}
        onOk={() => setsupporttoggle(false)}
        onCancel={() => setsupporttoggle(false)}
      >
        <div>
          <center>
            <h3>Wrong Network !</h3>
          </center>
          <br />
          <center>
            <h4>
              Look like you connected to unsupported network.Change network to
              Polygon Mumbai Or Rinkeby.
            </h4>
          </center>
          <br />
          {/* <center>
            <h3
              style={{ cursor: "pointer" }}
              onClick={() => {
                setsupporttoggle(false);
              }}
            >
              OK
            </h3>
          </center> */}

          <div></div>
        </div>
      </Modal>

      <Modal visible={statusmodal} footer={false}>
        <div>
          <center>
            <h4>Please Wait..</h4>
          </center>
          <br />
          <center>
            <h4>Please do not press Back or Refresh</h4>
          </center>

          <div></div>
        </div>
      </Modal>

      <Modal
        visible={isloginmodaltoggle}
        onOk={() => setisloginmodaltoggle(false)}
        onCancel={() => setisloginmodaltoggle(false)}
      >
        <div>
          <center>
            <h4>Please Login Before Mint Asset</h4>
          </center>
          <br />
          <center>
            <h4>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location = "/login";
                }}
              >
                Login
              </span>{" "}
              {/* <span
                style={{ marginLeft: "10%", cursor: "pointer" }}
                onClick={() => {
                  setisloginmodaltoggle(false);
                }}
              >
                Cancel
              </span> */}
            </h4>
          </center>

          <div></div>
        </div>
      </Modal>
      <div
        className={cn("container", styles.container)}
        style={{ marginTop: "0" }}
      >
        <div className={styles.left}>
          <Link className={styles.logo} to="/">
            <img
              className={styles.pic}
              src={logoimage}
              srcDark={logoimage}
              alt="Logo"
            />
          </Link>
          <div>
            <Link
              className={styles.link}
              target="_blank"
              to="//thenftbrewery.com"
            >
              {/* < className={styles.link}> */}
              Home
              {/* </> */}
            </Link>
          </div>
          <div>
            {/* <a href="https://thenftbrewery.com/ecosystem/">Ecosystem</a> */}
            <Link
              className={styles.link}
              target="_blank"
              to="//thenftbrewery.com/ecosystem/"
            >
              Ecosystem
            </Link>
          </div>
          <div>
            <Link
              className={styles.link}
              target="_blank"
              to="//form.typeform.com/to/wNiviw"
            >
              Get Featured
            </Link>
          </div>
        </div>

        <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
          {/* {window.location.pathname !== "/" && (
            <Link className={styles.NavLink} to="/">
              Home
            </Link>
          )} */}
          {/* {window.location.pathname !== "/marketplace" && (
            <Link className={styles.NavLink} to="/marketplace">
              Go to Marketplace
            </Link>
          )} */}
        </div>
        <div className={styles.right}>
          {/* <div>
            <Link
              className={styles.link}
              target="_blank"
              to="//thenftbrewery.com"
            >
              Home
            </Link>
          </div>
          <div>
            <Link
              className={styles.link}
              target="_blank"
              to="//thenftbrewery.com/ecosystem/"
            >
              Ecosystem
            </Link>
          </div>
          <div>
            <Link
              className={styles.link}
              target="_blank"
              to="//form.typeform.com/to/wNiviw"
            >
              Get Featured
            </Link>
          </div> */}
          {localStorage.isconnected && (
            <Fragment>
              {" "}
              <>
                <div className={styles.networkInfo}>
                  <div>{network}</div>
                  <div style={{ wordBreak: "break-all" }}>
                    {localStorage.getItem("CurrentAddress")}
                  </div>
                </div>
              </>
            </Fragment>
          )}
          {localStorage.isconnected && (
            <div className={styles.popover}>
              <Popover
                placement="bottom"
                title={"Wallet Details"}
                content={
                  <Fragment>
                    {" "}
                    <>
                      <div
                        style={{
                          width: "320px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>{network}</div>
                        <div style={{ wordBreak: "break-all" }}>
                          {localStorage.getItem("CurrentAddress")}
                        </div>
                      </div>
                    </>
                  </Fragment>
                }
              >
                <div>
                  <Avatar shape="square" size={25} icon={<UserOutlined />} />
                </div>
              </Popover>
            </div>
          )}
          <div
            style={{
              marginRight: "10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: "auto",
            }}
          >
            <div>
              <Link to="/connect-wallet">
                <img
                  className={styles.walletImage}
                  src={walletimage}
                  alt="wallet"
                />
              </Link>
            </div>
            {!localStorage.isconnected ? (
              <div style={{ color: "#1890ff" }}>Connect</div>
            ) : (
              <div style={{ color: "#1890ff" }}>Disconnect</div>
            )}
          </div>
          {/* {localStorage.isconnected && (
            <>
              <User className={styles.user} network={`${network}`} />
            </>
          )} */}
        </div>
      </div>
    </header>
  );
};

export default Headers;
